import React, { createRef } from "react";
import Joi from "joi-browser";
import Form from "../../../../common/Form";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CguvArtist from "../../../Common/CguvArtist";

const styles = (theme) => ({
  root: {
    "& .errorMsg": {
      color: "red",
    },
    "& .ck-editor__editable_inline": {
      background: theme.palette.background.level2,
      margin: "45px 0 15px 0",
      borderRadius: "8px !important",
    },
    "& .MuiButton-root, .MuiTextField-root": {
      width: "100%",
      margin: "15px 0",
    },
  },
  block: {
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
  },
  avatar: {
    width: 120,
    height: 120,
    margin: "0 auto",
    "& :hover": {
      cursor: "pointer",
    },
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  legalLink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "& :hover": {
      cursor: "pointer",
    },
  },
  errorMsg: {
    marginTop: 10,
    color: theme.palette.error.main,
    textAlign: "center",
  },
});

class LegalAcceptationForm extends Form {
  constructor(props) {
    super();
    this.uploadRef = createRef();

    this.state = {
      data: {
        generalConditions: false,
      },
      errors: {},
      modalOpen: false,
    };
  }

  schema = {
    generalConditions: Joi.boolean().invalid(false),
  };

  doSubmit = async () => {
    const { data } = this.state;
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <form
        onSubmit={this.handleSubmit}
        autoComplete="off"
        className={classes.root}
      >
        <Paper className={classes.block}>
          {this.renderCheckbox("generalConditions", "")}
          <span onClick={this.handleModalOpen} className={classes.legalLink}>
            J'accepte les CGUV
          </span>
          <div>{this.renderButton("Valider", "primary")}</div>
        </Paper>

        <Dialog
          fullScreen
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleModalClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                RunArt - CGUV
              </Typography>
              <Button autoFocus color="inherit" onClick={this.handleModalClose}>
                J'ai compris
              </Button>
            </Toolbar>
          </AppBar>
          <CguvArtist />
        </Dialog>
      </form>
    );
  }
}

LegalAcceptationForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LegalAcceptationForm);
