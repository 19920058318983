import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Grid } from "@material-ui/core";
import LoginForm from "./components/LoginForm";
import Image from "../../common/atoms/Image";
import Paper from "@material-ui/core/Paper";
import { Link, Redirect } from "react-router-dom";
import { useStore } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    marginTop: 150,
  },
  divider: {
    margin: "15px 0",
  },
  loginForm: {
    padding: 15,
  },
}));

const Login = (props) => {
  const { className, location } = props;
  const store = useStore();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const classes = useStyles();

  const unsubscribe = store.subscribe(() => {
    if (store.getState().entities.user.currentUser) {
      setIsLoggedIn(true);
    }
  });
  useEffect(() => {
    return () => {
      unsubscribe();
    };
  });

  let content = (
    <div className={clsx(classes.root, className)}>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Paper className={classes.loginForm}>
            <h1>Bienvenue !</h1>
            <p>Merci de vous identifier pour continuer</p>
            <LoginForm
              classes={className}
              from={location.state ? location.state.from : "/"}
            />
            <Divider className={classes.divider} />
            <p>
              <span>Pas encore de compte ? </span>
              <Link to="/register">Créez-en un gratuitement</Link>
            </p>
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Image
            src={"/assets/images/artwork/JONONE_2004.jpg"}
            alt={"..."}
            className={clsx(classes.image)}
            lazy={false}
          />
        </Grid>
      </Grid>
    </div>
  );

  if (isLoggedIn) {
    content = (
      <Redirect
        to={{
          pathname: props.location.state
            ? props.location.state.from.pathname
            : "/",
        }}
      />
    );
  }

  return content;
};

Login.propTypes = {
  className: PropTypes.string,
};

export default Login;
