import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Hidden,
  TextField,
} from "@material-ui/core";
import CardProduct from "./components/CardProduct";
import CardProductMobile from "./components/CardProductMobile";
import { Link } from "react-router-dom";
import { deleteArtwork, getArtworks } from "../../services/artworkService";
import { useStore } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { Autocomplete } from "@material-ui/lab";
import ConfirmDelete from "./components/ConfirmDelete";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  fab: {
    position: "sticky",
    bottom: theme.spacing(2),
    right: 0,
  },
}));

const ProductsList = (props) => {
  const { className } = props;
  const classes = useStyles();
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterSeller, setFilterSeller] = useState("all");
  const [textFilter, setTextFilter] = useState("");
  const store = useStore();
  const [artworks, setArtworks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const currentUser = store.getState().entities?.user?.currentUser;
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [artworkToDelete, setArtworkToDelete] = useState("");

  const fetchData = async (filters) => {
    let criteria = {
      page: filters.page,
    };
    if (currentUser && !currentUser.roles.includes("ROLE_ADMIN")) {
      criteria = { ...criteria, "seller._id": currentUser?._id };
    }

    if (
      currentUser.roles.includes("ROLE_ADMIN") &&
      filters.seller !== null &&
      filters.seller !== "all"
    ) {
      criteria = { ...criteria, "seller._id": filters.seller };
    }

    if (filters.status !== null && filters.status !== "all") {
      criteria = { ...criteria, status: filters.status };
    }

    if (filters.term) {
      criteria = { ...criteria, term: filters.term };
    }

    const artworks = await getArtworks(criteria);
    setArtworks(artworks.data);
  };

  const handleTextSearchChange = ({ target }) => {
    setTextFilter(target.value);
    fetchData({
      status: filterStatus,
      seller: filterSeller,
      page: currentPage,
      term: target.value,
    });
  };

  const handleFilterStatusChange = ({ target }) => {
    setFilterStatus(target.value);
    fetchData({
      status: target.value,
      seller: filterSeller,
      page: currentPage,
      term: textFilter,
    });
  };

  const handleFilterSellerChange = (newValue) => {
    let filter = "all";
    if (newValue) {
      filter = newValue._id._id;
    }
    setFilterSeller(filter);
    fetchData({
      status: filterStatus,
      seller: filter,
      page: currentPage,
      term: textFilter,
    });
  };

  const handlePageChange = (element, newPage) => {
    setCurrentPage(newPage);
    fetchData({
      status: filterStatus,
      seller: filterSeller,
      page: newPage,
      term: textFilter,
    });
  };

  const handleDelete = (artworkId) => {
    console.log(`Delete artwotk ${artworkId}`);
    setArtworkToDelete(artworkId);
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = async () => {
    setDeleteConfirmOpen(false);
    await deleteArtwork(artworkToDelete);
    toast.success("Oeuvre supprimée avec succès");
    fetchData({
      status: filterStatus,
      seller: filterSeller,
      page: currentPage,
      term: textFilter,
    });
  };

  const handleConfirmDeleteClose = () => {
    setDeleteConfirmOpen(false);
    setArtworkToDelete("");
  };

  useEffect(() => {
    let criteria = { page: 1 };

    if (currentUser && !currentUser.roles.includes("ROLE_ADMIN")) {
      criteria = { ...criteria, "seller._id": currentUser?._id };
    }
    getArtworks(criteria).then((artworks) => {
      setArtworks(artworks.data);
    });
  }, [store, currentUser]);

  return (
    <div className={clsx(classes.root, className)}>
      <h1 className={classes.title}>Vos oeuvres</h1>
      <h4>Filtres</h4>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="filter-status">Statut</InputLabel>
        <Select
          labelId="filter-status"
          id="filter-status"
          value={filterStatus}
          onChange={handleFilterStatusChange}
          label="Statut"
        >
          <MenuItem value="all">
            <em>Tous</em>
          </MenuItem>
          <MenuItem value={0}>En attente validation</MenuItem>
          <MenuItem value={5}>Refusé</MenuItem>
          <MenuItem value={10}>En ligne</MenuItem>
          <MenuItem value={20}>Acheté</MenuItem>
        </Select>
      </FormControl>
      {currentUser.roles.includes("ROLE_ADMIN") && artworks.sellerFacet && (
        <FormControl variant="outlined" className={classes.formControl}>
          <Autocomplete
            id="combo-box-demo"
            options={artworks.sellerFacet}
            style={{ width: 300 }}
            onChange={(event, newValue) => {
              handleFilterSellerChange(newValue);
            }}
            getOptionLabel={(option) =>
              option._id.firstName
                ? `${option._id.firstName} ${option._id.lastName}`
                : option._id.businessName
            }
            renderInput={(params) => (
              <TextField {...params} label="Vendeur" variant="outlined" />
            )}
          />
        </FormControl>
      )}
      <TextField
        label={"Rechercher"}
        onChange={handleTextSearchChange}
        type={"text"}
        variant="outlined"
        className={classes.formControl}
      />
      <Divider className={classes.divider} />

      {!currentUser.roles.includes("ROLE_ADMIN") && (
        <React.Fragment>
          <Button
            variant={"contained"}
            color={"primary"}
            component={Link}
            to={"/products/new"}
          >
            Ajouter une oeuvre
          </Button>

          <Divider className={classes.divider} />
        </React.Fragment>
      )}

      {artworks.data &&
        artworks.data.map((artwork) => (
          <React.Fragment key={artwork._id}>
            <Hidden smDown>
              <CardProduct artwork={artwork} onDelete={handleDelete} />
            </Hidden>
            <Hidden mdUp>
              <CardProductMobile artwork={artwork} onDelete={handleDelete} />
            </Hidden>
          </React.Fragment>
        ))}

      <Divider className={classes.divider} />
      <Pagination
        onChange={handlePageChange}
        count={artworks.metadata && Math.ceil(artworks.metadata[0]?.total / 10)}
      />
      <ConfirmDelete
        open={deleteConfirmOpen}
        handleClose={handleConfirmDeleteClose}
        handleConfirm={confirmDelete}
      />
    </div>
  );
};

ProductsList.propTypes = {
  className: PropTypes.string,
};

export default ProductsList;
