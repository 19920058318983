import React from "react";
import Joi from "joi-browser";
import Form from "../../../common/Form";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { login } from "../../../services/authService";
import { toast } from "react-toastify";

const styles = (theme) => ({
  root: {
    "& .MuiButton-root, .MuiTextField-root": {
      width: "100%",
      margin: "15px 0",
    },
  },
});

class LoginForm extends Form {
  state = {
    data: { username: "", password: "" },
    errors: {},
  };

  schema = {
    username: Joi.string().email().required().label("Username"),
    password: Joi.string().min(3).required().label("Password"),
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      const token = await login(data.username, data.password);

      localStorage.removeItem("tokenArtist");
      localStorage.removeItem("tokenArtPro");
      localStorage.removeItem("tokenAdmin");
      localStorage.setItem("token" + token.type, token.token.access_token);

      window.location = this.props.from.pathname
        ? this.props.from.pathname
        : "/";
    } catch (ex) {
      console.log(ex.response);
      if (ex.response && ex.response.status === 404) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data?.message;
        this.setState({ errors });
        toast.error("Identifiant ou mot de passe invalide");
      }
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <form
        onSubmit={this.handleSubmit}
        autoComplete="off"
        className={classes.root}
      >
        <div>{this.renderInput("username", "Email", "email")}</div>
        <div>{this.renderInput("password", "Mot de passe", "password")}</div>
        <div>{this.renderButton("Connexion", "primary")}</div>
      </form>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginForm);
