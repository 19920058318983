import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardActions,
  Button,
  Avatar,
  CardContent,
} from "@material-ui/core";
import clsx from "clsx";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  orderPrice: {
    fontWeight: 500,
    fontSize: 22,
    marginRight: 20,
  },
  "& MuiCardHeader-action": {
    marginTop: 0,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginBottom: 2,
    marginTop: 0,
  },
  status: {
    margin: 0,
    padding: 8,
    borderRadius: 6,
  },
  colorInfo: {
    backgroundColor: theme.palette.info.main,
  },
  colorSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  colorDanger: {
    backgroundColor: theme.palette.error.main,
  },
  colorPRocess: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const CardOrder = ({
  _id,
  user,
  totalPrice,
  status: orderStatus,
  createdAt,
}) => {
  const classes = useStyles();

  const status = {
    waiting_for_payment: {
      color: "Info",
      text: "En attente paiement",
    },
    payment_done: {
      color: "Success",
      text: "Paiement validé",
    },
    preparing: {
      color: "Info",
      text: "En cours de préparation",
    },
    delivery_in_progress: {
      color: "Process",
      text: "Expédiée",
    },
    delivered: {
      color: "Sucess",
      text: "Livrée",
    },
    canceled: {
      color: "Danger",
      text: "Annulée",
    },
    refund: {
      color: "Danger",
      text: "Remboursée",
    },
    closed: {
      color: "Infos",
      text: "Terminée",
    },
    paginate: {
      alignItems: "end",
    },
  };

  const createdAtDate = new Date(createdAt);

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {user.firstName[0] + user.lastName[0]}
          </Avatar>
        }
        title={
          <h3 className={classes.title}>
            #{_id} {user.firstName} {user.lastName}
          </h3>
        }
        subheader={createdAtDate?.toLocaleDateString("fr-FR", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })}
        action={<p className={classes.orderPrice}>{totalPrice}</p>}
      />
      <CardContent>
        <span
          className={clsx(
            classes.status,
            classes["color" + status[orderStatus].color]
          )}
        >
          {status[orderStatus].text}
        </span>
      </CardContent>
      <CardActions disableSpacing>
        <Button component={Link} to={`/orders/${_id}`} variant={"outlined"}>
          Consulter la commande
        </Button>
      </CardActions>
    </Card>
  );
};

CardOrder.propTypes = {
  className: PropTypes.string,
};

export default CardOrder;
