import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import ProductForm from "./components/ProductForm";
import { Link, useHistory } from "react-router-dom";
import { getArtworkById } from "../../services/artworkService";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import { useStore } from "react-redux";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
}));

const Products = (props) => {
  const { className } = props;
  const productId = props.match.params.id;
  const classes = useStyles();
  const history = useHistory();
  const [title, setTitle] = useState("Nouvelle oeuvre");

  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [artwork, setArtwork] = useState(null);
  const { seller } = props.location;

  const store = useStore();
  const { currentUser } = store.getState().entities.user;

  useEffect(() => {
    async function fetchData() {
      if (productId !== "new") {
        const artworkResponse = await getArtworkById(productId);
        setTitle(artworkResponse.data.title);
        setEdit(true);
        setArtwork(artworkResponse.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    fetchData();
  }, [productId]);

  const handleSubmit = () => {
    history.push("/products");
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Button
        color="primary"
        className={classes.button}
        startIcon={<ArrowBackIcon />}
        component={Link}
        to={"/products"}
      >
        Retour liste
      </Button>
      <h1 className={classes.title}>{title}</h1>
      {!loading && (
        <ProductForm
          onSubmit={handleSubmit}
          edit={edit}
          artwork={artwork}
          artworkId={productId}
          currentUser={currentUser}
          seller={seller}
        />
      )}
    </div>
  );
};

Products.propTypes = {
  className: PropTypes.string,
};

export default Products;
