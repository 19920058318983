import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { getOrderDetail, updateOrder } from "../../services/orderService";
import { currencyFormat } from "../../services/numberService";
import { Link } from "react-router-dom";
import axios from "axios";
import { calculateFees } from "../../services/fees";
import ShipProduct from "./components/ShipProduct";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
  content: {
    height: "100%",
  },
  paper: {
    padding: 15,
    margin: "15px 0",
  },
}));

const status = {
  waiting_for_payment: { text: "En attente paiement", next: "payment_done" },
  payment_done: { text: "Paiement validé", next: "preparing" },
  preparing: { text: "En cours de préparation", next: "delivery_in_progress" },
  delivery_in_progress: { text: "Expédiée", next: "delivered" },
  delivered: { text: "Livrée", next: "closed" },
  closed: { text: "Terminée" },
  canceled: { text: "Annulée" },
  refund: { text: "Remboursée" },
};

const Orders = ({ match, className }) => {
  const [order, setOrder] = useState();
  const [sellers, setSellers] = useState([]);
  const [fees, setFees] = useState([]);
  const [productToShip, setProductToShip] = useState("");
  const [openConfirmStatusChange, setOpenConfirmStatusChange] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const getSellers = async (order) => {
      const sellersInfos = order.products
        .map((product) => ({
          _id: product.seller._id,
          typeOfUser: product.seller.typeOfUser,
        }))
        .filter(
          (item, index, self) =>
            index === self.findIndex((t) => t._id === item._id)
        );

      const access_token = localStorage.getItem("tokenAdmin");

      const config = {
        headers: { Authorization: `Bearer ${access_token}` },
      };

      Promise.all(
        sellersInfos.map((seller) => {
          if (seller.typeOfUser === "artist") {
            const req = axios.get(
              `${process.env.REACT_APP_API_BASE_URL}artist/${seller._id}`,
              config
            );
            return req;
          } else if (seller.typeOfUser === "artPro") {
            return axios.get(
              `${process.env.REACT_APP_API_BASE_URL}art-pro/${seller._id}`,
              config
            );
          } else if (seller.typeOfUser === "client") {
            return axios.get(
              `${process.env.REACT_APP_API_BASE_URL}client/${seller._id}`,
              config
            );
          }
          return false;
        })
      ).then(async (results) => setSellers(results));
    };

    const calculateFee = (order) => {
      if (order?.products?.length > 1) {
        setFees(
          order?.products.reduce((total, item) => {
            return {
              [item.seller._id]:
                (total[item.seller._id]
                  ? total[item.seller._id]
                  : total.price + total.transportTax) +
                item.price +
                item.transportTax,
            };
          })
        );
      } else {
        setFees({
          [order?.products[0].seller._id]:
            order?.products[0].price + order?.products[0].transportTax,
        });
      }
    };

    const getOrder = async () => {
      const orderDetail = await getOrderDetail(match.params.id);
      setOrder(orderDetail.data);
      getSellers(orderDetail.data);
      calculateFee(orderDetail.data);
    };
    getOrder();
  }, [match?.params?.id, openConfirmStatusChange]);

  const shipProduct = (productId) => {
    // open modal with informations
    // set internal state with product id to keep context
    setProductToShip(productId);
  };

  const handleChangeStatus = (newStatus) => {
    updateOrder(order?._id, newStatus).then(() =>
      setOpenConfirmStatusChange(false)
    );
  };

  return (
    <div className={clsx(classes.root, className)}>
      <h1>Commande #{order?._id}</h1>

      {/* Paper with main information (price / status) */}
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <h2>
              {order?.user?.firstName} {order?.user?.lastName}
            </h2>
          </Grid>
          <Grid item xs={6}>
            <p>{order?.status}</p>
            <p>
              Total : <b>{currencyFormat(order?.totalPrice)}</b>
            </p>
          </Grid>
          <Grid item xs={6}>
            <p>
              Référence du virement :{" "}
              <b>{order?.mangoBankwire?.WireReference}</b>
            </p>
            <p>
              Titulaire du contrat :{" "}
              <b>{order?.mangoBankwire?.BankAccount.OwnerName}</b>
            </p>
            <p>
              IBAN : <b>{order?.mangoBankwire?.BankAccount.IBAN}</b>
            </p>
            <p>
              BIC : <b>{order?.mangoBankwire?.BankAccount.BIC}</b>
            </p>
          </Grid>
        </Grid>
      </Paper>

      {/* Paper with contact information */}
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <h2>Informations de livraison</h2>
            <p>
              {order?.deliveryAddress?.deliveryFirstName}{" "}
              {order?.deliveryAddress?.deliveryLastName}
            </p>
            <p>Entreprise : {order?.deliveryAddress?.deliveryCompanyName}</p>
            <p>
              {order?.deliveryAddress?.deliveryStreetAddress} -{" "}
              {order?.deliveryAddress?.deliveryApartment}
            </p>
            <p>
              {order?.deliveryAddress?.deliveryPostCode}{" "}
              {order?.deliveryAddress?.deliveryCity}{" "}
              {order?.deliveryAddress?.deliveryCountry}
            </p>
          </Grid>

          <Grid item sm={6} xs={12}>
            <h2>Informations de facturation</h2>
            <p>
              {order?.billingAddress?.firstName}{" "}
              {order?.billingAddress?.lastName}
            </p>
            <p>Entreprise : {order?.billingAddress?.companyName}</p>
            <p>
              {order?.billingAddress?.streetAddress} -{" "}
              {order?.billingAddress?.apartment}
            </p>
            <p>
              {order?.billingAddress?.postCode} {order?.billingAddress?.city}{" "}
              {order?.billingAddress?.country}
            </p>
          </Grid>
        </Grid>
      </Paper>

      {/* Paper with oder information (includes comission) */}
      <Paper className={classes.paper}>
        <h2>Commande</h2>
        {order?.products?.map((product) => (
          <div
            style={{ display: "flex", marginTop: 10, marginBottom: 10 }}
            key={product._id}
          >
            <p style={{ flexGrow: 1 }}>{product.name}</p>
            <div>
              <p>Trasnporteur : {product.carrierName}</p>
              <p>Numéro colis : {product.trackingId}</p>
              <p>Suivi colis : {product.trackingLink}</p>
            </div>
            <p>
              Vendeur :
              {
                sellers.find((seller) => seller.data._id === product.seller._id)
                  ?.data?.firstName
              }{" "}
              {
                sellers.find((seller) => seller.data._id === product.seller._id)
                  ?.data?.lastName
              }
            </p>
            <div style={{ margin: "auto 15px" }}>
              <p>Prix : {currencyFormat(product.price)}</p>
              <p>Transport : {currencyFormat(product.transportTax)}</p>
            </div>
            {/* Button should appear only if the product wans't shipped yet */}
            <div>
              {!product.carrierName && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => shipProduct(product._id)}
                  fullWidth={true}
                >
                  Expédier l'oeuvre
                </Button>
              )}
              <Button
                color="primary"
                variant="outlined"
                component={Link}
                to={`/products/${product._id}`}
                fullWidth={true}
              >
                Voir
              </Button>
            </div>
          </div>
        ))}
      </Paper>

      <Paper className={classes.paper}>
        <h2>Paiement vendeur</h2>
        {sellers?.map((seller) => (
          <Grid container key={seller.data._id}>
            <Grid item xs={12} sm={6}>
              {seller.data.firstName}
            </Grid>
            <Grid item xs={12} sm={6}>
              {seller.data.lastName}
            </Grid>

            <Grid item xs={12} sm={6}>
              {seller.data.email}
            </Grid>

            <Grid item xs={12} sm={6}>
              {seller.data.phone}
            </Grid>

            <Grid item xs={12} sm={6}>
              Montant total des ventes :
              <Typography variant="h5">
                {currencyFormat(fees[seller.data._id])}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              Dont commission RunArtGallery :
              <Typography variant="h4">
                {currencyFormat(
                  calculateFees(fees[seller.data._id], 0, seller.data.roles)
                )}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Paper>

      {/* Paper with action buttons */}
      <Paper className={classes.paper}>
        <h2>Actions</h2>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => setOpenConfirmStatusChange(true)}
        >
          Passer la commande au status{" "}
          {status[status[order?.status]?.next]?.text}
        </Button>
      </Paper>

      {productToShip && (
        <ShipProduct
          order={order}
          open={!!productToShip}
          onClose={() => setProductToShip("")}
          productId={productToShip}
        />
      )}

      <Dialog
        open={openConfirmStatusChange}
        onClose={() => {
          setOpenConfirmStatusChange(false);
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmez vous le changement de statut ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Etes-vous sur de vouloir changer le statut de la commande ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirmStatusChange(false);
            }}
            color="primary"
          >
            Annuler
          </Button>
          <Button
            onClick={() => handleChangeStatus(status[order?.status].next)}
            color="primary"
            autoFocus
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>

      {/* Paper with timeline */}
      {/*<Paper className={classes.paper}>
        <h2>Historique</h2>
        <Timeline align="alternate">
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                12/01/2021 9:30
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot>
                <FastfoodIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Nouvelle commande
                </Typography>
                <Typography>Nouvelle commande passée par John Doe</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                12/01/2021 9:35
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <LaptopMacIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Paiement accepté
                </Typography>
                <Typography>Paiement validé</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary" variant="outlined">
                <HotelIcon />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Sleep
                </Typography>
                <Typography>Because you need rest</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="secondary">
                <RepeatIcon />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h1">
                  Repeat
                </Typography>
                <Typography>Because this is the life you love!</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
        </Paper>*/}
    </div>
  );
};

Orders.propTypes = {
  className: PropTypes.string,
};

export default Orders;
