import axios from "axios";

export async function getClients(criteria) {
  const access_token_admin = localStorage.getItem("tokenAdmin");

  if (access_token_admin) {
    const config = {
      headers: { Authorization: `Bearer ${access_token_admin}` },
    };

    return await axios.post(
      process.env.REACT_APP_API_BASE_URL + "client/search",
      criteria,
      config
    );
  }
}

export async function getClientById(clientId) {
  const access_token_admin = localStorage.getItem("tokenAdmin");
  if (access_token_admin) {
    const config = {
      headers: { Authorization: `Bearer ${access_token_admin}` },
    };

    return axios.get(
      process.env.REACT_APP_API_BASE_URL + "client/" + clientId,
      config
    );
  }

  return false;
}

export async function updateProfileByAdmin(client, clientId) {
  const access_token = localStorage.getItem("tokenAdmin");

  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
  };

  return axios.patch(
    process.env.REACT_APP_API_BASE_URL + "client/admin/profile",
    { clientId, newClient: client },
    config
  );
}

export async function registerClient(client) {
  return await axios.post(
    process.env.REACT_APP_API_BASE_URL + "client/register",
    client
  );
}
