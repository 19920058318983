import axios from "axios";

export async function updateAdminProfile(admin) {
  const access_token = localStorage.getItem("tokenAdmin");

  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
  };

  return axios.patch(
    process.env.REACT_APP_API_BASE_URL + "admin/profile",
    admin,
    config
  );
}
