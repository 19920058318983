import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { getLeadById } from "../../services/leadService";
import LeadForm from "./components/LeadForm";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
}));

const Leads = (props) => {
  const { className } = props;
  const leadId = props.match.params.id;
  const classes = useStyles();
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [lead, setLead] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const leadResponse = await getLeadById(leadId);
      setTitle(leadResponse.data.firstName + " " + leadResponse.data.lastName);
      setLead(leadResponse.data);
      setLoading(false);
    }
    fetchData();
  }, [leadId]);

  const handleSubmit = () => {
    toast.success("Prospect mis à jour avec succès");
    history.push("/leads");
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Button
        color="primary"
        className={classes.button}
        startIcon={<ArrowBackIcon />}
        component={Link}
        to={"/leads"}
      >
        Retour liste
      </Button>
      <h1 className={classes.title}>{title}</h1>
      {!loading && (
        <LeadForm onSubmit={handleSubmit} lead={lead} leadId={leadId} />
      )}
    </div>
  );
};

Leads.propTypes = {
  className: PropTypes.string,
};

export default Leads;
