import axios from "axios";

export async function login(email, password) {
  try {
    const token = await axios.post(
      process.env.REACT_APP_API_BASE_URL + "artist/login",
      {
        email,
        password,
      }
    );
    return { token: token.data, type: "Artist" };
  } catch (ex) {
    try {
      const token = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "art-pro/login",
        {
          email,
          password,
        }
      );
      return { token: token.data, type: "ArtPro" };
    } catch (ex2) {
      const token = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "admin/login",
        {
          email,
          password,
        }
      );
      return { token: token.data, type: "Admin" };
    }
  }
}
