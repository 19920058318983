import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { useDispatch, useStore } from "react-redux";
import { loadCurrentUser } from "../../store/user";
import { toast } from "react-toastify";
import TabPanel from "@material-ui/lab/TabPanel";
import Tab from "@material-ui/core/Tab";
import TabList from "@material-ui/lab/TabList";
import TabContext from "@material-ui/lab/TabContext";
import AppBar from "@material-ui/core/AppBar";
import ArtistForm from "./components/ArtistForm";
import ArtProForm from "./components/ArtProForm";
import AdminForm from "./components/AdminForm";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  registerForm: {
    width: "100%",
  },
  block: {
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
  },
  tabContent: {
    width: "100%",
    padding: 0,
  },
  appBar: {
    color: "white",
  },
}));

const Profile = (props) => {
  const { className } = props;
  const classes = useStyles();
  const store = useStore();
  const dispatch = useDispatch();
  // technically the user should be already loaded bu routes, then don't need to load it again here
  const [value, setValue] = useState(
    props.match.params.tab !== undefined
      ? props.match.params.tab
      : "profileInfos"
  );

  const handleTabChange = (element, newIndex) => {
    setValue(newIndex);
  };

  const handleSubmit = () => {
    dispatch(loadCurrentUser());

    toast.success("Votre profil à bien été mis à jour");
  };

  const renderForm = () => {
    const { currentUser } = store.getState().entities.user;

    if (currentUser.roles.includes("ROLE_ARTIST")) {
      return (
        <ArtistForm
          currentUser={currentUser}
          onSubmit={handleSubmit}
          admin={false}
        />
      );
    } else if (currentUser.roles.includes("ROLE_ART_PRO")) {
      return <ArtProForm currentUser={currentUser} onSubmit={handleSubmit} />;
    } else if (currentUser.roles.includes("ROLE_ADMIN")) {
      return <AdminForm currentUser={currentUser} onSubmit={handleSubmit} />;
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <div className={classes.registerForm}>
            <TabContext value={value}>
              <AppBar position="static" className={classes.appBar}>
                <TabList onChange={handleTabChange} aria-label="sous menu">
                  <Tab label="Profil" value="profileInfos" />
                  <Tab label="Infos bancaires" value="bankInfos" />
                </TabList>
              </AppBar>
              <TabPanel value="profileInfos" className={classes.tabContent}>
                <Paper className={classes.block}>
                  <h1>Edition du profil</h1>
                  <p>
                    <em>Les champs marqués d'une "*" sont obligatoires</em>
                  </p>
                </Paper>
                {renderForm()}
              </TabPanel>
              <TabPanel value="bankInfos" className={classes.tabContent}>
                <Paper className={classes.block}>
                  <h1>Indisponible pour le moment</h1>
                  <p>Vous serez informé de la disponibilité de ce formulaire</p>
                </Paper>
              </TabPanel>
            </TabContext>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
