import React from "react";
import Joi from "joi-browser";
import Form from "../../../common/Form";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { patchOrderProduct } from "../../../services/orderService";

const styles = (theme) => ({
  root: {
    "& .errorMsg": {
      color: "red",
    },
    "& .MuiButton-root, .MuiTextField-root": {
      width: "100%",
      margin: "15px 0",
    },
  },
  errorMsg: {
    marginTop: 10,
    color: theme.palette.error.main,
    textAlign: "center",
  },
});

class ShipProductForm extends Form {
  schema = {
    carrierName: Joi.string().required().label("Transporteur"),
    trackingId: Joi.string().required().label("Numéro de colis"),
    trackingLink: Joi.any(),
  };

  state = {
    data: {
      carrierName: "",
      trackingId: "",
      trackingLink: "",
    },
    errors: {},
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      const { productId, order } = this.props;

      console.log(data);
      const productToUpdate = {
        ...order.products.find((product) => product._id === productId),
        ...data,
      };

      await patchOrderProduct(order._id, productToUpdate);

      const { onSubmit } = this.props;
      onSubmit();
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.error;
        this.setState({ errors });
      }
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <form
        onSubmit={this.handleSubmit}
        autoComplete="off"
        className={classes.root}
      >
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            {this.renderInput(
              "carrierName",
              "Nom du transporteur",
              "text",
              null,
              true
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {this.renderInput(
              "trackingId",
              "Numéro de colis",
              "text",
              null,
              true
            )}
          </Grid>
          <Grid item xs={12}>
            {this.renderInput("trackingLink", "Lien de suivi colis", "text")}
          </Grid>
          <Grid item xs={12}>
            <div>{this.renderButton("Valider", "primary")}</div>
          </Grid>
        </Grid>
      </form>
    );
  }
}

ShipProductForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShipProductForm);
