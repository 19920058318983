import React from "react";

function CguvPro(props) {
  return (
    <div>
      <em>
        Mise à jour : <b>01 avril 2021</b>
      </em>
      <h2>Conditions Générales d’Utilisation Vendeurs</h2>

      <p>Préambule</p>
      <p>
        RunArtGallery.com est une Marketplace (Place de marché), éditée par la
        SAS RunArtGallery au capital de 2 000 €, RCS d’Aix en Provence : 000 000
        000 , siège social : 1040, chemin de Roquepertuse, 13880 VELAUX ,
        France, téléphone : +33(0)664626571, E-mail : contact@runartgallery.com,
        numéro de TVA intracommunautaire : FR 00000000000
      </p>

      <p>
        Runartgallery SaS édite sur https://www.runartgallery.com (dénommé
        ci-après le “Site”), une Marketplace (dénommé ci-après Place de Marché)
        permettant à des vendeurs majeurs, après leur inscription sur le Site
        https://www.runartgallery.com, d’être mis en relation via
        l’intermédiaire de RunArtGallery avec des Acheteurs inscrits sur le Site
        pour acheter des œuvres d’art originales, des œuvres en série limitées,
        des reproductions ou tirages d’art proposés à un prix fixe, ou soumis à
        offre si l’option est possible (ci-après dénommé le “Produit”).
      </p>

      <p>
        Ce Site est accessible à tous les acheteurs sous l’URL
        https://www.runartgallery. (dénommé ci-après “runartgallery.com”).{" "}
      </p>

      <p>
        Il est rappelé que Runartgallery en sa qualité d’hébergeur agit comme
        simple intermédiaire et n’est en aucun cas revendeur des Produits
        proposés par les Vendeurs au sein de la plateforme. La majorité des
        Produits sont expédiés et livrés aux Acheteurs par les Vendeurs, sous
        leur seule responsabilité. Certains Produits pourront être expédiés par
        Runartgallery.
      </p>

      <p>
        2.1 Acceptation et modification des conditions générales d’utilisation
      </p>

      <p>
        Les Conditions Générales d’Utilisation de la Marketplace (dénommé
        ci-après les “CGU”) ont pour objet de définir les conditions d’accès et
        d’utilisation.
      </p>

      <p>
        Toute utilisation du Site implique l’acceptation préalable, expresse et
        sans réserve par le Vendeur des CGU. Le vendeur est informé et reconnaît
        qu’en cliquant, lors de la création de son compte client, sur la case
        “je crée mon compte ” précédé de la mention “En cliquant sur S'inscrire,
        vous acceptez les Conditions générales d'Utilisation”, il est tenu par
        l'ensemble des dispositions des CGU. Le fait de cocher la case susvisée
        sera réputé avoir la même valeur qu'une signature manuscrite de la part
        du Vendeur. Le Vendeur reconnaît la valeur de preuve des systèmes
        d'enregistrement automatique de Runartgallery et, sauf à lui d'apporter
        preuve contraire, il renonce à les contester en cas de litige.
      </p>

      <p>
        Les ventes réalisées via le Site entre les Acheteurs et les Vendeurs
        sont régies par les Conditions Générales de Vente (ci-après les “CGV”),
        qui doivent également être acceptées par l’acheteur à chaque
        confirmation de commande. Les CGV venant en complément des CGU Vendeur..
      </p>

      <p>
        Runartgallery se réserve le droit, à tout moment, de refuser l'accès à
        la Place de Marché, unilatéralement et sans notification préalable, à
        tout Vendeur ne respectant pas les présentes CGU. Le Vendeur est informé
        que les CGU du Site pourront être modifiées à tout moment,
        unilatéralement par Runartgallery , notamment en cas d’évolution de ses
        services ou en cas d’évolutions légales, jurisprudentielles, éditoriales
        et/ou techniques. Runartgallery s’engage à informer le Vendeur du site
        de ces modifications par e-mail ou par le biais d’une mention spéciale
        affichée sur son compte Vendeur.
      </p>

      <p>
        Les présentes conditions générales sont applicables aux relations entre
        les parties à l’exclusion de toutes autres conditions, et notamment
        celles du Vendeur.. L'acceptation des présentes conditions générales
        suppose de la part des Vendeurs qu'ils jouissent de la capacité
        juridique nécessaire pour cela, ou à défaut qu'ils en aient
        l'autorisation d'un tuteur ou d'un curateur s'ils sont incapables, de
        leur représentant légal s'ils sont mineurs, ou encore qu'ils soient
        titulaires d'un mandat s'ils agissent pour le compte d'une personne
        morale.
      </p>

      <p>2.2 Description du service de la Place de Marché</p>

      <p>
        Les transactions effectuées sur la Place de Marché Runartgallery sont
        effectuées directement entre l’Acheteur et le Vendeur. Runartgallery
        n’est en aucun cas revendeur des produits proposés par les Vendeurs par
        l’intermédiaire du site. Les produits achetés via le site ne peuvent
        être repris ni échangés par Runartgallery .
      </p>

      <p>
        Runartgallery se réserve le droit, à tout moment, de refuser l'accès au
        Vendeur de manière temporaire ou définitive à la Place de marché, à tout
        Vendeur ne respectant pas les présentes CGU.{" "}
      </p>

      <p>2.3 Conditions et modalités d'accès à la plateforme</p>

      <p>
        Le site est accessible gratuitement à tout Vendeur à l’adresse
        «https://pro.Runartgallery.com», et il est sans obligation d’achat.
        Runartgallery propose des Services aux Vendeurs disponibles au paiement
        d’un abonnement mensuel ou annuel. L’utilisation de ces services n’est
        pas obligatoire pour vendre.
      </p>

      <p>
        Le Vendeur est seul responsable du bon fonctionnement de son équipement
        informatique et de son accès à internet.
      </p>

      <p>Abonnements</p>
      <p>Choix d’un Abonnement</p>
      <p>
        L’accès au Site est gratuit. Cependant, pour bénéficier de certains
        Services, le Vendeur pourra souscrire un Abonnement mensuel ou annuel.
        Pour ce faire, le Vendeur devra remplir le formulaire prévu à cet effet
        sur le Site.
      </p>

      <p>Validation du choix</p>
      <p>
        Dans le cadre de la souscription d’un Abonnement, le Vendeur devra
        renseigner ses informations de facturation. Le Vendeur aura alors la
        faculté de vérifier l’Abonnement choisi, ainsi que son prix éventuel. Si
        sa commande lui convient, il pourra la valider en cliquant sur le bouton
        prévu à cet effet sur le Site.
      </p>
      <p>Paiement par le Vendeur</p>
      <p>
        Une fois la commande validée, les Vendeurs seront invités à effectuer
        leur paiement en étant invités à se connecter à l'interface de paiement
        sécurisée comportant la mention « commande avec obligation de paiement »
        ou toute formule analogue. Confirmation de la souscription par
        Runartgallery Runartgallery s'engage à adresser au Vendeur un courrier
        électronique récapitulatif de la commande et lui en confirmant le
        traitement, reprenant toutes les informations y afférant.
      </p>
      <p>Durée de l’Abonnement</p>
      <p>
        L’Abonnement prend effet à compter de l’encaissement par Runartgallery
        du paiement correspondant du Vendeur.
      </p>
      <p>
        L’Abonnement est conclu pour une durée indéterminée. Il pourra être
        résilié à tout moment par le Vendeur sans que cette résiliation donne
        droit à un quelconque remboursement au Vendeur. Le droit de rétractation
        ne s’applique pas aux contrats de fourniture de services pleinement
        exécutés avant la fin du délai de rétractation ou dont l'exécution a
        commencé après accord préalable exprès de l’Artiste et renoncement
        exprès à son droit de rétractation.
      </p>
      <p>Résiliation anticipée de l’Abonnement par Runartgallery</p>
      <p>
        En cas de manquement du Vendeur à l’une quelconque des stipulations des
        présentes conditions générales, Runartgallery pourra résilier
        l’Abonnement de plein droit et sans intervention du juge. La résiliation
        de l’Abonnement au tort du Vendeur est sans préjudice d'éventuels
        dommages-intérêts auxquels Runartgallery.com pourrait prétendre du fait
        du manquement du Vendeur.
      </p>
      <p>Pour vendre sur Runartgallery.com, le Vendeur doit être :</p>
      <p>- Soit une personne physique majeure ayant capacité à contracter</p>
      <p>
        - Soit une personne morale. Dans ce cas la personne la représentant doit
        mentionner son identité, sa raison sociale, son numéro d’identification,
        son téléphone et son adresse e-mail.
      </p>
      <p>
        L’accès au Site est conditionné à une utilisation strictement
        personnelle par le Vendeur.{" "}
      </p>

      <p>Création d’un Compte personnel : </p>
      <p>
        Pour vendre des produits sur le Site, le Vendeur doit se créer un compte
        personnel (dénommé ci-après le “Compte”). Ce Compte permet à
        l’utilisateur de :{" "}
      </p>
      <p>
        - gérer ses informations personnelles (coordonnées, adresse e-mail, mot
        de passe){" "}
      </p>
      <p>
        - accéder au fonctions sociales du Site (commentaires, création de
        collections, partage sur les réseaux sociaux, messagerie...)
      </p>
      <p>
        - faire une simulation de commande (calcul des frais de livraison et du
        montant total de la Commande){" "}
      </p>
      <p>
        - accéder à ses factures et informations concernant les commandes
        (numéro de suivi de commande)
      </p>
      <p>- vendre des Produits sur le site</p>
      <p>
        - contacter le service client de Runartgallery{" "}
        <p>
          <p></p>Afin de s'assurer de la conformité des Vendeurs, face aux
          législations anti-corruption ainsi que pour vérifier leur probité et
          intégrité, notamment afin de prévenir l'usurpation d'identité, la
          fraude fiscale, le blanchiment d'argent et le financement du
          terrorisme, Runartgallery demande aux Vendeurs d’enregistrer un
          certain nombre de données afin de recevoir le paiement de vente des
          Vendeurs. Ceci est obligatoire pour les transactions supérieures à 50
          € ou équivalent dans une autre devise, et peut être demandé dès le
          premier gain, en fonction du pays du vendeur. Les pièces demandées
          lors de la vérification peuvent être : Carte d’identité, Passeport,
          Permis de conduire.
        </p>
        <p>
          Le Vendeur peut créer un Compte en cliquant sur le bouton “créer un
          compte” situé en bas dans la page de d’accueil du Site. Pour créer son
          Compte, le Vendeur doit renseigner une adresse électronique valide et
          un mot de passe. Il s’engage à conserver confidentiel son mot de passe
          (le Vendeur étant seul responsable de l’accès à son compte grâce à son
          identifiant et mot de passe). Le Vendeur s’engage à ne fournir que des
          informations exactes, complètes et actualisées et à ne pas usurper
          l’identité d’un tiers. En cas de changement ou modification, le
          Vendeur doit informer Runartgallery sans délai via l’outil de
          messagerie à sa disposition. En cas de perte, détournement ou
          utilisation frauduleuse de votre identifiant et/ou mot de passe, le
          Vendeur s’engage à informer immédiatement le service client à
          l’adresse contact@runartgallery.com
        </p>
        <p>Le Vendeur doit également respecter les règles suivantes :</p>
        <p>
          - ne pas harceler les autres utilisateurs du Site, ne pas tenir des
          propos calomnieux, diffamatoires, homophobes ou racistes sur le Site,
          et ne pas tenir des propos contrevenant aux textes légaux ou
          réglementaires français en vigueur, aux droits des personnes, à
          l'ordre public et aux bonnes mœurs.{" "}
        </p>
        <p></p>- ne pas obtenir des informations sur les autres utilisateurs que
        ce soit de façon manuelle ou automatisée. Il est également interdit
        d’accéder au compte personnel d’un autre participant.
      </p>

      <p>Suppression du Compte personnel :</p>
      <p>
        L’utilisateur peut à tout moment supprimer son Compte et toutes ses
        données définitivement depuis la section paramètres de son compte.{" "}
      </p>

      <p>
        Runartgallery se réserve le droit, à tout moment, de refuser l'accès à
        la Place de marché, et supprimer unilatéralement et sans notification
        préalable, à tout Vendeur ne respectant pas les présentes CGU notamment,
        et sans que cette liste ne soit limitative, en cas de d’informations
        fausses, inexactes, incomplètes ou périmées mentionnées par le Vendeur
        ou bien en cas de fraude aux moyens de paiement, tentative d'escroquerie
        ou toute autre infraction pénale.
      </p>

      <p>Notation du Vendeur par l’Acheteur </p>
      <p>
        Les Acheteurs se voient offrir la faculté de contribuer au contenu du
        Site par la publication de commentaires sur leur utilisation des
        Produits et leur relation avec le Vendeur afin d’évaluer la transaction.
        Les commentaires seront soumis à validation de Runartgallery.com ou de
        son équipe de modérateurs. En cliquant sur l'onglet « Valider », afin de
        publier son commentaire, l’acheteur accorde une licence de droit
        d'auteur non exclusive à titre gratuit à Runartgallery portant sur ledit
        commentaire. À ce titre, l’Acheteur ou le Vendeur autorisent
        Runartgallery à communiquer au public en ligne, en tout ou partie, son
        commentaire sur le Site, les newsletters à venir de Runartgallery et les
        sites des partenaires de Runartgallery. Le Vendeur et l’Acheteur
        autorisent Runartgallery à reproduire son commentaire aux fins de le
        communiquer au public en ligne et de réaliser des supports de
        communication et de promotion du Site. Le Vendeur et l’Acheteur
        autorisent Runartgallery à traduire dans toute langue son commentaire
        aux fins de le communiquer au public en ligne et de le reproduire sur
        ses supports commerciaux et promotionnels. Le droit d'adaptation
        comprend également le droit d'effectuer les modifications techniquement
        nécessaires à l'exploitation du commentaire dans d'autres formats. La
        présente licence est accordée pour une utilisation permanente.
      </p>

      <p>
        Runartgallery ne peut pas garantir que le Site fonctionne dans tout
        environnement d’exploitation et qu’il soit utilisable à tout moment,
        sans aucune interruption ou erreur, ni que toutes ces erreurs pourront
        être corrigées. Le Site étant en constante évolution, il peut-être sujet
        sans préavis à des modifications et des interruptions temporaires ou
        définitives. La responsabilité de Runartgallery ne saurait en aucun cas
        être engagée en cas d’indisponibilité de la Place de Marché du fait de
        ces modifications et/ou interruptions. Runartgallery se réserve la
        possibilité d'interrompre temporairement ou de modifier sans préavis
        l'accès à tout ou partie du Site, notamment pour en assurer la
        maintenance, ou pour toutes autres raisons, sans que l'interruption
        n'ouvre droit à aucune obligation ni indemnisation.
      </p>

      <p>
        2.4 Obligations liées aux transactions réalisées par l’intermédiaire de
        Runartgallery
      </p>
      <p>
        Le Vendeur doit s’engager pour vendre, à s’identifier comme agissant en
        qualité de Vendeur professionnel dès lors qu’il effectue des ventes
        régulières de Produits par l’intermédiaire du Site Runartgallery.com. Le
        Vendeur s’engage à respecter la législation en vigueur dans son pays en
        matière d’exercice d’activité commerciale (immatriculation, obligations
        fiscales, sociale et comptable). Le Vendeur s’engage à satisfaire de
        façon optimale l’Acheteur. Pour cela il met en œuvre tous les moyens
        dont il dispose pour offrir à l’acheteur un service de qualité. Le
        Vendeur répondra aussi bien aux e-mails du service client du Site qu’aux
        messages des Acheteurs dans un délai de 15 jours ouvrés maximum après
        réception du message. La communication avec le Support peut se faire en
        français ou en anglais. Si le message que vous avez reçu est établi dans
        une autre langue, n’hésitez pas à contacter le service client pour
        demander de l’aide. Les échanges avec le service client sont strictement
        confidentiels. Le Vendeur s’engage à ne pas divulguer à des tiers les
        échanges qu’il a pu avoir avec le service client. Les contenus du Site
        sont susceptibles d'être protégés par le droit d'auteur et le droit des
        bases de données. Toute représentation, reproduction, traduction,
        adaptation ou transformation, intégrale ou partielle, réalisée
        illégalement et sans le consentement de Runartgallery ou de ses ayants
        droit ou ayants cause constitue une violation des Livres I et III du
        Code de la propriété intellectuelle et sera susceptible de donner lieu à
        des poursuites judiciaires pour contrefaçon.{" "}
      </p>

      <p>
        Le Vendeur s'engage contractuellement à l'égard de Runartgallery à ne
        pas utiliser, reproduire ou représenter, de quelque manière que ce soit,
        les contenus du Site, qu'ils soient ou non protégés par un droit de
        propriété intellectuelle, à une autre fin que celle de leur lecture par
        un robot ou un navigateur. Cette interdiction n'est pas applicable aux
        robots d'indexation ayant pour seul objet de scanner le contenu du Site
        aux fins d'indexation.
      </p>

      <p>
        2.4.1 Obligations concernant l’offre de produits présentées par le
        Vendeur{" "}
      </p>
      <p>
        Le Vendeur déclare avoir reçu de Runartgallery toutes les explications
        et précisions utiles pouvant lui permettre d’utiliser les Services objet
        du présent contrat. Le Vendeur reconnaît que ses besoins et les Services
        proposés par Runartgallery sont en adéquation et qu’il a souscrit au
        contrat en connaissance de cause et en disposant de toutes les
        informations nécessaires lui permettant de produire un consentement
        libre et éclairé. Le cas échéant, le Vendeur s’engage à fournir à
        Runartgallery tous les éléments nécessaires à la réalisation des
        Services. De même, le Vendeur s’engage à informer Runartgallery de toute
        circonstance susceptible d’affecter l’exécution du présent contrat dès
        qu’il en a connaissance.
      </p>

      <p>
        Le Vendeur déclare qu’il ne vendra que des Produits de sa propre
        création et/ou qu’il dispose des droits et autorisations nécessaires à
        cette fin, ou qu’il est propriétaire dudit bien, et qu’il a toutes les
        garanties et certificats reconnus sur le marché attestant d’une parfaite
        authenticité, ainsi que l’autorisation de vendre. Le cas échéant, le
        Vendeur déclare avoir effectué au préalable toute démarche nécessaire,
        telle que demandes d’autorisations, licences et déclarations
        administratives. Notamment, lorsque le Vendeur n’est pas le titulaire
        original des droits de propriété intellectuelle sur les Produits, il
        déclare les avoir régulièrement acquis. Le Vendeur s'interdit à ce titre
        notamment de vendre tout Produit consistant en des Produits
        contrefaisants au sens du Code la propriété intellectuelle. Le Vendeur
        garantit Runartgallery contre tout recours qui serait entrepris à son
        encontre en cas de défaut de telles déclarations et autorisations. Le
        Vendeur est seul responsable de la mise en vente des Produits qu'il
        propose sur le Site. Le Vendeur s'engage à n’utiliser le site que pour
        ses besoins propres et pour les seules finalités visées au présent
        contrat. Tout usage illégal ou de manière générale non autorisé des
        Services entraînera la résiliation immédiate du compte du Vendeur,
        Runartgallery se gardant toute possibilité d’action en justice,
        notamment demande de dommages et intérêts. Ainsi, le Vendeur s’engage
        notamment, sans que cette liste soit limitative à ne pas exposer de
        Produits ou à ne pas publier d’Offre pouvant :
      </p>
      <p>
        - porter atteinte à l'ordre public, aux bonnes mœurs ou pouvant heurter
        la sensibilité des mineurs ;
      </p>
      <p>
        - porter atteinte de quelque manière que ce soit aux droits à la
        réputation, à la vie privée, au droit ou à l'image d’un tiers ;
      </p>
      <p>
        - être dénigrant, diffamatoire, porter atteinte à l'image, à la
        réputation d'une marque ou d'une quelconque personne physique ou morale,
        de quelque manière que ce soit ;
      </p>
      <p>- présenter des œuvres à caractères pornographiques ou pédophiles ;</p>
      <p>
        - porter atteinte à la sécurité ou à l'intégrité d'un État ou d'un
        territoire, quel qu'il soit ;
      </p>
      <p>
        - porter atteinte aux droits de propriété intellectuelle de quelque
        personne que ce soit ;
      </p>
      <p>
        - inciter à la haine, à la violence, au suicide, au racisme, à
        l'antisémitisme, à la xénophobie, à l'homophobie, faire l'apologie des
        crimes de guerre ou des crimes contre l'humanité ;
      </p>
      <p>- inciter à commettre un crime, un délit ou un acte de terrorisme ;</p>
      <p>
        - inciter à la discrimination d'une personne ou d'un groupe de personnes
        en raison de son appartenance à une ethnie, à une religion, à une race,
        ou du fait de son orientation sexuelle ou de son handicap.
      </p>
      <p>
        - ne pas présenter d’objets volés ; des médicaments ou drogues de tous
        types ; des armes, des armes de guerre et munitions ; des animaux
        vivants et des boissons alcooliques.
      </p>
      <p>
        La mise en vente de contrefaçons et de copies non autorisées est
        interdite sur RunArtGallery.
      </p>
      <p>
        Les copies non autorisées incluent notamment les copies destinées à
        abuser l’acheteur en attribuant faussement l’objet en vente à un artiste
        existant et/ou connu, les copies illégales et les copies pirates. Ces
        objets peuvent enfreindre les droits d’auteur ou de marque commerciale
      </p>
      <p>
        Les contrefaçons sont illégales et sont interdites sur RunArtGallery.
        Exemples :{" "}
      </p>
      <p>Faux, copies ou répliques d’œuvres </p>
      <p>
        Si vous n'êtes pas certain que votre objet soit authentique, ou si
        l'authenticité de l'objet n'a pas pu être confirmée par un expert,
        l'objet ne peut pas être mis en vente Nous pouvons retirer des annonces
        si des acheteurs déposent des réclamations indiquant qu'elles ne sont
        pas authentiques. Il se peut que ces objets ne soient pas remis en vente
        Les activités non conformes aux règlements de RunArtGallery sont
        susceptibles d'entraîner des sanctions, notamment : la suppression ou
        l'annulation d'annonces, ou du compte vendeur.
      </p>

      <p>
        Tout traitement, transmission, publication, diffusion ou représentation
        des Produits par le Vendeur est effectuée sous sa seule et entière
        responsabilité. Le Vendeur s'engage à ne pas entraver ou perturber le
        Site et les serveurs de Runartgallery et à se conformer aux conditions
        requises, aux procédures, aux règles générales qui lui sont communiquées
        par Runartgallery pour la publication des Produits. Tout usage illégal
        ou de manière générale non autorisé du Site entraînera la suppression
        immédiate du compte du Vendeur, sans préjudice des éventuels
        dommages-intérêts auxquels Runartgallery pourrait prétendre. Le Vendeur
        garantit en conséquence RunArtGallery contre tout dommage susceptible de
        lui être causé du fait de son utilisation du Site, et y compris les
        éventuels frais d’avocat et de procédure, et s’engage à ce titre à
        intervenir à toute instance judiciaire engagée à son encontre du fait de
        son utilisation du Site.
      </p>

      <p>
        La mise en vente d’un Produit est ouverte à tout Vendeur titulaire des
        droits requis sur le Produit objet de l'annonce publiée. Les Vendeurs
        s'interdisent de publier plusieurs annonces visant à la vente ou la
        location d'un même Produit. Les annonces devront être rédigées dans un
        langage correct et compréhensible. Elles devront se conformer aux
        dispositions légales en vigueur et devront être dépourvues de tout
        critère discriminatoire quant à la qualité de l'éventuel cocontractant.
        Les Vendeurs auront la possibilité de télécharger des photographies
        relatives au Produit objet de l’annonce. Les photographies téléchargées
        devront être au format jpg, d'une taille maximale ne pouvant excéder 25
        mégaoctets et d’une qualité minimale de 1500 pixel de côté. Le Vendeur
        garantit à RunArtGallery qu'il dispose des droits, en particulier de
        propriété intellectuelle, afférents à ces illustrations, qui lui
        permettent de les utiliser afin de présenter les œuvres. Au cours de la
        publication de l’annonce, le Vendeur disposera, via son espace
        personnel, de la faculté de modifier la fiche descriptive de l’annonce.
        Ces modifications seront possibles tout au long de la publication de
        l’annonce. L’Artiste devra saisir les données essentielles du Produit,
        notamment :
      </p>
      <p>Son prix s’il est à vendre ;</p>
      <p>Le nom de son auteur ; </p>
      <p>
        Ses caractéristiques (notamment : dimension, poids, technique,
        matériaux…) ;{" "}
      </p>
      <p>Une description complète ; </p>
      <p>Les éventuels frais de livraison ; </p>
      <p>Le pays d’expédition du Produit.</p>
      <p>
        Le Vendeur reconnaît que la publication de son annonce sur le Site
        emporte automatiquement et sans intervention de RunArtGallery la
        publication de ladite annonce sur les sites partenaires et affiliés.
        RunArtGallery ne garantit en aucun cas l'exactitude des données
        contenues dans les annonces. Il appartient à tout Acheteur intéressé par
        l’annonce de s'assurer de l'exactitude de toutes ces données
        préalablement à toute transaction effective. De même, RunArtGallery ne
        garantit en aucun cas aux Vendeurs la vente ou la location effective des
        Produits visées par l’annonce. Le Vendeur est seul responsable de ses
        propositions et s’engage envers RunArtGallery à ce que les Produits mis
        en vente sur le Site soient bien disponibles à la vente. Par ailleurs,
        le Vendeur s’engage à notifier sans délai à RunArtGallery tout rappel ou
        retrait de Produits dont il serait à l’initiative ou dont il aurait
        connaissance.
      </p>
      <p>2.4.2 Obligations concernant le prix des produits</p>
      <p>
        Le Vendeur définit librement le prix de vente des Produits qu’il met en
        vente sur le site dans le respect des lois et règlements en vigueur. Ce
        prix doit être mentionné sur le Site toutes taxes et frais compris
        (notamment TVA, frais d’emballage, etc), mais hors frais de livraison,
        RunArtGallery ajoutant ces frais au prix de la commande lorsque
        l’Acheteur valide sa commande. Les frais de livraison sont calculés sur
        la base des dimensions et du poids de l’œuvre mentionnés par l’artiste
        et du choix de l’acheteur. En cas de différence entre les dimensions et
        le poids mentionnés par le Vendeur et celui réel observé par le
        transporteur, le surcoût de transport sera intégralement à la charge du
        Vendeur.
      </p>

      <p>
        En accord avec le vendeur, dans le cadre de négociations ou d’opérations
        commerciales, des remises peuvent être consenties par RunArtGallery,
        suite à une offre de l’acheteur. Les remises commerciales pouvant être
        consenties par RunArtGallery aux Acheteurs sont en intégralité à la
        charge du Vendeur. Ce montant de remise est fixé à un maximum de 50% du
        prix fixé par le Vendeur. Le montant de la remise est automatiquement
        déduit de la somme payée au Vendeur par RunArtGallery.{" "}
      </p>
      <p>2.4.3 Obligations concernant la vente des Produits</p>
      <p>
        Le contrat de vente des Produits proposés par le Vendeur sur
        RunArtGallery est conclu entre le Vendeur et l’Acheteur. Le Vendeur
        s’engage à ne proposer sur RunArtGallery.com que des Produits
        disponibles à la vente. Dans le cas où le Produit ne serait plus
        disponible, le Vendeur s’engage à supprimer l’annonce ou à changer le
        statut du Produit afin de montrer qu’il n’est plus disponible. Le
        Vendeur est le seul responsable en cas d’indisponibilité d’un produit
        dont il a seul le contrôle. Lorsqu’un Vendeur reçoit une commande, il
        est informé par mail. Toutes les informations concernant cette commande
        sont disponibles sur son compte RunArtGallery. Le Vendeur a alors 15
        jours après réception de l’information pour confirmer la commande. Le
        Vendeur s’engage à consulter régulièrement ses e-mails (au moins une
        fois par jour) à l’adresse fournie à RunArtGallery lors de l’ouverture
        de son compte. En cas de changement d’adresse e-mail, les messages
        seront envoyés à la nouvelle adresse e-mail du Vendeur. La confirmation
        de commande par le Vendeur entraîne l’obligation d’expédier le(s)
        œuvre(s) dans un délai de 30 jours ouvrés. À défaut de réponse du
        Vendeur dans le délai imparti, la commande sera annulée automatiquement
        et le compte du Vendeur mentionné comme étant “en absence temporaire”.
      </p>

      <p>
        Le vendeur doit honorer toutes les commandes qu’il reçoit des Produits
        qu’il propose à la vente. En cas de non-respect, RunArtGallery se
        réserve le droit d’interrompre temporairement ou définitivement le
        compte du Vendeur. Par ailleurs, le Vendeur est seul responsable de sa
        capacité de traitement des commandes, de tout défaut d’expédition,
        retard de livraison, toute absence de livraison ou livraison partielle,
        livraison erronée, vol ou erreur ou action liés à la gestion et à la
        livraison des Produits ainsi que du traitement de tout litige en lien
        avec les Offres publiées sur le Site ou sur tout Produit.
      </p>
      <p>2.4.4 Obligations concernant la livraison des produits</p>

      <p>
        Le Vendeur doit obligatoirement joindre au colis un certificat
        d’authenticité. Le Vendeur s’engage à emballer soigneusement, solidement
        et sérieusement les œuvres commandées de façon à éviter que les produits
        ne soient endommagés pendant le transport. Les œuvres voyagent aux frais
        et risques du Vendeur. Le Vendeur a 15 jours après réception de
        l’information pour confirmer la commande. Les informations transmises
        par RunArtGallery lui permettent de livrer la commande. Le Vendeur
        s’engage à expédier le(s) œuvre(s) dans un délai de 30 jours ouvrés à
        compter de la confirmation de commande. Si le délai d’expédition devait
        être supérieur, le Vendeur s’engage à en faire mention dans la
        description du produit, à en informer RunArtGallery et l’Acheteur. Le
        Vendeur s’engage à respecter envers l’Acheteur la garantie légale de
        délivrance conforme (article 1604 du Code civil), la garantie légale
        contre les vices cachés (article 1604 et s. du Code civil) et la
        garantie de sécurité (article 1386-1 et s. du Code civil) envers tous
        les Acheteurs.
      </p>

      <p>
        2.4.5 Obligation concernant les contestations relatives aux produits
      </p>
      <p>
        Le Vendeur s’engage à respecter la garantie légale de conformité
        vis-à-vis des Acheteurs consommateurs (article L. 211-1 et s. Code de la
        consommation). En cas de réclamation d’un Acheteur, le litige sera réglé
        directement entre l’Acheteur et le Vendeur à l’aide de l’outil
        messagerie proposé par le Site. Le Vendeur devra résoudre à l’amiable
        tous les litiges l’opposant à l’Acheteur. Néanmoins si le litige n’était
        pas résolu dans un délai de 7 jours ouvrés à compter de la réception de
        l’information, RunArtGallery après en avoir informé le Vendeur pourra
        procéder au remboursement du Produit à l’Acheteur. Ce montant étant
        alors déduit de la somme reversée au Vendeur par RunArtGallery . En cas
        où l’Acheteur utilise son droit de rétractation, RunArtGallery en sa
        qualité de tiers de confiance, procédera au remboursement de la totalité
        des sommes versées, (incluant les frais de livraison) par l’Acheteur
        dans les meilleurs délais et au plus tard dans les quatorze jours
        suivant la date à laquelle ce droit a été exercé (sous réserve que les
        produits soient tous bien réceptionnés dans leur état et emballage
        original par le vendeur). Le cas échéant, RunArtGallery peut différer le
        remboursement jusqu'à récupération des Produits par le Vendeur ou
        jusqu'à ce que l’Acheteur ait fourni une preuve de l'expédition et la
        réception des Produits par le vendeur, au plus tôt des deux évènements.
        Le cas échéant, RunArtGallery effectue le remboursement à l’Acheteur en
        utilisant le même moyen de paiement que celui utilisé par l’Acheteur
        pour la transaction initiale. Les frais directs de renvoi du Produit
        sont à la charge de l’Acheteur. La responsabilité de l’Acheteur n’est
        engagée qu’à l’égard de la dépréciation du Produit résultant de
        manipulations autres que celles nécessaires pour établir la nature, les
        caractéristiques et le bon fonctionnement de ce Produit. RunArtGallery
        se réserve le droit de suspendre temporairement ou définitivement le
        compte du Vendeur au cas où le Vendeur ne respecterait pas ses
        obligations.
      </p>
      <p>2.5 Conditions financières</p>
      <p>
        Produits vendus par le Vendeur Pour chaque commande de Produit reçue par
        le Vendeur, RunArtGallery recevra du Vendeur une commission d’un montant
        défini au paragraphe “Commissions de vente”, frais de port et TVA en
        sus. La commission de vente s’applique sur l’intégralité de la commande
        + frais de port et tout autre frais éventuels.
      </p>

      <p>
        RunArtGallery reversera au Vendeur le montant total de la commande
        minorée de la commission après confirmation de la réception de la
        commande par l’Acheteur et une fois le délai de rétractation passé. Le
        paiement sera effectué sur le compte bancaire du Vendeur mentionné sur
        son compte. Lorsque l’Acheteur n’a pas confirmé la réception du Produit
        et si le Vendeur n’a pas reçu de contestation dans le délai imparti à
        l’Acheteur pour faire la réclamation, et qu’aucune autre information
        n’est disponible du livreur ou des services de douane, alors le produit
        sera considéré comme reçu 45 jours suivant la date d’expédition
        effective.
      </p>

      <p>
        Lorsque l’Acheteur a confirmé ne pas avoir reçu l'oeuvre, et que le
        vendeur est dans l'impossibilité de produire une preuve de livraison, ou
        que le statut de suivi fourni par le livreur ne montre aucune évolution
        depuis plus de 30 jours, le colis sera considéré comme perdu, et
        l'acheteur sera remboursé. La commission reste acquise à RunArtGallery
        en cas d’annulation de commande à l’initiative du Vendeur lorsque
        celle-ci est abusive ou répétée. RunArtGallery se réserve le droit de
        suspendre le paiement des ventes à l’Acheteur au cas où le Vendeur
        manquerait à ses obligations. Le paiement au Vendeur peut être demandé à
        tout moment une fois le délai de rétractation de 14 jours passé. Le
        paiement est effectué par virement sur le compte bancaire du Vendeur
        mentionné dans son compte RunArtGallery au plus tard 15 jours après
        validation définitive de la commande. La demande s’effectue depuis le
        compte vente du Vendeur. D’autres méthodes de paiement peuvent également
        être utilisées dans certains pays, en accord avec le vendeur.
      </p>

      <p>Commissions de vente</p>
      <p>
        Pour chaque vente d'oeuvre originale, RunArtGallery percevra une
        commission correspondant au barème ci-joint pour les comptes "Galeries",
        pour les comptes bénéficiaires du service Premium, se référer au barème
        adéquat, au moment de la vente, et le barème général paragraphe XX pour
        tous les autres type de comptes. Le montant de la commission s'entend
        Hors Taxe et hors frais de paiement par le site sécurisé « XXX ». Voir
        barèmes ci-dessous
      </p>

      <p>Artistes</p>

      <table>
        <tr>
          <td>Marchands/Galeries Commissions (HT)</td>
          <td></td>
        </tr>
        <tr>
          <td>0-1000€</td>
          <td>12%</td>
        </tr>
        <tr>
          <td>1001-3000€</td>
          <td>12%</td>
        </tr>
        <tr>
          <td>3001-6000€</td>
          <td>12%</td>
        </tr>
        <tr>
          <td>6001- 10000€</td>
          <td>12%</td>
        </tr>
        <tr>
          <td>10001- 25000€</td>
          <td>10%</td>
        </tr>
        <tr>
          <td>25001-100000€</td>
          <td>8%</td>
        </tr>
        <tr>
          <td>100001 à 500000€</td>
          <td>6%</td>
        </tr>
        <tr>
          <td>500001 et plus</td>
          <td>5%</td>
        </tr>
      </table>

      <p>.6 Suspension et résiliation</p>
      <p>
        es présentes CGU sont conclues pour une durée indéterminée. Chaque
        partie peut à tout moment sans avoir besoin d’aucune justification
        résilier les CGU en respectant un préavis d’un mois. RunArtGallery se
        réserve le droit de suspendre définitivement au cas où le Vendeur ne
        respecterait pas ses obligations après envoi d’une mise en demeure d’une
        lettre recommandée restée sans effet pendant une durée de 15 jours et
        résilier le contrat par l’envoi d’un recommandé avec avis de réception.
        RunArtGallery se réserve le droit de suspendre définitivement le contrat
        au cas où le Vendeur ne respecterait pas ses obligations pour faute
        grave sans aucun préavis et sans que le Vendeur ne puisse prétendre à
        une quelconque indemnité.
      </p>

      <p>2.7 Réclamations et traitement des litiges</p>
      <p>
        es présentes CGU sont soumises à l'application du droit français. Les
        présentes CGU peuvent être modifiées à tout moment par RunArtGallery.
        Les conditions générales applicables aux Vendeurs sont celles en vigueur
        au jour de sa commande ou de sa connexion sur le présent Site, toute
        nouvelle connexion à l'espace personnel emportant acceptation le cas
        échéant des nouvelles conditions générales. Pour toute réclamation merci
        de contacter le service client à l’adresse suivante :
        contact@runartgallery.com
      </p>

      <p>2.8 Données personnelles</p>

      <p>
        Les Vendeurs disposent de la libre faculté de fournir des informations
        personnelles les concernant. La fourniture d'informations personnelles
        n'est pas indispensable pour la navigation sur le Site. En revanche,
        l'inscription sur le présent Site suppose la collecte par RunArtGallery
        d'un certain nombre d'informations personnelles concernant les Vendeurs.
        Les Vendeurs ne souhaitant pas fournir les informations nécessaires à
        l'inscription ne pourront pas vendre sur le présent Site. Les données
        personnelles collectées font l'objet d'un traitement informatique et
        sont exclusivement réservées à RunArtGallery. Les données récoltées sont
        nécessaires à la bonne administration du Site, ainsi qu'au respect de
        ses obligations contractuelles par RunArtGallery. Ces données sont
        conservées par RunArtGallery en cette unique qualité. RunArtGallery
        s'engage à ne pas les utiliser dans un autre cadre ni à les transmettre
        à des tiers, hors accord exprès du Vendeur ou cas prévus par la loi. Les
        données personnelles récoltées ne font l'objet d'aucun transfert vers
        l'étranger. Les coordonnées de tous les Internautes inscrits sur le
        présent Site sont sauvegardées pour une durée raisonnable nécessaire à
        la bonne administration du Site et à une utilisation normale des
        données. Ces données sont conservées dans des conditions sécurisées,
        selon les moyens actuels de la technique, dans le respect des
        dispositions de la Loi Informatique et Libertés du 6 janvier 1978.
        Conformément à cette dernière, les Internautes disposent d'un droit
        d'opposition, d'interrogation, d'accès et de rectification des données
        qu'ils ont fournies. Pour cela, il leur suffit d'en faire la demande à
        RunArtGallery, en la formulant par courrier électronique ou postal à
        l'adresse du siège de RunArtGallery mentionnée au préambule des
        présentes conditions générales. Cookies et adresses IP des Vendeurs
      </p>
      <p>
        Afin de permettre à tous les internautes une navigation optimale sur le
        présent Site ainsi qu'un meilleur fonctionnement des différentes
        interfaces et applications, RunArtGallery pourra procéder à
        l'implantation d'un cookie sur le poste informatique de l'Internaute.
        Les cookies permettent de stocker des informations relatives à la
        navigation sur le Site (date, page, heures), ainsi qu'aux éventuelles
        données saisies par les Internautes au cours de leur visite (recherches,
        login, e-mail, mot de passe). Ces cookies ont vocation à être conservés
        sur le poste informatique de l'Internaute pour une durée variable allant
        jusqu'à 13 mois, et pourront être lus et utilisés par RunArtGallery lors
        d'une visite ultérieure du Vendeur sur le présent Site. Le Vendeur
        dispose de la possibilité de bloquer, modifier la durée de conservation,
        ou supprimer ces cookies via l'interface de son navigateur (généralement
        : outils ou options / vie privée ou confidentialité). Dans un tel cas,
        la navigation sur le présent Site ne sera pas optimisée. Si la
        désactivation systématique des cookies sur le navigateur du Vendeur
        l'empêche d'utiliser certains Services, ce dysfonctionnement ne saurait
        en aucun cas constituer un dommage pour le Vendeur qui ne pourra
        prétendre à aucune indemnité de ce fait. Les Internautes ont aussi la
        possibilité de supprimer les cookies implantés sur leur ordinateur, en
        se rendant dans le menu de leur navigateur prévu à cet effet
        (généralement, outils ou options / vie privée ou confidentialité). Une
        telle action fait perdre aux Internautes le bénéfice apporté par les
        cookies. Collecte des adresses IP
      </p>
      <p>
        L'adresse IP correspond à une série de chiffres séparés par des points
        permettant l'identification unique d'un ordinateur sur le réseau
        Internet. RunArtGallery se réserve le droit de collecter l'adresse IP
        publique de tous les Internautes. La collecte de cette adresse IP sera
        effectuée de façon anonyme. L'adresse IP des Internautes sera conservée
        pendant la durée requise par la loi. RunArtGallery devra communiquer
        toutes les données personnelles relatives à un Internaute à la Police
        sur réquisition judiciaire ou à toute personne sur décision judiciaire.
        L'adresse IP pourra faire l'objet d'un rapprochement avec l'identité
        effective de l'Internaute en cas de communication de cette information
        par un fournisseur d'accès à internet. Collecte des informations pour le
        paiement
      </p>
      <p>
        Le prestataire de paiement procède à la collecte de certaines données
        notamment dans le but de :
      </p>
      <p>Vérifier l’identité des clients</p>
      <p>
        Vérifier les transactions en utilisant un système de suivi qui calcule
        et évalue les risques et vérifie l’origine des capitaux
      </p>
      <p>
        Vérifier si une personne physique dispose des autorisations nécessaires
        pour effectuer la transaction
      </p>
      <p>
        Les données permettant la sécurisation des transactions sont conservées
        selon la durée légale qui est généralement de 5 ans. Vous pouvez
        demander à consulter vos données à tout moment en envoyant un mail à
        contact@runartgallery.com
      </p>

      <p>2.9 Propriété intellectuelle</p>
      <p>2.9.1. Protection légale des contenus du Site </p>
      <p>
        Les contenus du Site sont susceptibles d'être protégés par le droit
        d'auteur et le droit des bases de données. Toute représentation,
        reproduction, traduction, adaptation ou transformation, intégrale ou
        partielle, réalisée illégalement et sans le consentement de
        RunArtGallery.com ou de ses ayants droit ou ayants cause constitue une
        violation des Livres I et III du Code de la propriété intellectuelle et
        sera susceptible de donner lieu à des poursuites judiciaires pour
        contrefaçon.{" "}
      </p>
      <p>2.9.2. Protection contractuelle des Contenus du Site </p>
      <p>
        L'Internaute s'engage contractuellement à l'égard d’RunArtGallery.com à
        ne pas utiliser, reproduire ou représenter, de quelque manière que ce
        soit, les contenus du Site, qu'ils soient ou non protégés par un droit
        de propriété intellectuelle, à une autre fin que celle de leur lecture
        par un robot ou un navigateur. Cette interdiction n'est pas applicable
        aux robots d'indexation ayant pour seul objet de scanner le contenu du
        Site aux fins d'indexation.
      </p>
      <p>2.9.3. Propriété intellectuelle des Œuvres </p>
      <p>
        Le Vendeur cède à RunArtGallery, à titre non exclusif et gratuit, tous
        les droits d’exploitation sur les images des Produits (ci-après les
        “Œuvres”).
      </p>
      <p>Durée de la cession</p>
      <p>
        La cession est consentie par le Vendeur à RunArtGallery dès transmission
        de l’Œuvre intervenue dans le cadre de l’utilisation du Site. La
        transmission emportera en conséquence soumission du Produit aux termes
        de la présente cession. La cession est consentie par le Vendeur à
        RunArtGallery pour toute la durée de la propriété littéraire et
        artistique. Par durée de la propriété littéraire et artistique, il
        convient d'entendre la durée prévue par le Code de la propriété
        intellectuelle et les lois étrangères et toutes les prolongations ou
        prorogations de cette durée par une convention internationale, une
        directive européenne, une loi française ou étrangère. Etendue
        géographique de la cession
      </p>
      <p>
        La présente cession est consentie pour le monde entier et pour toutes
        langues et ce pour tous les modes d'exploitation prévus dans les
        présentes conditions générales.
      </p>
      <p>
        Droits cédés Destination de l’Œuvre L’Œuvre est destinée à une
        exploitation civile et commerciale.
      </p>

      <p>Cession du droit de reproduction</p>
      <p>La cession du droit de reproduction comporte :</p>
      <p>
        - le droit de reproduire l’Œuvre par tous procédés électroniques et
        mécaniques connus ou inconnus à ce jour, notamment optique, magnétique
        et numérique, en vue de sa communication au public en ligne, sur
        catalogue papier et sur les Produits ;
      </p>
      <p>
        - le droit de moduler, compresser ou décompresser l’Œuvre pour son
        stockage, son transfert, sa diffusion ou toute autre utilisation ;{" "}
      </p>
      <p>- le droit d'inclure l’Œuvre dans une base de données ;</p>
      <p>- le droit de reproduire des extraits de l’Œuvre.</p>

      <p>Cession du droit de représentation</p>
      <p>La cession du droit de représentation comporte :</p>
      <p>
        - le droit de communication au public de l’Œuvre, en tout ou partie,
        dans le monde entier, par tout réseau permettant la transmission de
        données, notamment par réseaux et services numériques interactifs ou
        non, internet, sites web, réseaux de télécommunication, réseau satellite
        et réseau de téléphone portable ;
      </p>
      <p>
        - le droit d'exploiter l’Œuvre, en tout ou partie, par tout réseau,
        notamment de télécommunication ou câblé, par tous procédés interactifs
        inhérents à ce mode d'exploitation ;
      </p>
      <p>- le droit de publier des extraits de l’Œuvre.</p>

      <p>Cession du droit d'adaptation</p>
      <p>La cession du droit d’adaptation comporte :</p>
      <p>
        - le droit de corriger, d’améliorer et de modifier l’Œuvre en tout ou
        partie ;
      </p>
      <p>
        - le droit d'effectuer toutes modifications nécessaires à l’exercice des
        droits cédés ;
      </p>
      <p>
        - le droit de dissocier l’Œuvre ou un de ses éléments de son ensemble,
        afin d’exploiter l’Œuvre ;
      </p>
      <p>
        - le droit d’associer l’Œuvre à une ou plusieurs autres Œuvres en un
        seul fichier ;
      </p>
      <p>- le droit de convertir l’Œuvre dans un autre langage informatique.</p>

      <p>
        Le Vendeur reconnaît que les adaptations de l’Œuvre réalisées par
        RunArtGallery au titre des droits qui lui sont cédés, sont la propriété
        intellectuelle exclusive de RunArtGallery.
      </p>

      <p>Droit moral</p>
      <p>
        Dans le cadre de l'utilisation ou de l'exploitation de l’Œuvre,
        RunArtGallery pourra utiliser le terme de son choix pour désigner
        l’Œuvre.
      </p>

      <p>Garanties</p>
      <p>
        Le Vendeur garantit à RunArtGallery qu’il est le seul auteur et/ou
        propriétaire de l’Œuvre et qu'à ce titre il est le seul titulaire de
        l'ensemble des droits de propriété intellectuelle relatifs à l’Œuvre et
        qu’aucun tiers ne pourra prétendre à la qualité d’auteur ou de coauteur
        d’une ou plusieurs desdites Œuvres. Le Vendeur s’engage notamment à
        respecter le droit à l’image des personnes représentées sur les Œuvres
        ainsi que les droits de propriété intellectuelle des tiers, notamment
        les droits d'auteur, les droits sur les dessins et modèles, ainsi que
        les droits sur les brevets et sur les marques. Le Vendeur garantit à
        RunArtGallery la jouissance paisible des droits cédés en vertu des
        présentes conditions générales. Le Vendeur garantit RunArtGallery contre
        toute action, réclamation, revendication ou opposition de la part de
        toute personne invoquant un droit de propriété intellectuelle ou un acte
        de concurrence déloyale et/ou parasitaire, du fait de l’Œuvre.
        Reproduction et protection de l’Œuvre Le Vendeur est informé de la
        faculté pour RunArtGallery de recourir à des mesures techniques pour
        protéger les supports matériels et fichiers numériques reproduisant les
        Œuvres, aux fins d’en empêcher ou d’en limiter les utilisations non
        autorisées. RunArtGallery aura la faculté d’utiliser dans le cadre des
        exploitations des Œuvres des informations sous forme électronique
        destinées à les identifier et à informer les tiers sur le régime des
        conditions et modalités autorisées en vue de leur d’utilisation. Le
        Vendeur pourra, sur demande écrite, obtenir auprès de RunArtGallery des
        informations quant aux caractéristiques essentielles des mesures
        techniques de protection et informations susvisées auxquelles
        RunArtGallery aurait effectivement recours pour assurer l’exploitation
        des Œuvres. Notation du Vendeur Les Acheteurs se voient offrir la
        faculté de contribuer au contenu du Site par la publication de
        commentaires sur leur utilisation des Produits et leur relation avec le
        Vendeur afin d’évaluer la transaction. Les commentaires seront soumis à
        validation de RunArtGallery.com et de son équipe de modérateurs. En
        cliquant sur l'onglet « Valider », afin de publier son commentaire,
        l’acheteur accorde une licence de droit d'auteur non exclusive à titre
        gratuit à RunArtGallery portant sur ledit commentaire. À ce titre, le
        Vendeur autorise RunArtGallery à communiquer au public en ligne, en tout
        ou partie, son commentaire sur le Site, les newsletters de RunArtGallery
        et les sites des partenaires de RunArtGallery. Le Vendeur autorise
        RunArtGallery à reproduire son commentaire aux fins de le communiquer au
        public en ligne et de réaliser des supports de communication et de
        promotion du Site. Le Vendeur autorise RunArtGallery à traduire dans
        toute langue son commentaire aux fins de le communiquer au public en
        ligne et de le reproduire sur ses supports commerciaux et promotionnels.
        Le droit d'adaptation comprend également le droit d'effectuer les
        modifications techniquement nécessaires à l'exploitation du commentaire
        dans d'autres formats. La présente licence est accordée pour une
        utilisation permanente.
      </p>

      <p>2.10 Responsabilité </p>
      <p>
        RunArtGallery.com s'engage à apporter le soin et la diligence nécessaire
        à la fourniture de produits de qualité conforme aux spécifications des
        présentes Conditions Générales. RunArtGallery ne répond que d'une
        obligation de moyens concernant les services objet des présentes. La
        responsabilité de RunArtGallery envers le Vendeur ne peut être engagée
        que pour des faits qui lui seraient directement imputables et qui lui
        causeraient un préjudice directement lié à ces faits. RunArtGallery
        n'engagera pas sa responsabilité en cas de force majeure ou de faute du
        Vendeur, telles que définies au présent article :
      </p>

      <p>Force majeure </p>
      <p>
        Au sens des présentes conditions générales, sera considéré comme un cas
        de force majeure opposable au Vendeur tout empêchement, limitation ou
        dérangement du service du fait d'incendie, d'épidémie, d'explosion, de
        tremblement de terre, de fluctuations de la bande passante, de
        manquement imputable au fournisseur d'accès, de défaillance des réseaux
        de transmission, d'effondrement des installations, d'utilisation
        illicite ou frauduleuse des mots de passe, codes ou références fournis
        au client, de piratage informatique, d'une faille de sécurité imputable
        à l'hébergeur du Site ou aux développeurs, d'inondation, de panne
        d'électricité, de guerre, d'embargo, de loi, d'injonction, de demande ou
        d'exigence de tout gouvernement, de réquisition, de grève, de boycott,
        ou autres circonstances hors du contrôle raisonnable de RunArtGallery.
        Dans de telles circonstances, RunArtGallery.com sera dispensé de
        l'exécution de ses obligations dans la limite de cet empêchement, de
        cette limitation ou de ce dérangement. Faute du Vendeur Au sens des
        présentes CGU, sera considérée comme une faute de l’acheteur opposable à
        ce dernier toute mauvaise utilisation du service, faute, négligence,
        omission ou défaillance de sa part ou de celle de ses préposés,
        non-respect des conseils donnés par RunArtGallery.com sur son site,
        toute divulgation ou utilisation illicite du mot de passe, des codes et
        références du client, ainsi que le renseignement d’informations erronées
        ou l’absence de mise à jour de telles informations dans son espace
        personnel. Sera également considérée comme une faute du Client la mise
        en œuvre de tout procédé technique, tels que des robots, ou des requêtes
        automatiques, dont la mise en œuvre contreviendrait à la lettre ou à
        l’esprit des présentes CGU. Les données, notamment commentaires,
        publiées sur le Site par l’Acheteur le sont sous sa propre
        responsabilité. Dans ce cadre, RunArtGallery bénéficiera du statut
        d'hébergeur des données au sens de l'article 6-I-2 de la Loi pour la
        confiance dans l'économie numérique du 21 juin 2004. Conformément à
        l'alinéa 3 du même article, RunArtGallery.com ne pourra voir sa
        responsabilité civile ou pénale engagée à raison de ces commentaires, à
        moins que, dès le moment où il a eu connaissance de l'activité ou de
        l'information illicite, il n'ait agi promptement pour retirer ces
        informations ou en rendre l'accès impossible. Les contenus du Site sont
        publiés à titre indicatif, sans garantie d'exactitude. RunArtGallery.com
        ne peut en aucun cas être tenu responsable du fait d'une omission, d'une
        inexactitude ou de toute erreur contenue dans ces informations et qui
        serait à l'origine d'un dommage direct ou indirect causé à l'Internaute.
        RunArtGallery décline toute responsabilité en ce qui concerne les ventes
        entre les acheteurs et les vendeurs via l’utilisation de la plateforme
        RunArtGallery.com, auxquelles elle reste étrangère. Toute plainte
        relative aux informations figurant sur le descriptif des produits ou aux
        livraisons des produits sera redirigée vers le vendeur désigné, qui
        assumera l'entière et seule responsabilité à ce titre.
      </p>

      <p>
        L’utilisateur est seul responsable de l’utilisation qu’il fait du Site
        et il s’engage à indemniser et/ou dédommager de tout préjudice ou perte
        que RunArtGallery pourrait subir dans le cas où sa responsabilité serait
        engagée par un tiers à cause d’une opération en rapport avec
        l’utilisation du site par l’acheteur.{" "}
      </p>

      <p>Messagerie interne </p>

      <p>
        Le contenu des boîtes de réception et d'envoi ne fait l'objet d'aucune
        garantie de conservation de la part de RunArtGallery et il appartient
        aux Vendeurs de sauvegarder lesdits contenus. La perte de ces contenus,
        quelle qu'en soit la cause, ne pourra constituer un dommage pour les
        Vendeurs qui ne pourront prétendre à aucune indemnité de ce fait. Tout
        Vendeur qui serait victime d'abus (spams, publicités non souhaitées ou
        autre) pourra en informer RunArtGallery.com qui prendra toutes mesures
        utiles.{" "}
      </p>

      <p>2.11 Stipulations finales </p>
      <p>
        Les présentes conditions générales sont soumises à l'application du
        droit français. Les présentes conditions générales peuvent être
        modifiées à tout moment par RunArtGallery. Les conditions générales
        applicables au Vendeur sont celles en vigueur au jour de sa commande ou
        de sa connexion sur le présent Site, toute nouvelle connexion à l'espace
        personnel emportant acceptation le cas échéant des nouvelles conditions
        générales. En cas d’échec de la médiation, le différend sera tranché par
        les Tribunaux d’Aix en Provence nonobstant pluralité de défendeurs ou
        appels en garantie, y compris pour les procédures en référé ou sur
        requêtes. La nullité d'une des clauses du présent contrat n'entraînera
        pas la nullité des autres clauses du contrat ou du contrat dans sa
        globalité, qui garderont leur plein effet et portée. Dans une telle
        hypothèse, les parties devront dans la mesure du possible remplacer la
        stipulation annulée par une stipulation valable correspondant à l'esprit
        et à l'objet des présentes. L'absence d'exercice par RunArtGallery des
        droits qui lui sont reconnus par les présentes ne pourra en aucun cas
        être interprétée comme une renonciation à faire valoir lesdits droits.
        Les présentes conditions générales sont proposées en français. Les
        stipulations des présentes conditions générales s'appliquent sous
        réserve du respect des dispositions impératives du Code de la
        consommation concernant les clauses abusives dans les contrats conclus
        entre un professionnel et un consommateur.
      </p>

      <p>Conditions Générales de Vente</p>
      <p>Préambule</p>

      <p>
        RunArtGallery.com est une Marketplace (Place de marché), éditée par la
        SAS RunArtGallery au capital de 2 000 €, RCS d’Aix en Provence : 000 000
        000 , siège social : 1040, chemin de Roquepertuse, 13880 VELAUX ,
        France, téléphone : +33(0)664626571, E-mail : info@runartgallery.com,
        numéro de TVA intracommunautaire : FR 00000000000 Runartgallery SaS
        édite sur https://www.runartgallery.com (dénommé ci-après le “Site”),
        une Marketplace (dénommé ci-après Place de Marché) permettant à des
        vendeurs majeurs, après leur inscription sur le Site
        https://www.runartgallery.com, d’être mis en relation via
        l’intermédiaire de RunArtGallery avec des Acheteurs inscrits sur le Site
        pour acheter des œuvres d’art originales, des œuvres en série limitées,
        ou soumis à offre si l’option est possible (dénommé ci-après dénommé le
        “Produit”). Ce Site est accessible à tous les acheteurs sous l’URL
        https://www.runartgallery. ( dénommé ci-après “runartgallery.com”). Il
        est rappelé que RunArtGallery en sa qualité d’hébergeur agit comme
        simple intermédiaire et n’est en aucun cas revendeur des Produits
        proposés par les Vendeurs au sein de la plateforme. Les Produits sont
        expédiés et livrés aux Acheteurs par les Vendeurs, sous leur seule
        responsabilité. Les produits ne seront donc ni repris, ni échangés par
        RunArtGallery.
      </p>

      <p>3.1 Le contrat de Vente</p>
      <p>Commande</p>
      <p>
        Afin de passer commande, l’acheteur pourra sélectionner un ou plusieurs
        Produits et les ajouter à son panier. La disponibilité des Produits, son
        prix, ses caractéristiques et son délai de livraison sont indiqués sur
        le Site, dans la fiche descriptive de chaque article. Lorsque la
        commande sera complète, l’Acheteur pourra accéder à son panier en
        cliquant sur le bouton prévu à cet effet. Validation de la commande par
        l’Acheteur En consultant son panier, l’Acheteur aura la faculté de
        vérifier le nombre ainsi que la nature des Produits qu'il a choisi et
        pourra vérifier le prix unitaire, ainsi que le prix global. Il aura la
        possibilité de retirer un ou plusieurs Produits de son panier. Si la
        commande lui convient, l’Acheteur pourra la valider. En confirmant sa
        commande l’Acheteur prend connaissance et accepte les présentes CGV. Il
        accédera alors à un formulaire sur lequel il pourra soit saisir ses
        identifiants de connexion s'il en possède déjà, soit s'inscrire sur le
        Site en complétant le formulaire d’inscription au moyen des informations
        personnelles le concernant.
      </p>

      <p>Paiement par le Client</p>
      <p>
        Dès lors qu'il est connecté ou après avoir parfaitement complété le
        formulaire d’inscription, l’Acheteur sera invité à contrôler ou modifier
        ses coordonnées de livraison et de facturation, puis sera invité à
        effectuer le paiement en étant redirigés à cet effet sur l'interface de
        paiement sécurisée comportant la mention « commande avec obligation de
        paiement » ou toute formule analogue. Confirmation de la commande par
        RunArtGallery Une fois le paiement effectivement reçu par RunArtGallery
        (ou intermédiaire financier), ce dernier s'engage à en accuser réception
        à l’Acheteur par voie électronique, dans un délai maximal de 24 heures.
        Dans le même délai, RunArtGallery.com s'engage à adresser à l’Acheteur
        un courrier électronique récapitulatif de la commande et lui en
        confirmant le traitement, reprenant toutes les informations y afférant.
        Pour les commandes d’impression d’art, RunArtGallery peut annuler une
        commande après la confirmation de commande, au cas où le fichier fournit
        par le Vendeur ne serait pas conforme aux spécifications de
        RunArtGallery. Réception de la commande par le Vendeur Le Vendeur est
        informé par RunArtGallery qu’une ou plusieurs œuvres qu’il a mises en
        ligne ont été commandées. Le Vendeur a 15 jours après réception de
        l’information pour confirmer la commande. Les informations transmises
        par RunArtGallery lui permettent de livrer la commande. À défaut de
        réponse du Vendeur dans le délai imparti, la commande sera annulée
        automatiquement. Le contrat conclu entre l’Acheteur et le Vendeur sera
        également automatiquement annulé, chacune des parties étant libéré de
        ses obligations. Notamment l’Acheteur est assuré que son paiement ne
        sera pas débité, ou bien qu’il sera remboursé s’il a déjà été effectué.
        Dans le cas où l’œuvre ne serait plus disponible, le Vendeur s’engage à
        supprimer l’annonce ou à changer le statut de l’œuvre afin de montrer
        qu’elle n’est plus disponible. Le Vendeur est le seul responsable en cas
        d’indisponibilité d’un produit dont il a seul le contrôle. Si une
        commande a été tout de même passée par un Acheteur. La commande sera
        annulée par le Vendeur. RunArtGallery informera alors l’Acheteur et le
        remboursera le cas échéant. Confirmation de commande par le Vendeur
        Lorsque la commande est confirmée par le Vendeur, un message est envoyé
        à l’Acheteur pour confirmer sa commande. Le Vendeur s’engage alors à
        livrer les Produits dans le délai mentionné. Pour les commandes de
        produits numériques, le fichier numérique commandé par l’Acheteur est
        disponible dès confirmation de la commande et du règlement en allant
        dans la section Mes Achats. Commande expédiée La commande est expédiée
        par le Vendeur. Le Vendeur s’engage à respecter envers l’Acheteur la
        garantie légale de délivrance conforme (article 1604 du Code civil), la
        garantie légale contre les vices cachés (article 1604 et s. du Code
        civil) et la garantie de sécurité (article 1386-1 et s. du Code civil)
        envers tous les Acheteurs.
      </p>

      <p>Réception de la commande</p>
      <p>
        Le vendeur s'engage à envoyer son colis en utilisant un transporteur
        disposant d'un service de suivi en ligne, avec assurance, et dont le
        délai de livraison sera aussi court que possible (de l'ordre de 3 à 7
        jours), le délai de livraison maximal estimé ne devant en aucun cas
        excéder 30 jours. L’Acheteur doit confirmer sans délai dans “MON COMPTE”
        la bonne réception de chaque produit commandé. A défaut, le produit sera
        considéré comme ayant été réceptionné dès que celui-ci sera indiqué
        comme livré par le service de suivi du transporteur. Dans le cas où
        aucune information de suivi n’est disponible par le transporteur, et
        qu’aucune information n’a été reçue de la part de l’acheteur, ni des
        services de douane, le produit sera considéré comme ayant été
        réceptionné dans un délai de 45 jours à compter de la date de débit de
        son compte bancaire. Lorsque l’Acheteur a confirmé ne pas avoir reçu
        l'oeuvre, et que le vendeur est dans l'impossibilité de produire une
        preuve de livraison, ou que le statut de suivi fourni par le livreur ne
        montre aucune évolution depuis plus de 30 jours, le colis sera considéré
        comme perdu, et l'acheteur sera remboursé. Abonnements Choix d’un
        Abonnement L’accès au Site est gratuit. Cependant, pour bénéficier de
        certains Services, le Vendeur pourra souscrire à un Abonnement mensuel
        ou annuel. Pour ce faire, le Vendeur devra remplir le formulaire prévu à
        cet effet sur le Site. Validation du choix Dans le cadre de la
        souscription d’un Abonnement, le Vendeur devra renseigner ses
        informations de facturation. Le Vendeur aura alors la faculté de
        vérifier l’Abonnement choisi, ainsi que son prix éventuel. Si sa
        commande lui convient, il pourra la valider en cliquant sur le bouton
        prévu à cet effet sur le Site. Paiement par le Vendeur Une fois la
        commande validée, les Vendeurs seront invités à effectuer leur paiement
        en étant invités à se connecter à l'interface de paiement sécurisée
        comportant la mention « commande avec obligation de paiement » ou toute
        formule analogue. Confirmation de la souscription par RunArtGallery Une
        fois le paiement effectivement reçu par RunArtGallery (ou son partenaire
        de paiement sécurisé), ce dernier s'engage à en accuser réception au
        Vendeur par voie électronique, dans un délai maximal de 24 heures. Dans
        le même délai, RunArtGallery s'engage à adresser au Vendeur un courrier
        électronique récapitulatif de la commande et lui en confirmant le
        traitement, reprenant toutes les informations y afférant. Durée de
        l’Abonnement L’Abonnement prend effet à compter de l’encaissement par
        RunArtGallery du paiement correspondant du Vendeur. L’Abonnement est
        conclu pour une durée indéterminée. Il pourra être résilié à tout moment
        par le Vendeur sans que cette résiliation donne droit à un quelconque
        remboursement au Vendeur. Le droit de rétractation ne s’applique pas aux
        contrats de fourniture de services pleinement exécutés avant la fin du
        délai de rétractation et dont l'exécution a commencé après accord
        préalable exprès de l’Artiste et renoncement exprès à son droit de
        rétractation. Résiliation anticipée de l’Abonnement par RunArtGallery En
        cas de manquement du Vendeur à l’une quelconque des stipulations des
        présentes conditions générales, RunArtGallery pourra résilier
        l’Abonnement de plein droit et sans intervention du juge. La résiliation
        de l’Abonnement au tort du Vendeur est sans préjudice d’éventuels
        dommages-intérêts auxquels RunArtGallery.com pourrait prétendre du fait
        du manquement du Vendeur.
      </p>

      <p>3.2 Prix et paiement</p>

      <p>Prix</p>
      <p>
        Les prix applicables sont ceux affichés sur le Site au jour de la
        commande. Ces prix peuvent être modifiés à tout moment par le Vendeur.
        Les prix affichés ne sont valables qu'au jour de la commande et ne
        portent pas effet pour l'avenir. Les prix indiqués sur le Site sont
        entendus dans la devise indiquée, toutes taxes comprises pour les œuvres
        originales. Hors indication contraire, les frais de livraison et de
        frais de règlement sont en sus. Le fait que l’Acheteur valide sa
        commande implique qu’il paye le prix indiqué. Modalité de paiement Le
        Client peut effectuer son règlement en choisissant une méthode de
        paiement parmi celles indiquées sur son panier au moment de sa commande.
        Les paiements par carte bancaire se font au moyen de transactions
        sécurisées fournies par les prestataires de paiement MangoPay. Dans le
        cadre des paiements par carte bancaire, RunArtGallery n'a accès à
        aucunes données relatives aux moyens de paiement du Client: le paiement
        est effectué directement par l’établissement bancaire. En cas de
        paiement par virement bancaire, les délais de livraison ne commencent à
        courir qu'à compter de la date de l’encaissement du paiement par
        RunArtGallery. Facturation RunArtGallery adressera ou mettra à
        disposition de l’Acheteur une facture par voie électronique après chaque
        paiement. L’Acheteur accepte expressément de recevoir les factures par
        voie électronique. Défaut de paiement Les dates de paiement convenues ne
        peuvent être retardées sous quelque prétexte que ce soit, y compris en
        cas de litige. Toute somme non payée à l’échéance donnera lieu, de plein
        droit et sans mise en demeure, à l’application de pénalités de retard
        calculées sur la base d’un taux égal à 3 fois le taux d’intérêt légal,
        sans que cette pénalité nuise à l’exigibilité des sommes dues en
        principal. En outre, tout retard de paiement aura pour conséquence la
        facturation au Client défaillant de frais de recouvrement d'un montant
        de 50 euros, l’exigibilité immédiate de toutes les sommes restant dues
        quels que soient les délais convenus, majorés d’une indemnité de 20% du
        montant à titre de clause pénale, ainsi que la possibilité de résilier
        le contrat unilatéralement au tort du Client. La présente clause
        s’inscrit dans le cadre des dispositions de l’article 1152 du Code civil
        permettant au juge de réduire l’indemnité si le juge estime qu’elle est
        excessive. Réserve de propriété Les Produits vendus restent la propriété
        du Vendeur jusqu'au complet paiement de leur prix, conformément à la
        présente clause de réserve de propriété.
      </p>
      <p>3.3 Frais d’expédition</p>

      <p>
        Sauf indication contraire, les frais de ports et taxes à l’importation
        sont à la charge de l’acheteur et peuvent être payés en sus. Le Vendeur
        s’engage à expédier le(s) Produit(s) dans un délai de 30 jours ouvrés à
        compter de la confirmation de commande. Si le délai d’expédition devait
        être supérieur, le Vendeur s’engage à en faire mention dans la
        description du produit, à en informer RunArtGallery et l’Acheteur. Les
        Œuvres sont expédiées à l’adresse indiquée par l’acheteur dans “Mon
        Compte” Il appartient à l’Acheteur de s’assurer que les informations
        qu’il communique à RunArtGallery sont correctes et lui permettront de
        recevoir les produits achetés sur le site. Le Vendeur s’engage à
        emballer soigneusement , solidement et sérieusement les œuvres
        commandées de façon à éviter que les produits ne soient endommagés
        pendant le transport. Les œuvres voyagent aux frais et risques du
        Vendeur. Dès que l’Acheteur prend possession de l’œuvre physiquement, le
        risque de perte ou d’endommagement des produits lui sont transférés. Le
        Vendeur s’engage à respecter envers l’Acheteur la garantie légale de
        délivrance conforme (article 1604 du Code civil), la garantie légale
        contre les vices cachés (article 1604 et s. du Code civil) et la
        garantie de sécurité (article 1386-1 et s. du Code civil) envers tous
        les Acheteurs. Réserves: Lors de la réception de l’œuvre ou de tout
        autre produit commandé, l’Acheteur s’engage à vérifier le colis avant de
        l’accepter et notifier au transporteur tout problème, dommage ou réserve
        concernant l’état du colis immédiatement. En outre, l’Acheteur s’engage
        à informer RunArtGallery via la messagerie du site et par tout autre
        moyen toutes réserves sur le produit qui est livré (en notifiant par
        exemple colis endommagé, paquet ouvert, bruit suspect dans le colis….).
        Le Vendeur tiendra RunArtGallery informé de l’expédition à chaque
        commande via le compte Vendeur. À défaut de cette information,
        RunArtGallery pourra annuler la transaction.
      </p>

      <p>3.4 Droit de rétractation et Garanties </p>
      <p>
        Service clientèle Le service clientèle du Site est accessible du lundi
        au vendredi de 9 h à 17h (GMT +1) au numéro de téléphone non surtaxé
        suivant : 000000000000, par courrier électronique à : contact@
        RunArtGallery.com ou par courrier postal à l’adresse indiquée à
        l’article 1 des présentes conditions générales. Dans ces deux derniers
        cas, RunArtGallery s'engage à apporter une réponse sous 5 jours ouvrés.
        Droit de rétractation – Vente à distance Conditions d’exercice du droit
        de rétractation Conformément à la législation en vigueur en matière de
        vente à distance, l’Acheteur consommateur concluant un contrat avec un
        Vendeur dispose d'un délai de quatorze jours francs pour exercer son
        droit de rétractation sans avoir à justifier de motifs ni à payer de
        pénalités, à l'exception, le cas échéant, des frais de retour. Le délai
        mentionné à l'alinéa précédent, court à compter soit du jour où le
        contrat à distance est conclu pour les contrats relatifs à la fourniture
        d’une prestation de service et/ou à la fourniture d’un contenu numérique
        non fourni sur un support matériel, soit de la réception du bien par
        l’Acheteur ou un tiers, autre que le transporteur, désigné par lui, pour
        les contrats de vente de biens et les contrats de prestation de services
        incluant la livraison de biens. Dans le cas d'une commande portant sur
        plusieurs biens livrés séparément ou dans le cas d'une commande d'un
        bien composé de lots ou de pièces multiples dont la livraison est
        échelonnée sur une période définie, le délai court à compter de la
        réception du dernier bien ou lot ou de la dernière pièce. Pour les
        contrats prévoyant la livraison régulière de biens pendant une période
        définie, le délai court à compter de la réception du premier bien.
        Lorsque le délai de quatorze jours expire un samedi, un dimanche ou un
        jour férié ou chômé, il est prorogé jusqu'au premier jour ouvrable
        suivant. La décision de rétractation devra être notifiée au Vendeur via
        la messagerie du site au moyen d’une déclaration dénuée d'ambiguïté.
        L’Acheteur a, par exemple, la possibilité d’utiliser le formulaire type
        fourni à la fin des présentes conditions générales. En tout état de
        cause, le Vendeur adressera à l’Acheteur au plus tôt un accusé de
        réception de ladite rétractation par courrier électronique. Aucun renvoi
        en contre-remboursement ne sera accepté, pour quel qu’en soit la raison.
        Effets du droit de rétractation Le Vendeur renvoie ou restitue les
        produits au Vendeur ou à toute personne désignée par ce dernier, sans
        retard excessif et, au plus tard, dans les quatorze jours suivant la
        communication de sa décision de se rétracter. Les retours sont à
        effectuer dans leur état d’origine et complets dans un conditionnement
        identique à celui utilisé pour l’expédition. L’Acheteur le cas échéant
        doit aussi inclure dans l’emballage une copie de la facture ou du bon de
        livraison. En cas où l’Acheteur utilise son droit de rétractation,
        RunArtGallery en sa qualité de tiers de confiance, procédera au
        remboursement de la totalité des sommes versées, (incluant les frais de
        livraison) à l’Acheteur dans les meilleurs délais et au plus tard dans
        les quatorze jours suivant la date à laquelle ce droit a été exercé. Le
        cas échéant, RunArtGallery peut différer le remboursement jusqu'à
        récupération des Produits par le Vendeur ou jusqu'à ce que l’Acheteur
        ait fourni une preuve de la livraison des Produits, au plus tôt des deux
        évènements. Au-delà, la somme due est, de plein droit, productive
        d'intérêts au taux légal en vigueur, tel que précisé à l’article L.
        242-4 du Code de la consommation Le cas échéant, RunArtGallery effectue
        le remboursement à l’Acheteur en utilisant le même moyen de paiement que
        celui utilisé par l’Acheteur pour la transaction initiale. Les frais
        directs de renvoi du Produit sont à la charge de l’Acheteur. La
        responsabilité de l’Acheteur n’est engagée qu’à l’égard de la
        dépréciation du Produit résultant de manipulations autres que celles
        nécessaires pour établir la nature, les caractéristiques et le bon
        fonctionnement de ce Produit. Les conditions, délais et modalités
        d’exercice du droit de rétractation sont exposés dans le formulaire type
        fourni à la fin des présentes conditions générales. Exclusions du droit
        de rétractation : De même, le droit de rétractation n’est pas applicable
        aux contrats exécutés intégralement par les deux parties à la demande
        expresse de l’Acheteur avant que ce dernier n'exerce son droit de
        rétractation. Lors de la validation de la commande d’un contenu
        numérique indépendant de tout support matériel avant l'expiration du
        délai de rétractation, le renoncement de l’Acheteur au droit de
        rétractation sera manifesté par le fait de cocher la case correspondant
        à la phrase suivante : « Je renonce expressément à mon droit de
        rétractation de 14 jours pour les Produits livrés ».
      </p>

      <p>Résolution du contrat sur l’initiative du Client</p>
      <p>
        L’Acheteur consommateur peut dénoncer le contrat par lettre recommandée
        avec demande d'avis de réception en cas de dépassement de la date de
        livraison du bien excédant 21 jours. L'Acheteur sera alors remboursé des
        sommes engagées par lui lors de la commande. La présente clause n'a pas
        vocation à s'appliquer si le retard de livraison est dû à un cas de
        force majeure. En pareil cas, l’Acheteur s'engage à ne pas exercer de
        poursuites à l'encontre du Vendeur et renonce à se prévaloir de la
        résolution de la vente prévue au présent article. Garanties Garantie des
        vices et défauts apparents Il appartient à l’Acheteur de vérifier le bon
        état des Produits au moment de la livraison. Cette vérification doit
        notamment porter sur la qualité, les quantités et les références des
        Produits ainsi que leur conformité à la commande. Aucune réclamation ne
        sera prise en compte après un délai de trois jours à compter de la
        livraison. En tout état de cause, toute réclamation concernant les colis
        livrés ne sera prise en compte que si l’Acheteur ayant la qualité de
        commerçant a émis des réserves auprès du transporteur conformément aux
        articles L. 133-3 et suivants du Code de commerce. Garantie des vices et
        défauts cachés Garanties légales Les Acheteurs disposent d'une garantie
        légale de délivrance conforme (article 1604 du Code civil), d’une
        garantie légale contre les vices cachés (articles 1641 et s. du Code
        civil) et d’une garantie de sécurité (articles 1245 et s. du Code
        civil). Les Acheteurs ayant la qualité de consommateurs disposent en
        outre d'une garantie légale de conformité (articles L. 217-4 et s. Code
        de la consommation). Retour Afin de mettre en œuvre la garantie, il
        appartient à l’acheteur de retourner le produit à l'adresse du siège du
        Vendeur, accompagné d'une lettre explicative en demandant soit la
        réparation, soit l’échange, soit le remboursement. Les frais de retour
        du Produit demeurent à la charge de l’Acheteur sauf pour les Clients
        consommateurs mettant en œuvre la garantie de conformité des articles L.
        217-4 et s. du Code de la consommation.
      </p>

      <p>
        L’Acheteur consommateur bénéficie d’un délai de 2 ans à compter de la
        délivrance du bien pour agir auprès du vendeur. A ce titre, il peut
        choisir entre la réparation ou le remplacement du Produit, sous réserve
        des conditions de coût prévues par l’article L.217-9 du Code de la
        consommation. Enfin, l’Acheteur est dispensé de rapporter la preuve de
        l’existence du défaut de conformité du Produit durant les 24 mois
        suivant la délivrance dudit Produit, sauf pour les biens d’occasion. Le
        cas échéant, la garantie légale de conformité s’applique indépendamment
        de la garantie commerciale. Lorsque l’Acheteur consommateur décide de
        mettre en œuvre la garantie des vices cachés, il peut choisir entre la
        résolution de la vente ou une réduction du prix de vente.{" "}
      </p>

      <p>
        3.5 Réclamations et traitement des litiges Les présentes CGV sont
        soumises à l'application du droit français. À n’importe quel moment,
        l’Acheteur peut contacter le Vendeur pour toute demande, réclamation
        concernant l’œuvre commandée via la messagerie à disposition sur le
        site. Le Vendeur s’engage à respecter la garantie légale de conformité
        vis-à-vis des Acheteurs consommateurs (article L. 211-1 et s. Code de la
        consommation). En cas de réclamation d’un Acheteur, le litige sera réglé
        directement entre l’Acheteur et le Vendeur à l’aide de l’outil
        messagerie proposé par le Site. Le Vendeur devra résoudre à l’amiable
        tous les litiges l’opposant à l’Acheteur. Néanmoins si le litige n’était
        pas résolu dans un délai de 7 jours ouvrés à compter de la réception de
        l’information, RunArtGallery après en avoir informé le Vendeur pourra
        procéder au remboursement du produit à l’Acheteur. Ce montant étant
        alors déduit de la somme reversée au Vendeur par RunArtGallery. Les
        présentes CGV peuvent être modifiées à tout moment par RunArtGallery.
        Les conditions générales applicables aux Acheteur et aux Vendeurs sont
        celles en vigueur au jour de sa commande ou de sa connexion sur le
        présent Site, toute nouvelle connexion à l'espace personnel emportant
        acceptation le cas échéant des nouvelles conditions générales. Pour
        toute réclamation merci de contacter le service client à l’adresse
        suivante : contact@runartgallery.com En vertu de l’ordonnance
        n°2015-1033 du 20 août 2015, tous litiges qui pourraient survenir dans
        le cadre de l'exécution des présentes conditions générales et dont la
        solution n’aura pu être trouvée préalablement à l’amiable entre les
        parties devront être soumis à Medicys : www.medicys.fr Tout litige
        relatif au présent contrat ou en relation avec celui-ci sera tranché par
        voie d’arbitrage conformément au règlement de l’Institut digital
        d’arbitrage et de médiation : www.fast-arbitre.com La Commission
        européenne met à disposition des consommateurs de l’Union européenne une
        plateforme de résolution des litiges en ligne. Les consommateurs peuvent
        faire une réclamation :
        https://ec.europa.eu/consumers/odr/main/index.cfm{" "}
      </p>

      <p>3.6 Évaluation des vendeurs</p>
      <p>
        Notation du Vendeur Les Acheteurs se voient offrir la faculté de
        contribuer au contenu du Site par la publication de commentaires sur
        leur utilisation des Produits et leur relation avec le Vendeur afin
        d’évaluer la transaction. Les commentaires seront soumis à validation de
        RunArtGallery.com ou de son équipe de modérateurs. En cliquant sur
        l'onglet « Valider », afin de publier son commentaire, l’Acheteur et le
        Vendeur accordent une licence de droit d'auteur non exclusive à titre
        gratuit à RunArtGallery portant sur ledit commentaire. À ce titre,
        l’Acheteur et le Vendeur autorisent RunArtGallery à communiquer au
        public en ligne, en tout ou partie, son commentaire sur le Site, les
        newsletters de RunArtGallery et les sites des partenaires de
        RunArtGallery. Le Vendeur et l’Acheteur autorisent RunArtGallery à
        reproduire son commentaire aux fins de le communiquer au public en ligne
        et de réaliser des supports de communication et de promotion du Site. Le
        Vendeur et l’Acheteur autorisent RunArtGallery à traduire dans toute
        langue son commentaire aux fins de le communiquer au public en ligne et
        de le reproduire sur ses supports commerciaux et promotionnels. Le droit
        d'adaptation comprend également le droit d'effectuer les modifications
        techniquement nécessaires à l'exploitation du commentaire dans d'autres
        formats. La présente licence est accordée pour une utilisation
        permanente.
      </p>

      <p>3.7 Données personnelles</p>
      <p>
        En complément de l’article 1.4 des CGU Acheteur et 2.8 des CGU Vendeurs,
        l’Acheteur et le Vendeur sont informés que les données personnelles
        collectées par le Site lors de la passation des commandes sont traitées
        par RunArtGallery uniquement au fin de ces commandes. Les informations
        des données des Acheteurs concernant la livraison sont transmises aux
        Vendeurs uniquement aux seules fins d’expédier la commande. Ces données
        ne peuvent être utilisées à d’autres fins. En cas de problème concernant
        le traitement des données, l’Acheteur et le Vendeur peuvent contacter
        directement RunArtGallery comme précisé dans les CGU Vendeur et
        Acheteur. Le Vendeur a uniquement accès aux données personnelles
        fournies par l’Acheteur de ses œuvres sur le Site lors de la commande.
        Le Vendeur s’engage à sécuriser ces données qu’il conserve pour les
        besoins exclusifs du suivi de sa commande. RunArtGallery a adopté un
        système de sécurisation de cryptage SSL, l’intégralité du site étant
        protégé par le protocole HTTPS. Le site a également renforcé la sécurité
        par des moyens de brouillage et de cryptage afin de protéger au maximum
        les données sensibles liées aux moyens de paiement utilisés sur
        RunArtGallery.
      </p>
      <p>3.8 Stipulations finales</p>
      <p>
        Les présentes CGV sont soumises à l'application du droit français. La
        nullité d'une des clauses du présent contrat n'entraînera pas la nullité
        des autres clauses du contrat ou du contrat dans sa globalité, qui
        garderont leur plein effet et portée. Dans une telle hypothèse, les
        parties devront dans la mesure du possible remplacer la stipulation
        annulée par une stipulation valable correspondant à l'esprit et à
        l'objet des présentes. L'absence d'exercice par RunArtGallery des droits
        qui lui sont reconnus par les présentes ne pourra en aucun cas être
        interprétée comme une renonciation à faire valoir lesdits droits. Les
        stipulations des présentes conditions générales s'appliquent sous
        réserve du respect des dispositions impératives du Code de la
        consommation concernant les clauses abusives dans les contrats conclus
        entre un professionnel et un consommateur. Tout différend sera tranché
        par les Tribunaux d’Aix en Provence nonobstant pluralité de défendeurs
        ou appels en garantie, y compris pour les procédures en référé ou sur
        requêtes.
      </p>
      <p>ANNEXE 1</p>
      <p>
        Article L. 217-4 Code de la consommation Le Vendeur est tenu de livrer
        un bien conforme au contrat et répond des défauts de conformité existant
        lors de la délivrance. Il répond également des défauts de conformité
        résultant de l'emballage, des instructions de montage ou de
        l'installation lorsque celle-ci a été mise à sa charge par le contrat ou
        a été réalisée sous sa responsabilité. Article L. 217-5 Code de la
        consommation Le bien est conforme au contrat : S'il est propre à l'usage
        habituellement attendu d'un bien semblable et, le cas échéant : s'il
        correspond à la description donnée par le Vendeur et possède les
        qualités que celui-ci a présentées à l'acheteur sous forme d'échantillon
        ou de modèle ; s'il présente les qualités qu'un acheteur peut
        légitimement attendre eu égard aux déclarations publiques faites par le
        Vendeur, par le producteur ou par son représentant, notamment dans la
        publicité ou l'étiquetage ; Ou s'il présente les caractéristiques
        définies d'un commun accord par les parties ou est propre à tout usage
        spécial recherché par l'acheteur, porté à la connaissance du Vendeur et
        que ce dernier a accepté. Article L. 217-12 Code de la consommation
        L'action résultant du défaut de conformité se prescrit par deux ans à
        compter de la délivrance du bien. Article L. 217-16 Code de la
        Consommation : Lorsque l'acheteur demande au Vendeur, pendant le cours
        de la garantie commerciale qui lui a été consentie lors de l'acquisition
        ou de la réparation d'un bien meuble, une remise en état couverte par la
        garantie, toute période d'immobilisation d'au moins sept jours vient
        s'ajouter à la durée de la garantie qui restait à courir. Cette période
        court à compter de la demande d'intervention de l'acheteur ou de la mise
        à disposition pour réparation du bien en cause, si cette mise à
        disposition est postérieure à la demande d'intervention. Article 1641
        Code civil Le Vendeur est tenu de la garantie à raison des défauts
        cachés de la chose vendue qui la rendent impropre à l'usage auquel on la
        destine, ou qui diminuent tellement cet usage, que l'acheteur ne
        l'aurait pas acquise, ou n'en aurait donné qu'un moindre prix, s'il les
        avait connus. Article 1648 alinéa 1er Code civil L'action résultant des
        vices rédhibitoires doit être intentée par l'acquéreur dans un délai de
        deux ans à compter de la découverte du vice.
      </p>
    </div>
  );
}

export default CguvPro;
