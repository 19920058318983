import axios from "axios";

export async function createArtwork(artwork) {
  let access_token = localStorage.getItem("tokenArtist");
  if (!access_token) {
    access_token = localStorage.getItem("tokenArtPro");
  }

  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
  };

  return await axios.post(
    process.env.REACT_APP_API_BASE_URL + "artwork",
    artwork,
    config
  );
}

export async function createArtworkByAdmin(artwork) {
  let access_token = localStorage.getItem("tokenAdmin");

  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
  };

  console.log(artwork);

  return await axios.post(
    process.env.REACT_APP_API_BASE_URL + "artwork/admin/create",
    artwork,
    config
  );
}

export async function updateArtwork(artworkId, artwork) {
  let access_token = localStorage.getItem("tokenArtist");
  if (!access_token) {
    access_token = localStorage.getItem("tokenArtPro");
  }
  if (!access_token) {
    access_token = localStorage.getItem("tokenAdmin");
  }

  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
  };

  return await axios.patch(
    process.env.REACT_APP_API_BASE_URL + "artwork",
    { artworkId, newArtwork: artwork },
    config
  );
}

export async function getArtworkById(artworkId) {
  return await axios.get(
    process.env.REACT_APP_API_BASE_URL + "artwork/" + artworkId
  );
}

export async function getArtworks(criteria) {
  return await axios.post(
    process.env.REACT_APP_API_BASE_URL + "artwork/search",
    criteria
  );
}

export async function deleteArtwork(artworkId) {
  let access_token = localStorage.getItem("tokenArtist");
  if (!access_token) {
    access_token = localStorage.getItem("tokenArtPro");
  }
  if (!access_token) {
    access_token = localStorage.getItem("tokenAdmin");
  }

  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
  };

  return await axios.delete(
    process.env.REACT_APP_API_BASE_URL + "artwork/" + artworkId,
    config
  );
}
