import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useStore } from "react-redux";
import { listOrders } from "../../services/orderService";
import { getArtworks } from "../../services/artworkService";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
  content: {
    height: "100%",
  },
  informations: {
    color: "white",
    backgroundColor: "forestGreen",
    padding: 10,
    "& a": {
      color: "white",
      textDecoration: "none",
    },
  },
  liftUp: {
    transition:
      "box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease",
    "&:hover": {
      boxShadow:
        "0 1.5rem 2.5rem rgba(22,28,45,.1),0 .3rem 0.5rem -.50rem rgba(22,28,45,.05) !important",
      transform: "translate3d(0,-5px,0)",
    },
  },
  cardContent: {
    textAlign: "center",
  },
  kpis: {
    marginTop: 30,
  },
}));

const Home = (props) => {
  const { className } = props;
  const store = useStore();
  const classes = useStyles();
  const [nbCurrentOrders, setNbCurrentOrders] = useState(0);
  const [nbOnlineProducts, setNbOnlineProducts] = useState(0);
  const currentUser = store.getState().entities?.user?.currentUser;

  const stepToValidate = () => {
    if (
      !store.getState().entities?.user?.currentUser?.status !== "active" &&
      !store.getState().entities?.user?.currentUser?.status !== 20
    ) {
      return (
        <React.Fragment>
          <p>
            Votre compte n'est pas encore actif, vous serez averti par mail des
            démarches à effectuer
          </p>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <p>Votre compte est actif et vos oeuvres disponibles à la vente</p>
        </React.Fragment>
      );
    }
  };

  useEffect(() => {
    const getCurrentOrders = async () => {
      const orders = await listOrders([
        "waiting_for_payment",
        "payment_done",
        "preparing",
        "delivery_in_progress",
        "delivered",
      ]);
      console.log(orders);
      setNbCurrentOrders(orders?.data[0]?.metadata[0]?.total || 0);
    };

    const getOnlineProduct = async () => {
      let criteria = { status: 10 };
      if (!currentUser.roles.includes("ROLE_ADMIN")) {
        criteria = { ...criteria, "seller._id": currentUser._id };
      }
      const products = await getArtworks(criteria);
      setNbOnlineProducts(products?.data?.metadata[0]?.total || 0);
    };

    getCurrentOrders();
    getOnlineProduct();
  }, [currentUser]);

  const displayInformation = () => {
    if (
      !store.getState().entities?.user?.currentUser?.status ||
      store.getState().entities?.user?.currentUser?.status === 10
    ) {
      return (
        <Paper className={classes.informations}>
          <p>
            Votre compte est en <b>cours de validation</b>
          </p>
          {stepToValidate()}
        </Paper>
      );
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      <h1>Dashboard</h1>

      {displayInformation()}

      <Grid container spacing={2} className={classes.kpis}>
        <Grid item sm={6} xs={12}>
          <Card className={clsx(classes.liftUp, classes.cardContent)}>
            <CardContent>
              <Typography variant={"h2"}>{nbCurrentOrders}</Typography>
              <Typography variant={"h6"}>commandes en cours</Typography>
            </CardContent>
            <CardActions>
              <Button
                size={"small"}
                component={Link}
                to="/orders"
                variant={"outlined"}
              >
                Voir les commandes
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Card className={clsx(classes.liftUp, classes.cardContent)}>
            <CardContent>
              <Typography variant={"h2"}>{nbOnlineProducts}</Typography>
              <Typography variant={"h6"}>oeuvre(s) en ligne</Typography>
            </CardContent>
            <CardActions>
              <Button
                size={"small"}
                variant={"outlined"}
                component={Link}
                to={"/products"}
              >
                Voir les oeuvres
              </Button>
              <Button
                size={"small"}
                variant={"contained"}
                color={"primary"}
                component={Link}
                to={"/products/new"}
              >
                Ajouter des oeuvres
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

Home.propTypes = {
  className: PropTypes.string,
};

export default Home;
