import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "user",
  initialState: {
    currentUser: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    userRequested: (user, action) => {
      user.loading = true;
    },

    userReceived: (user, action) => {
      user.currentUser = action.payload;
      user.loading = false;
      user.lastFetch = Date.now();
    },

    userRequestFailed: (user, action) => {
      user.loading = false;
    },
    removeCurrentUser: (user) => {
      user.currentUser = null;
    },
  },
});

export const {
  userReceived,
  userRequested,
  userRequestFailed,
  removeCurrentUser,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "/profile";

export const loadCurrentUser = (force = false) => (dispatch, getState) => {
  /*const { lastFetch } = getState().entities.user;

  const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  if (diffInMinutes < 10 && force === false) return;*/

  return dispatch(
    apiCallBegan({
      url,
      onStart: userRequested.type,
      onSuccess: userReceived.type,
      onError: userRequestFailed.type,
    })
  );
};

export const removeUser = () => (dispatch) => {
  return dispatch(removeCurrentUser);
};
