const matrix = {
  ROLE_ARTIST: [
    { min: 0, max: 1000, fees: 20 },
    { min: 1001, max: 3000, fees: 18 },
    { min: 3001, max: 6000, fees: 16 },
    { min: 6001, max: 10000, fees: 14 },
    { min: 10001, max: 25000, fees: 12 },
    { min: 25001, max: 100000, fees: 10 },
    { min: 100001, max: 500000, fees: 7 },
    { min: 500001, max: 1000000000, fees: 5 },
  ],
  ROLE_ART_PRO: [
    { min: 0, max: 10000, fees: 12 },
    { min: 10001, max: 25000, fees: 10 },
    { min: 25001, max: 100000, fees: 8 },
    { min: 100000, max: 500000, fees: 6 },
    { min: 500001, max: 1000000000, fees: 5 },
  ],
  ROLE_CLIENT: [
    { min: 0, max: 25000, fees: 20 },
    { min: 25001, max: 1000000000, fees: 10 },
  ],
};

export function calculateArtistFees(
  price,
  transportTax,
  currentUser,
  seller,
  artwork
) {
  const { roles } = currentUser;
  let role = roles[0];

  if (roles.includes("ROLE_ADMIN")) {
    if (seller) {
      seller.typeOfUser === "artist" && (role = "ROLE_ARTIST");
      seller.typeOfUser === "artPro" && (role = "ROLE_ART_PRO");
      seller.typeOfUser === "client" && (role = "ROLE_CLIENT");
    } else if (artwork) {
      artwork.seller.typeOfUser === "artist" && (role = "ROLE_ARTIST");
      artwork.seller.typeOfUser === "artPro" && (role = "ROLE_ART_PRO");
      artwork.seller.typeOfUser === "client" && (role = "ROLE_CLIENT");
    }
  }

  return calculateFees(price, transportTax, role);
}

export function calculateFees(price, transportTax, role) {
  const totalPrice = parseFloat(price) + parseFloat(transportTax);

  return matrix[role]
    .filter((rule) => {
      return totalPrice >= rule.min && totalPrice <= rule.max;
    })
    .map((rule) => {
      return (
        Math.round(
          (totalPrice * (rule.fees / 100) * 1.2 + Number.EPSILON) * 100
        ) / 100
      );
    });
}
