import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    display: "flex",
    flex: "1",
    height: 220,
    overflow: "hidden",
    margin: "15px 0",
  },
  details: {
    display: "flex",
    flex: "1 0 auto",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: "30%",
  },
  controls: {
    display: "flex",
    flex: "1",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  icon: {
    height: 30,
    width: 30,
  },
}));

const CardUser = (props) => {
  const classes = useStyles();
  const { lead } = props;

  const status = {
    new: {
      color: "default",
      text: "Nouveau",
    },
    contacted: {
      color: "secondary",
      text: "Contacté",
    },
    won: {
      color: "primary",
      text: "Gagné",
    },
    lost: {
      color: "danger",
      text: "Perdu",
    },
  };

  return (
    <Card className={classes.cardRoot}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {lead.firstName} {lead.lastName}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {lead.email} - {lead.phone}
          </Typography>
          <Button variant={"contained"} color={status[lead.status].color}>
            {status[lead.status].text}
          </Button>
        </CardContent>
      </div>
      <div className={classes.controls}>
        <IconButton
          aria-label="edit"
          component={Link}
          to={"/leads/" + lead._id}
        >
          <EditIcon className={classes.icon} />
          Modifier
        </IconButton>
      </div>
    </Card>
  );
};

CardUser.propTypes = {
  className: PropTypes.string,
};

export default CardUser;
