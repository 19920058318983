import axios from "axios";

export async function listOrders(status) {
  let access_token = localStorage.getItem("tokenArtist");
  if (!access_token) {
    access_token = localStorage.getItem("tokenArtPro");
  }
  if (!access_token) {
    access_token = localStorage.getItem("tokenAdmin");
  }

  if (access_token) {
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    if (!status || status === "all") {
      return await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}order/all`,
        config
      );
    } else {
      let statusFilter = `status=${status}`;
      if (typeof status === "object") {
        statusFilter = status.map((item) => `status[]=${item}`).join("&");
      }

      return await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}order/all?${statusFilter}`,
        config
      );
    }
  }
}

export async function getOrderDetail(orderId) {
  const access_token_admin = localStorage.getItem("tokenAdmin");

  if (access_token_admin) {
    const config = {
      headers: { Authorization: `Bearer ${access_token_admin}` },
    };

    return await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}order/${orderId}`,
      config
    );
  }
}

export async function patchOrderProduct(orderId, product) {
  const access_token_admin = localStorage.getItem("tokenAdmin");

  if (access_token_admin) {
    const config = {
      headers: { Authorization: `Bearer ${access_token_admin}` },
    };

    return await axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}order/${orderId}/product`,
      product,
      config
    );
  }
}

export async function updateOrder(orderId, newStatus) {
  const access_token_admin = localStorage.getItem("tokenAdmin");

  if (access_token_admin) {
    const config = {
      headers: { Authorization: `Bearer ${access_token_admin}` },
    };

    return await axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}order/${orderId}`,
      { status: newStatus },
      config
    );
  }
}
