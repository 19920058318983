import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  CardContent,
  CardMedia,
  Typography,
  Card,
  CardActionArea,
  CardActions,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useStore } from "react-redux";

import { slugify } from "../../../services/stringService";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "15px 0",
  },
  description: {
    maxHeight: 100,
    overflow: "hidden",
    margin: "15px 0",
  },
  media: {
    minHeight: 200,
  },
  status: {
    marginTop: 15,
    fontWeight: 600,
    display: "block",
  },
  colorprimary: {
    backgroundColor: theme.palette.primary.main,
    width: 100,
    textAlign: "center",
    padding: 15,
    borderRadius: 8,
    color: "white",
  },
  colordefault: {
    backgroundColor: theme.palette.grey.A100,
    width: 150,
    textAlign: "center",
    padding: 15,
    borderRadius: 8,
  },
  colorsecondary: {
    backgroundColor: theme.palette.secondary.main,
    width: 100,
    textAlign: "center",
    padding: 15,
    borderRadius: 8,
    color: "white",
  },
  seller: {
    marginTop: 10,
  },
}));

const CardProductMobile = ({ artwork, onDelete }) => {
  const classes = useStyles();
  const store = useStore();
  const currentUser = store.getState().entities?.user?.currentUser;

  const status = {
    0: {
      color: "default",
      text: "En attente validation",
    },
    5: {
      color: "secondary",
      text: "Refusé",
    },
    10: {
      color: "primary",
      text: "En ligne",
    },
    20: {
      color: "default",
      text: "Acheté",
    },
    paginate: {
      alignItems: "end",
    },
    status: {
      marginTop: 15,
    },
  };

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={
            artwork.images[0]
              ? artwork.images[0]
              : "https://www.club-games.com/wp-content/themes/cwp-youit/images/no-image-default.png"
          }
          title={artwork.title}
        />
        <CardContent>
          <Typography component="h5" variant="h5">
            {artwork.title} - {artwork.price}€
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {artwork.typeOfArtwork}
          </Typography>
          <span
            className={clsx(
              classes.status,
              classes["color" + status[artwork.status].color]
            )}
          >
            {status[artwork.status].text}
          </span>

          {currentUser.roles.includes("ROLE_ADMIN") && (
            <Typography className={classes.seller} component="h6" variant="h6">
              Vendeur {artwork.seller.firstName} {artwork.seller.lastName}{" "}
              {artwork.seller.businessName}
            </Typography>
          )}

          <div
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: artwork.description }}
          />
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          component={Link}
          to={"/products/" + artwork._id}
        >
          Modifier
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={() => onDelete(artwork._id)}
        >
          Supprimer
        </Button>
        {artwork.status === 10 && (
          <Button
            size="small"
            color="primary"
            onClick={() => {
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_MARKETPLACE_URL}oeuvre-d-art/${slugify(
                  artwork.title
                )}-${artwork._id}`
              );
              toast.success("Le lien de l'oeuvre a été copié !");
            }}
          >
            Copier le lien de l'oeuvre
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

CardProductMobile.propTypes = {
  classname: PropTypes.string,
};

export default CardProductMobile;
