import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import CardOrder from "./components/CardOrder";
import { listOrders } from "../../services/orderService";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  fab: {
    position: "sticky",
    bottom: theme.spacing(2),
    right: 0,
  },
}));

const OrdersList = (props) => {
  const { className } = props;
  const classes = useStyles();
  const [filterStatus, setFilterStatus] = useState("all");
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);

  const handleFilterChange = ({ target }) => {
    setFilterStatus(target.value);
  };

  const handlePageChange = (element, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    const getOrders = async () => {
      const orders = await listOrders(filterStatus);
      setOrders(orders.data[0]);
    };

    getOrders();
  }, [filterStatus, page]);

  return (
    <div className={clsx(classes.root, className)}>
      <h1 className={classes.title}>Vos oeuvres</h1>
      <h4>Filtres</h4>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="filter-status">Statut</InputLabel>
        <Select
          labelId="filter-status"
          id="filter-status"
          value={filterStatus}
          onChange={handleFilterChange}
          label="Statut"
        >
          <MenuItem value="all">
            <em>Tous</em>
          </MenuItem>
          <MenuItem value={"waiting_for_payment"}>En attente paiement</MenuItem>
          <MenuItem value={"payment_done"}>Paiement validé</MenuItem>
          <MenuItem value={"preparing"}>En cours de préparation</MenuItem>
          <MenuItem value={"delivery_in_progress"}>Expédiée</MenuItem>
          <MenuItem value={"delivered"}>Livrée</MenuItem>
          <MenuItem value={"closed"}>Terminée</MenuItem>
          <MenuItem value={"canceled"}>Annulée</MenuItem>
          <MenuItem value={"refund"}>Remboursée</MenuItem>
        </Select>
      </FormControl>
      <Divider className={classes.divider} />

      {orders?.data?.map((order) => (
        <CardOrder key={order._id} {...order} />
      ))}

      <Divider className={classes.divider} />
      <Pagination
        onChange={handlePageChange}
        count={orders.metadata && Math.ceil(orders.metadata[0]?.total / 10)}
      />
    </div>
  );
};

OrdersList.propTypes = {
  className: PropTypes.string,
};

export default OrdersList;
