import React, { createRef } from "react";
import Joi from "joi-browser";
import Form from "../../../../common/Form";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import axios from "axios";

const styles = (theme) => ({
  root: {
    "& .errorMsg": {
      color: "red",
    },
    "& .ck-editor__editable_inline": {
      background: theme.palette.background.level2,
      margin: "45px 0 15px 0",
      borderRadius: "8px !important",
    },
    "& .MuiButton-root, .MuiTextField-root": {
      width: "100%",
      margin: "15px 0",
    },
  },
  block: {
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
  },
  avatar: {
    width: 120,
    height: 120,
    margin: "0 auto",
    "& :hover": {
      cursor: "pointer",
    },
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  legalLink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "& :hover": {
      cursor: "pointer",
    },
  },
  errorMsg: {
    marginTop: 10,
    color: theme.palette.error.main,
    textAlign: "center",
  },
});

class BasicInfosForm extends Form {
  constructor(props) {
    super();
    this.uploadRef = createRef();

    this.state = {
      data: {
        businessName: "",
        siret: "",
        email: "",
        phone: "",
        plainPassword: "",
        avatarUrl: "",
      },
      errors: {},
      imgLoading: false,
    };
  }

  schema = {
    businessName: Joi.string().required().label("Nom de l'entreprise"),
    siret: Joi.string().required().label("SIRET"),
    email: Joi.string().email().required().label("Email"),
    phone: Joi.any(),
    avatarUrl: Joi.string().required().label("Image de profil"),
    plainPassword: Joi.string().required().label("Mot de passe"),
  };

  doSubmit = async () => {
    const { data } = this.state;
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  triggerUpload = () => {
    this.uploadRef.current.click();
  };

  uploadFile = async (e) => {
    this.setState({ imgLoading: true });
    const imgToken = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "image-kit/token"
    );
    const formData = new FormData();

    formData.append("file", e.target.files[0]);
    formData.append("publicKey", "public_D/8HDlj32JhWbgZuZnYKYAOUPbk=");
    formData.append("fileName", e.target.files[0].name);
    formData.append("signature", imgToken.data.signature);
    formData.append("expire", imgToken.data.expire);
    formData.append("token", imgToken.data.token);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const response = await axios.post(
      "https://upload.imagekit.io/api/v1/files/upload",
      formData,
      config
    );

    const { data } = this.state;
    data.avatarUrl = response.data.url;
    this.setState({ data, imgLoading: false });
  };

  render() {
    const { classes } = this.props;

    let avatar = (
      <Avatar
        className={classes.avatar}
        alt="Image de profil"
        onClick={this.triggerUpload}
        src={
          this.state.data.avatarUrl
            ? this.state.data.avatarUrl
            : "https://www.vox.cg/wp-content/uploads/2014/10/default-img.gif"
        }
      />
    );

    if (this.state.imgLoading) {
      avatar = (
        <Skeleton
          variant="circle"
          width={120}
          height={120}
          className={classes.avatar}
        />
      );
    }

    return (
      <form
        onSubmit={this.handleSubmit}
        autoComplete="off"
        className={classes.root}
      >
        <Paper className={classes.block}>
          <h3>A propos de vous</h3>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {avatar}
              <input
                type={"file"}
                onChange={this.uploadFile}
                ref={this.uploadRef}
                style={{ display: "none" }}
              />
              {!this.state.data["avatarUrl"] && (
                <p className={classes.errorMsg}>La photo est obligatoire</p>
              )}
              <p className={classes.errorMsg}>
                {this.state.errors["avatarUrl"]}
              </p>
            </Grid>
            <Grid item md={6} xs={12}>
              {this.renderInput(
                "businessName",
                "Nom de l'entreprise *",
                "text",
                null,
                true
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {this.renderInput("siret", "SIRET *", "text", null, true)}
            </Grid>

            <Grid item md={4} xs={12}>
              {this.renderInput("email", "Email *", "text", null, true)}
            </Grid>
            <Grid item md={4} xs={12}>
              {this.renderInput(
                "plainPassword",
                "Mot de passe *",
                "password",
                null,
                true
              )}
            </Grid>
            <Grid item md={4} xs={12}>
              {this.renderInput("phone", "Tel", "tel")}
            </Grid>
          </Grid>
          <div>{this.renderButton("Suivant", "primary")}</div>
        </Paper>
      </form>
    );
  }
}

BasicInfosForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BasicInfosForm);
