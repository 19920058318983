import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useStore } from "react-redux";
import { toast } from "react-toastify";

const RouteWithLayout = (props) => {
  const store = useStore();

  const {
    layout: Layout,
    component: Component,
    isProtected,
    isAdmin,
    ...rest
  } = props;

  const currentUser = store.getState().entities?.user?.currentUser;

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (isProtected && (!currentUser || !currentUser._id)) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        } else if (
          isProtected &&
          isAdmin &&
          (!currentUser ||
            !currentUser._id ||
            currentUser.roles[0] !== "ROLE_ADMIN")
        ) {
          toast.error("Vous n'avez pas accès à cette page !");
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          );
        } else {
          return (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          );
        }
      }}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
