import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { useStore } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { getLeads } from "../../services/leadService";
import CardUser from "./components/CardUser";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  fab: {
    position: "sticky",
    bottom: theme.spacing(2),
    right: 0,
  },
}));

const LeadsList = (props) => {
  const { className } = props;
  const classes = useStyles();
  const [filterStatus, setFilterStatus] = useState("all");
  const [textFilter, setTextFilter] = useState("");
  const store = useStore();
  const [leads, setLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async (filters) => {
    let criteria = {
      page: filters.page,
    };

    if (filters.status !== null && filters.status !== "all") {
      criteria = { ...criteria, status: filters.status };
    }

    if (filters.term) {
      criteria = { ...criteria, term: filters.term };
    }

    const leads = await getLeads(criteria);
    setLeads(leads.data);
  };

  const handleTextSearchChange = ({ target }) => {
    setTextFilter(target.value);
    fetchData({
      status: filterStatus,
      page: currentPage,
      term: target.value,
    });
  };

  const handleFilterChange = ({ target }) => {
    setFilterStatus(target.value);
    fetchData({ status: target.value, page: currentPage, term: textFilter });
  };

  const handlePageChange = (element, newPage) => {
    setCurrentPage(newPage);
    fetchData({ status: filterStatus, page: newPage, term: textFilter });
  };

  useEffect(() => {
    getLeads({}).then((leads) => {
      setLeads(leads.data);
    });
  }, [store]);

  return (
    <div className={clsx(classes.root, className)}>
      <h1 className={classes.title}>Liste des Prospects</h1>
      <h4>Filtres</h4>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="filter-status">Statut</InputLabel>
        <Select
          labelId="filter-status"
          id="filter-status"
          value={filterStatus}
          onChange={handleFilterChange}
          label="Statut"
        >
          <MenuItem value="all">
            <em>Tous</em>
          </MenuItem>
          <MenuItem value={"new"}>Nouveau</MenuItem>
          <MenuItem value={"contacted"}>Contactés</MenuItem>
          <MenuItem value={"lost"}>Perdus</MenuItem>
          <MenuItem value={"won"}>Gagnés</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label={"Rechercher"}
        onChange={handleTextSearchChange}
        type={"text"}
        variant="outlined"
        className={classes.formControl}
      />

      <Divider className={classes.divider} />

      {leads.data &&
        leads.data.map((lead) => (
          <React.Fragment key={lead._id}>
            <CardUser lead={lead} />
          </React.Fragment>
        ))}

      <Divider className={classes.divider} />
      <Pagination
        onChange={handlePageChange}
        count={leads.metadata && Math.ceil(leads.metadata[0]?.total / 10)}
      />
    </div>
  );
};

LeadsList.propTypes = {
  className: PropTypes.string,
};

export default LeadsList;
