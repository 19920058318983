import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import { useStore } from "react-redux";
import { toast } from "react-toastify";
import { getClientById } from "../../services/clientService";
import ClientsForm from "./components/ClientsForm";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
}));

const Clients = (props) => {
  const { className } = props;
  const clientId = props.match.params.id;
  const classes = useStyles();
  const history = useHistory();
  const [title, setTitle] = useState("Nouveau client");

  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState(null);

  const store = useStore();
  const { currentUser } = store.getState().entities.user;

  useEffect(() => {
    async function fetchData() {
      if (clientId !== "new") {
        const clientResponse = await getClientById(clientId);
        setTitle(clientResponse.data.firstName + clientResponse.data.lastName);
        setEdit(true);
        setClient(clientResponse.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    fetchData();
  }, [clientId]);

  const handleSubmit = () => {
    toast.success("Profil mis à jour avec succès");
    history.push("/clients");
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Button
        color="primary"
        className={classes.button}
        startIcon={<ArrowBackIcon />}
        component={Link}
        to={"/clients"}
      >
        Retour liste
      </Button>
      <h1 className={classes.title}>{title}</h1>
      {!loading && (
        <ClientsForm
          onSubmit={handleSubmit}
          edit={edit}
          client={client}
          clientId={clientId}
          admin={true}
          currentUser={currentUser}
        />
      )}
    </div>
  );
};

Clients.propTypes = {
  className: PropTypes.string,
};

export default Clients;
