import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import { useStore } from "react-redux";
import { toast } from "react-toastify";
import { getArtProById } from "../../services/artProService";
import ArtProForm from "../Profile/components/ArtProForm";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
}));

const ArtPros = (props) => {
  const { className } = props;
  const artProId = props.match.params.id;
  const classes = useStyles();
  const history = useHistory();
  const [title, setTitle] = useState("Nouveau professionnel de l'art");

  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [artPro, setArtPro] = useState(null);

  const store = useStore();
  const { currentUser } = store.getState().entities.user;

  useEffect(() => {
    async function fetchData() {
      if (artProId !== "new") {
        const artProResponse = await getArtProById(artProId);
        setTitle(artProResponse.data.businessName);
        setEdit(true);
        setArtPro(artProResponse.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    fetchData();
  }, [artProId]);

  const handleSubmit = () => {
    toast.success("Profil mis à jour avec succès");
    history.push("/artPros");
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Button
        color="primary"
        className={classes.button}
        startIcon={<ArrowBackIcon />}
        component={Link}
        to={"/artPros"}
      >
        Retour liste
      </Button>
      <h1 className={classes.title}>{title}</h1>
      {!loading && (
        <ArtProForm
          onSubmit={handleSubmit}
          edit={edit}
          artPro={artPro}
          artProId={artProId}
          admin={true}
          currentUser={currentUser}
        />
      )}
    </div>
  );
};

ArtPros.propTypes = {
  className: PropTypes.string,
};

export default ArtPros;
