import React from "react";
import Joi from "joi-browser";
import Form from "../../../common/Form";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import { updateAdminProfile } from "../../../services/adminService";

const styles = (theme) => ({
  root: {
    "& .errorMsg": {
      color: "red",
    },
    "& .MuiButton-root, .MuiTextField-root": {
      width: "100%",
      margin: "15px 0",
    },
  },
  block: {
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
  },
  errorMsg: {
    marginTop: 10,
    color: theme.palette.error.main,
    textAlign: "center",
  },
});

class AdminForm extends Form {
  constructor(props) {
    super();

    const { currentUser } = props;
    if (currentUser) {
      this.state = {
        data: this.mapToViewModel(currentUser),
        errors: {},
        imgLoading: false,
      };
    }
  }

  schema = {
    firstName: Joi.string().required().label("Prénom"),
    lastName: Joi.string().required().label("Nom"),
    email: Joi.string().email().required().label("Email"),
    plainPassword: Joi.any(),
  };

  mapToViewModel(user) {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      plainPassword: "",
    };
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;

      await updateAdminProfile(data);

      const { onSubmit } = this.props;
      onSubmit();
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.error;
        this.setState({ errors });
      }
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <form
        onSubmit={this.handleSubmit}
        autoComplete="off"
        className={classes.root}
      >
        <Paper className={classes.block}>
          <h3>Compte admin</h3>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              {this.renderInput("firstName", "Prénom", "text", null, true)}
            </Grid>
            <Grid item md={6} xs={12}>
              {this.renderInput("lastName", "Nom", "text", null, true)}
            </Grid>

            <Grid item md={6} xs={12}>
              {this.renderInput("email", "Email *", "text", null, true)}
            </Grid>
            <Grid item md={6} xs={12}>
              {this.renderInput(
                "plainPassword",
                "Mot de passe (laissez vide pour ne rien changer)",
                "password"
              )}
            </Grid>
          </Grid>
        </Paper>

        <Paper className={classes.block}>
          <div>{this.renderButton("Valider", "primary")}</div>
        </Paper>
      </form>
    );
  }
}

AdminForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminForm);
