import React from "react";
import Joi from "joi-browser";
import Form from "../../../common/Form";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import { updateLeadByAdmin } from "../../../services/leadService";

const styles = (theme) => ({
  root: {
    "& .errorMsg": {
      color: "red",
    },
    "& .ck-editor__editable_inline": {
      background: theme.palette.background.level2,
      margin: "45px 0 15px 0",
      borderRadius: "8px !important",
    },
    "& .MuiButton-root, .MuiTextField-root": {
      width: "100%",
      margin: "15px 0",
    },
  },
  block: {
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
  },
  avatar: {
    width: 120,
    height: 120,
    margin: "0 auto",
    "& :hover": {
      cursor: "pointer",
    },
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  legalLink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "& :hover": {
      cursor: "pointer",
    },
  },
  errorMsg: {
    marginTop: 10,
    color: theme.palette.error.main,
    textAlign: "center",
  },
});

class LeadForm extends Form {
  constructor(props) {
    super();

    const { lead } = props;
    this.state = {
      data: this.mapToViewModel(lead, true),
      errors: {},
      imgLoading: false,
    };
  }

  schema = {
    firstName: Joi.string().required().label("Prénom"),
    lastName: Joi.string().required().label("Nom"),
    email: Joi.string().email().required().label("Email"),
    phone: Joi.any(),
    status: Joi.string().required(),
  };

  mapToViewModel(user, admin) {
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      status: user.status,
    };
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;
      const { leadId } = this.props;

      await updateLeadByAdmin(data, leadId);
      const { onSubmit } = this.props;
      onSubmit();
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.error;
        this.setState({ errors });
      }
    }
  };

  status = [
    { value: "new", text: "Nouveau" },
    { value: "contacted", text: "Contacté" },
    { value: "lost", text: "Perdu" },
    { value: "won", text: "Gagné" },
  ];

  render() {
    const { classes, lead } = this.props;

    return (
      <form
        onSubmit={this.handleSubmit}
        autoComplete="off"
        className={classes.root}
      >
        <Paper className={classes.block}>
          <h3>Information du prospect</h3>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              {this.renderInput("firstName", "Prénom *", "text", null, true)}
            </Grid>
            <Grid item md={6} xs={12}>
              {this.renderInput("lastName", "Nom *", "text", null, true)}
            </Grid>

            <Grid item md={6} xs={12}>
              {this.renderInput("email", "Email *", "text", null, true)}
            </Grid>
            <Grid item md={6} xs={12}>
              {this.renderInput("phone", "Tel", "text")}
            </Grid>
            <Grid item md={12} xs={12}>
              {this.renderSelect("status", "Statut", this.status)}
            </Grid>
          </Grid>
        </Paper>

        {lead && lead.message && (
          <Paper className={classes.block}>
            <h3>Message du prospect</h3>
            <p>{lead.message}</p>
          </Paper>
        )}

        <Paper className={classes.block}>
          <div>{this.renderButton("Valider", "primary")}</div>
        </Paper>
      </form>
    );
  }
}

LeadForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LeadForm);
