import axios from "axios";

export async function registerArtist(artist) {
  return await axios.post(
    process.env.REACT_APP_API_BASE_URL + "artist/register",
    artist
  );
}

export async function updateProfile(artist) {
  const access_token = localStorage.getItem("tokenArtist");

  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
  };

  return axios.patch(
    process.env.REACT_APP_API_BASE_URL + "artist/profile",
    artist,
    config
  );
}

export async function updateProfileByAdmin(artist, artistId) {
  const access_token = localStorage.getItem("tokenAdmin");

  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
  };

  return axios.patch(
    process.env.REACT_APP_API_BASE_URL + "artist/admin/profile",
    { artistId, newArtist: artist },
    config
  );
}

export async function getArtists(criteria) {
  const access_token_admin = localStorage.getItem("tokenAdmin");

  if (access_token_admin) {
    const config = {
      headers: { Authorization: `Bearer ${access_token_admin}` },
    };

    return await axios.post(
      process.env.REACT_APP_API_BASE_URL + "artist/search",
      criteria,
      config
    );
  }
}

export async function getArtistById(artistId) {
  const access_token_admin = localStorage.getItem("tokenAdmin");
  if (access_token_admin) {
    const config = {
      headers: { Authorization: `Bearer ${access_token_admin}` },
    };

    return axios.get(
      process.env.REACT_APP_API_BASE_URL + "artist/" + artistId,
      config
    );
  }

  return false;
}

//TODO : move this function to another service (specific)
export async function getCurrentProfile() {
  const access_token_artist = localStorage.getItem("tokenArtist");
  const access_token_art_pro = localStorage.getItem("tokenArtPro");
  const access_token_admin = localStorage.getItem("tokenAdmin");

  if (access_token_artist) {
    const config = {
      headers: { Authorization: `Bearer ${access_token_artist}` },
    };

    return axios.get(
      process.env.REACT_APP_API_BASE_URL + "artist/profile",
      config
    );
  } else if (access_token_art_pro) {
    const config = {
      headers: { Authorization: `Bearer ${access_token_art_pro}` },
    };

    return axios.get(
      process.env.REACT_APP_API_BASE_URL + "art-pro/profile",
      config
    );
  } else if (access_token_admin) {
    const config = {
      headers: { Authorization: `Bearer ${access_token_admin}` },
    };

    return axios.get(
      process.env.REACT_APP_API_BASE_URL + "admin/profile",
      config
    );
  }

  return false;
}
