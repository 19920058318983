import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  CardContent,
  CardMedia,
  Typography,
  Card,
  CardActionArea,
  CardActions,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "15px 0",
  },
  description: {
    maxHeight: 100,
    overflow: "hidden",
    margin: "15px 0",
  },
  media: {
    minHeight: 200,
  },
  status: {
    marginTop: 15,
    fontWeight: 600,
    display: "block",
  },
  colorprimary: {
    backgroundColor: theme.palette.primary.main,
    width: 100,
    textAlign: "center",
    padding: 15,
    borderRadius: 8,
    color: "white",
  },
  colordefault: {
    backgroundColor: theme.palette.grey.A100,
    width: 150,
    textAlign: "center",
    padding: 15,
    borderRadius: 8,
  },
  colorsecondary: {
    backgroundColor: theme.palette.secondary.main,
    width: 100,
    textAlign: "center",
    padding: 15,
    borderRadius: 8,
    color: "white",
  },
}));

const CardUserMobile = (props) => {
  const classes = useStyles();
  const { artPro } = props;

  const status = {
    0: {
      color: "default",
      text: "Nouveau",
    },
    new: {
      color: "default",
      text: "Nouveau",
    },
    waiting_validation: {
      color: "secondary",
      text: "En attente validation",
    },
    active: {
      color: "primary",
      text: "Actif",
    },
  };

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={
            artPro.avatarUrl
              ? artPro.avatarUrl
              : "https://www.club-games.com/wp-content/themes/cwp-youit/images/no-image-default.png"
          }
          title={artPro.businessName}
        />
        <CardContent>
          <Typography component="h5" variant="h5">
            {artPro.businessName}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {artPro.email} - {artPro.phone}
          </Typography>
          <span
            className={clsx(
              classes.status,
              classes["color" + status[artPro.status].color]
            )}
          >
            {status[artPro.status].text}
          </span>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          component={Link}
          to={"/artPro/" + artPro._id}
        >
          Modifier
        </Button>

        <Button
          size="small"
          color="primary"
          component={Link}
          to={{
            pathname: "/products/new",
            seller: { ...artPro, typeOfUser: "artPro" },
          }}
        >
          Créer une oeuvre
        </Button>
      </CardActions>
    </Card>
  );
};

CardUserMobile.propTypes = {
  classname: PropTypes.string,
};

export default CardUserMobile;
