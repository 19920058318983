import React from "react";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ExtensionIcon from "@material-ui/icons/Extension";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useStore } from "react-redux";
import { toast } from "react-toastify";
import { removeUser } from "../../../store/user";

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: theme.palette.drawer.width,
      flexShrink: 0,
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: theme.palette.drawer.width,
  },
}));

const Menu = (props) => {
  const { window, mobileOpen, onClick } = props;
  const classes = useStyles();
  const theme = useTheme();
  const store = useStore();
  const { currentUser } = store.getState().entities.user;
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem("tokenArtist");
    localStorage.removeItem("tokenArtPro");
    localStorage.removeItem("tokenAdmin");
    dispatch(removeUser());
    toast.success("Vous avez été déconnecté avec succès");
    history.push("/login");
  };

  const displayName = () => {
    if (currentUser.firstName && currentUser.lastName) {
      return currentUser.firstName + " " + currentUser.lastName;
    } else if (currentUser.businessName) {
      return currentUser.businessName;
    }
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <List>
          <ListItem button component={Link} to={"/profile"} key={"profile"}>
            <ListItemAvatar>
              <Avatar
                alt={currentUser.firstName + " " + currentUser.lastName}
                src={currentUser.avatarUrl ? currentUser.avatarUrl : ""}
              >
                <span>
                  {currentUser.firstName && currentUser.firstName.charAt(0)}{" "}
                  {currentUser.businessName &&
                    currentUser.businessName.charAt(0)}
                </span>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={displayName()} />
          </ListItem>
        </List>
      </div>
      <Divider />
      <List>
        <ListItem button component={Link} to={"/profile"} key={"profile"}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={"Mon profil"} />
        </ListItem>
        {currentUser.roles[0] === "ROLE_ADMIN" && (
          <ListItem button component={Link} to={"/artists"} key={"artists"}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={"Artistes"} />
          </ListItem>
        )}
        {currentUser.roles[0] === "ROLE_ADMIN" && (
          <ListItem button component={Link} to={"/artPros"} key={"artPro"}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={"Professionnels"} />
          </ListItem>
        )}
        {currentUser.roles[0] === "ROLE_ADMIN" && (
          <ListItem button component={Link} to={"/clients"} key={"clients"}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={"Clients / Particuliers"} />
          </ListItem>
        )}
        {currentUser.roles[0] === "ROLE_ADMIN" && (
          <ListItem button component={Link} to={"/leads"} key={"leads"}>
            <ListItemIcon>
              <GroupAddIcon />
            </ListItemIcon>
            <ListItemText primary={"Prospects"} />
          </ListItem>
        )}
        {currentUser.roles[0] === "ROLE_ADMIN" && (
          <ListItem button component={Link} to={"/orders"} key={"orders"}>
            <ListItemIcon>
              <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary={"Commandes"} />
          </ListItem>
        )}
        <ListItem button component={Link} to={"/products"} key={"products"}>
          <ListItemIcon>
            <ExtensionIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              (currentUser.roles[0] === "ROLE_ADMIN" && "Oeuvres") ||
              "Mes oeuvres"
            }
          />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={handleLogout} key={"logout"}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={"Déconnexion"} />
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={onClick}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default Menu;
