import React, { createRef } from "react";
import Joi from "joi-browser";
import Form from "../../../common/Form";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { Grid, Avatar, Button } from "@material-ui/core";
import {
  createArtwork,
  updateArtwork,
  createArtworkByAdmin,
} from "../../../services/artworkService";
import Skeleton from "@material-ui/lab/Skeleton";
import axios from "axios";
import { toast } from "react-toastify";
import { calculateArtistFees } from "../../../services/fees";
import { Redirect } from "react-router";
import SearchArtist from "./SearchArtist";

const styles = (theme) => ({
  root: {
    "& .errorMsg": {
      color: "red",
    },
    "& .ck-editor__editable_inline": {
      background: theme.palette.background.level2,
      margin: "45px 0 15px 0",
      borderRadius: "8px !important",
    },
    "& .MuiButton-root, .MuiTextField-root, .MuiFormControl-root": {
      width: "100%",
      margin: "15px 0",
    },
  },
  block: {
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
  },
  avatar: {
    width: "100%",
    height: "auto",
    "& :hover": {
      cursor: "pointer",
    },
  },
  image: {
    textAlign: "center",
  },
});

class ProductForm extends Form {
  constructor(props) {
    super(props);
    this.uploadRef = createRef();
    this.uploadAvatarRef = createRef();
    const { currentUser, artwork, seller, edit } = props;

    let artworkSeller = seller;
    if (edit) {
      artworkSeller = artwork.seller;
    }

    if (artwork) {
      this.state = {
        data: this.mapToViewModel(artwork, currentUser),
        errors: {},
        imgLoading: false,
        avatarLoading: false,
        showArtistForm: !artwork?.artist?._id,
      };
      if (artwork.technical) {
        this.technical = this.technicalBase.filter(
          (tec) => tec.id === artwork.technical || tec.type === ""
        );
      }
    } else {
      this.state = {
        data: {
          title: "",
          description: "",
          typeOfArtwork: "",
          technical: "",
          support: "",
          gender: "",
          price: 0,
          transportTax: 0,
          withCertificate: false,
          characteristics: [],
          images: [],
          mainColor: "",
          width: "",
          height: "",
          depth: "",
          theme: "",
          stateOfArtwork: "",
          status: "",
          yearOfCreation: "",
        },
        errors: {},
        imgLoading: false,
        avatarLoading: false,
        showArtistForm: false,
      };

      if (
        !currentUser.roles.includes("ROLE_ARTIST") &&
        artworkSeller?.typeOfUser !== "artist"
      ) {
        this.state = {
          ...this.state,
          data: {
            ...this.state.data,
            artistNickName: "",
            artistCategory: "",
            workCity: "",
            birthYear: "",
            artistDescription: "",
            artistAvatarUrl: "",
            artistEmail: "",
            artistId: "",
          },
        };
      }
    }

    if (
      !currentUser.roles.includes("ROLE_ARTIST") &&
      artworkSeller?.typeOfUser !== "artist"
    ) {
      this.schema = {
        ...this.schema,
        artistNickName: Joi.string().required(),
        artistCategory: Joi.any(),
        workCity: Joi.any(),
        birthYear: Joi.any(),
        artistDescription: Joi.any(),
        artistAvatarUrl: Joi.any(),
        artistEmail: Joi.any(),
        artistId: Joi.any(),
      };
    }
  }

  mapToViewModel(artwork, currentUser) {
    let model = {
      title: artwork.title,
      description: artwork.description,
      typeOfArtwork: artwork.typeOfArtwork,
      technical: artwork.technical,
      support: artwork.support,
      gender: artwork.gender,
      price: artwork.price,
      transportTax: artwork.transportTax,
      withCertificate: artwork.withCertificate,
      characteristics: artwork.characteristics,
      images: artwork.images,
      mainColor: artwork.mainColor,
      width: artwork.width,
      height: artwork.height,
      depth: artwork.depth,
      theme: artwork.theme,
      stateOfArtwork: artwork.stateOfArtwork,
      status: artwork.status,
      yearOfCreation: artwork.yearOfCreation,
    };

    if (
      !currentUser.roles.includes("ROLE_ARTIST") &&
      artwork.seller.typeOfUser !== "artist"
    ) {
      model = {
        ...model,
        artistNickName: artwork.artist.name,
        artistCategory: artwork.artist.category,
        workCity: artwork.artist.workCity,
        birthYear: artwork.artist.birthYear,
        artistDescription: artwork.artist.artistDescription,
        artistAvatarUrl: artwork.artist.artistAvatarUrl,
        artistEmail: artwork.artist.email,
        artistId: artwork.artist._id,
      };
    }

    return model;
  }

  schema = {
    title: Joi.string().required(),
    description: Joi.string().required(),
    typeOfArtwork: Joi.number().required(),
    technical: Joi.number().required(),
    support: Joi.any(),
    gender: Joi.any(),
    price: Joi.number().greater(0),
    transportTax: Joi.number().required(),
    withCertificate: Joi.boolean(),
    characteristics: Joi.any(),
    images: Joi.any(),
    mainColor: Joi.any(),
    width: Joi.number().required(),
    height: Joi.number().required(),
    depth: Joi.number().required(),
    theme: Joi.number().required(),
    stateOfArtwork: Joi.any(),
    status: Joi.any(),
    yearOfCreation: Joi.any(),
  };

  typeOfArtwork = [
    { text: "Peinture", type: "paint", id: 1 },
    { text: "Sculpture", type: "sculpture", id: 2 },
    { text: "Photographie", type: "photo", id: 3 },
    { text: "Dessin / gouache / aquarelle", type: "drawing", id: 4 },
    { text: "Multiple (gravure, lithographie, ...)", type: "multi", id: 5 },
    { text: "Objet mobilier", type: "furniture", id: 6 },
    { text: "Autre", id: 7, alwaysDisplayed: true },
  ];

  support = [
    { text: "Toile", id: 501 },
    { text: "Panneau", id: 502 },
    { text: "Carton", id: 503 },
    { text: "Papier", id: 504 },
    { text: "Dibon", id: 505 },
    { text: "Tirage unique", id: 506 },
    { text: "Alu", id: 507 },
    { text: "Autre", id: 500, alwaysDisplayed: true },
  ];

  gender = [
    { text: "Abstrait", id: 701 },
    { text: "Figuratif", id: 702 },
    { text: "Street Art", id: 703 },
    { text: "Pop Art", id: 704 },
    { text: "Art conceptuel", id: 705 },
    { text: "Art naïf", id: 706 },
    { text: "Art oriental", id: 707 },
    { text: "Art premier", id: 708 },
    { text: "Art spirituel - religieux", id: 721 },
    { text: "Art Calligraphique", id: 709 },
    { text: "Art Classique", id: 710 },
    { text: "Cubisme", id: 711 },
    { text: "Art Expressionniste", id: 712 },
    { text: "Fauvisme", id: 713 },
    { text: "Figuratif", id: 714 },
    { text: "Géométrique - orphique", id: 715 },
    { text: "Hyper-réalisme", id: 716 },
    { text: "Impressionnisme", id: 717 },
    { text: "Art Minimaliste", id: 718 },
    { text: "Surréalisme", id: 719 },
    { text: "Symbolisme", id: 720 },
    { text: "Autre", id: 700, alwaysDisplayed: true },
  ];

  color = [
    { value: "", text: "Aucune" },
    { value: "white", text: "Blanc" },
    { value: "blue", text: "Bleu" },
    { value: "red", text: "Rouge" },
    { value: "yellow", text: "Jaune" },
    { value: "purple", text: "Violet" },
    { value: "green", text: "Vert" },
    { value: "grey", text: "Gris" },
    { value: "black", text: "Noir" },
    { value: "orange", text: "Orange" },
    { value: "pink", text: "Rose" },
  ];

  artistCategory = [
    { value: "emerging_artist", text: "Artiste émergent" },
    { value: "professional_artist", text: "Artiste professionnel" },
    { value: "famous_artist", text: "Artiste connu" },
    { value: "second_market", text: "Second marché" },
  ];

  technicalBase = [
    { text: "Accessoires", type: "", id: 301 },
    { text: "Acier inoxydable", type: ["sculpture"], id: 302 },
    { text: "Acrylique", type: ["drawing", "paint"], id: 303 },
    { text: "Aérographe", type: ["drawing", "paint"], id: 304 },
    { text: "Aluminium", type: ["sculpture"], id: 305 },
    { text: "Aquarelle", type: ["drawing", "paint"], id: 306 },
    { text: "Argile", type: ["sculpture"], id: 307 },
    { text: "Art de la table", type: "", id: 308 },
    { text: "Béton", type: ["sculpture"], id: 309 },
    { text: "Béton cellulaire", type: ["sculpture"], id: 310 },
    { text: "Bijoux", type: "", id: 311 },
    { text: "Bois", type: ["sculpture"], id: 312 },
    { text: "Bombe aérosol", type: ["drawing", "paint"], id: 313 },
    { text: "Broderie", type: "", id: 314 },
    { text: "Bronze", type: ["sculpture"], id: 315 },
    { text: "Carte à gratter", type: ["drawing", "paint"], id: 316 },
    { text: "Carton", type: ["sculpture"], id: 317 },
    { text: "Céramique", type: ["sculpture"], id: 318 },
    { text: "Ciment", type: ["sculpture"], id: 319 },
    { text: "Cire", type: ["drawing", "paint"], id: 320 },
    { text: "Collages", type: "", id: 321 },
    { text: "Collagraphie", type: ["multi"], id: 322 },
    { text: "Conté", type: ["drawing", "paint"], id: 323 },
    { text: "Coulage", type: ["sculpture"], id: 324 },
    { text: "Craie", type: ["drawing", "paint"], id: 325 },
    { text: "Crayon", type: ["drawing", "paint"], id: 326 },
    { text: "Cuir", type: ["sculpture"], id: 327 },
    { text: "Eau-forte", type: ["multi"], id: 328 },
    { text: "Email", type: ["drawing", "paint"], id: 329 },
    { text: "Embossage", type: ["multi"], id: 330 },
    { text: "Encaustique", type: ["drawing", "paint"], id: 331 },
    { text: "Encre", type: ["drawing", "paint"], id: 332 },
    { text: "Fil", type: "", id: 333 },
    { text: "Fil de fer", type: ["sculpture"], id: 334 },
    { text: "Fusain", type: ["drawing", "paint"], id: 335 },
    { text: "Glace", type: ["sculpture"], id: 336 },
    { text: "Gouache", type: ["drawing", "paint"], id: 337 },
    { text: "Graphite", type: ["drawing", "paint"], id: 338 },
    { text: "Huile", type: ["drawing", "paint"], id: 339 },
    { text: "Impression numérique", type: "", id: 340 },
    { text: "Laque", type: ["drawing", "paint"], id: 341 },
    { text: "Light Painting", type: ["photo"], id: 342 },
    { text: "Linogravures", type: "", id: 343 },
    { text: "Lithographie", type: ["multi"], id: 344 },
    { text: "Luminaire", type: "", id: 345 },
    { text: "Marqueur", type: ["drawing", "paint"], id: 346 },
    { text: "Métaux", type: ["sculpture"], id: 347 },
    { text: "Meuble", type: ["furniture"], id: 348 },
    { text: "Modélisation 3D", type: "", id: 349 },
    { text: "Monotype", type: "", id: 350 },
    { text: "Mosaïque", type: ["sculpture"], id: 351 },
    { text: "Os", type: ["sculpture"], id: 352 },
    { text: "Papier", type: ["sculpture"], id: 353 },
    { text: "Papier mâché", type: ["sculpture"], id: 354 },
    { text: "Pastel", type: ["drawing", "paint"], id: 355 },
    { text: "Patchwork", type: "", id: 356 },
    { text: "Pâte polymère", type: ["sculpture"], id: 357 },
    { text: "Peinture numérique", type: "", id: 358 },
    { text: "Peinture vitrail", type: ["drawing", "paint"], id: 359 },
    { text: "Photo montage", type: ["photo"], id: 360 },
    { text: "Photographique argentique", type: ["photo"], id: 361 },
    { text: "Photographie manipulée", type: ["photo"], id: 362 },
    { text: "Photographie numérique", type: ["photo"], id: 363 },
    { text: "Pierre", type: ["sculpture"], id: 364 },
    { text: "Pigments", type: ["drawing", "paint"], id: 365 },
    { text: "Plastique", type: ["sclpture"], id: 366 },
    { text: "Plâtre", type: ["sculpture"], id: 367 },
    { text: "Pochoir", type: ["drawing", "paint"], id: 368 },
    { text: "Pointe d'argent", type: ["drawing", "paint"], id: 369 },
    { text: "Résine", type: ["sculpture"], id: 370 },
    { text: "Rubain adhésif", type: "", id: 371 },
    { text: "Sable", type: ["sculpture"], id: 372 },
    { text: "Savon", type: ["sculpture"], id: 373 },
    { text: "Sérigraphie", type: ["multi"], id: 374 },
    { text: "String Art", type: "", id: 375 },
    { text: "Stylo à bille", type: ["drawing", "paint"], id: 376 },
    { text: "Stylo à gel", type: ["drawing", "paint"], id: 377 },
    { text: "Tapisserie", type: "", id: 378 },
    { text: "Tempera", type: ["drawing", "paint"], id: 379 },
    { text: "Terre cuite", type: ["sclpture"], id: 380 },
    { text: "Tirage argentique", type: "", id: 381 },
    { text: "Tissu", type: "", id: 382 },
    { text: "Travail numérique 2D", type: "", id: 383 },
    { text: "Verre", type: ["sculpture"], id: 384 },
    { text: "Vêtements", type: "", id: 385 },
    { text: "Vidéo", type: "", id: 386 },
    { text: "Xylographie", type: "", id: 387 },
    { text: "Autre", type: "", id: 388 },
    { text: "Technique mixte", type: "", alwaysDisplayed: true, id: 300 },
  ];

  stateOfArtwork = [
    { value: "new", text: "Neuf" },
    { value: "very_good", text: "Très bon" },
    { value: "good", text: "Bon" },
    { value: "medium", text: "Moyen" },
    { value: "bad", text: "Mauvais" },
    { value: "to_restore", text: "A restaurer" },
  ];

  status = [
    { value: 0, text: "En attente validation" },
    { value: 5, text: "Refusé" },
    { value: 10, text: "En ligne" },
    { value: 20, text: "Acheté" },
  ];

  technical = [
    { text: "Technique mixte", type: "", alwaysDisplayed: true, id: 300 },
  ];

  theme = [
    { text: "Abstrait", id: 100 },
    { text: "Amour", id: 101 },
    { text: "Animal", id: 102 },
    { text: "Culture populaire", id: 103 },
    { text: "Cultures du monde", id: 104 },
    { text: "Fantastique", id: 105 },
    { text: "Histoire", id: 106 },
    { text: "Humour", id: 107 },
    { text: "Marine", id: 108 },
    { text: "Musique", id: 109 },
    { text: "Nature", id: 110 },
    { text: "Nature morte", id: 111 },
    { text: "Nu", id: 112 },
    { text: "Paysage", id: 113 },
    { text: "Portrait", id: 114 },
    { text: "Scène de genre", id: 115 },
    { text: "Spiritualité", id: 116 },
    { text: "Sport", id: 117 },
    { text: "Technologie", id: 118 },
    { text: "Urbain", id: 119 },
    { text: "Véhicule", id: 120 },
    { text: "Autre", id: 121, alwaysDisplayed: true },
  ];

  characteristics = [{ text: "Encadré" }, { text: "Signé (avant - arrière)" }];

  capitalizeFirstLetter = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((names) => {
        return names
          .split("-")
          .map((subNames) => {
            const [firstLetter, ...rest] = subNames.split("");
            return firstLetter.toUpperCase() + rest.join("");
          })
          .join("-");
      })
      .join(" ");
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      const { currentUser, seller } = this.props;
      if (this.props.edit) {
        await updateArtwork(this.props.artworkId, data);
        toast.success("Modifications effectuées avec succès");
      } else {
        if (currentUser.roles.includes("ROLE_ADMIN")) {
          data.seller = seller;
          if (seller.typeOfUser === "artist") {
            const birthDate = new Date(seller.birthDate);
            const artistName = this.capitalizeFirstLetter(
              seller.nickName
                ? seller.nickName
                : seller.lastName + " " + seller.firstName
            );
            data.artist = {
              name: artistName,
              category: seller.category,
              email: seller.email,
              _id: seller._id,
              workCity: seller.workCity,
              birthYear: birthDate.getFullYear(),
              artistDescription: seller.experience,
              artistAvatarUrl: seller.avatarUrl,
            };
          }
          await createArtworkByAdmin(data);
        } else {
          await createArtwork(data);
        }
        toast.success("Votre oeuvre a été créée avec succès");
      }
      this.props.onSubmit();
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.error;
        this.setState({ errors });
      }
    }
  };

  triggerUpload = () => {
    this.uploadRef.current.click();
  };

  triggerAvatarUpload = () => {
    this.uploadAvatarRef.current.click();
  };

  uploadToServer = async (e) => {
    const imgToken = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "image-kit/token"
    );
    const formData = new FormData();

    formData.append("file", e.target.files[0]);
    formData.append("publicKey", "public_D/8HDlj32JhWbgZuZnYKYAOUPbk=");
    formData.append("fileName", e.target.files[0].name);
    formData.append("signature", imgToken.data.signature);
    formData.append("expire", imgToken.data.expire);
    formData.append("token", imgToken.data.token);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return axios.post(
      "https://upload.imagekit.io/api/v1/files/upload",
      formData,
      config
    );
  };

  uploadFile = async (e) => {
    this.setState({ imgLoading: true });

    const response = await this.uploadToServer(e);

    const { data } = this.state;
    data.images.push(response.data.url);
    this.setState({ data, imgLoading: false });
  };

  uploadAvatarFile = async (e) => {
    this.setState({ avatarLoading: true });

    const response = await this.uploadToServer(e);

    const { data } = this.state;
    data.artistAvatarUrl = response.data.url;
    this.setState({ data, avatarLoading: false });
  };

  deleteImage = (index) => {
    let { data } = this.state;

    data.images = data.images.filter((image, position) => position !== index);

    this.setState({ data });
  };

  customHandleChangeAutocomplete = (name, value) => {
    if (name === "typeOfArtwork") {
      if (value) {
        this.technical = this.technicalBase.filter((tech) => {
          return tech.type === "" || tech.type.includes(value.type);
        });
      } else {
        this.technical = this.technicalBase;
      }
    }
  };

  customHandleChange = ({ currentTarget: input }) => {
    //here is custom validation
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };

    if (input.name === "transportTax") {
      if (input.value > 30 && input.value > data.price * 0.1) {
        errors["transportTax"] =
          "Les FDP ne doivent pas excéder 10% du prix de vente";
      } else if (input.value >= 0) {
        delete errors["transportTax"];
      }
    }

    if (input.name === "price") {
      if (input.value > 1000000000) {
        errors["price"] = "Le prix est trop élevé";
      } else if (input.value >= 0) {
        delete errors["price"];
      }
    }

    this.setState({ errors });
  };

  renderArtistBlock = () => {
    const { classes, seller, artwork, edit } = this.props;

    let artworkSeller = seller;
    if (edit) {
      artworkSeller = artwork.seller;
    }

    let artistAvatar = (
      <Avatar
        className={classes.avatar}
        alt="Image de profil"
        onClick={this.triggerAvatarUpload}
        variant={"square"}
        src={"https://www.vox.cg/wp-content/uploads/2014/10/default-img.gif"}
      />
    );

    if (this.state.avatarLoading) {
      artistAvatar = (
        <Skeleton
          variant="rect"
          width={250}
          height={250}
          className={classes.avatar}
        />
      );
    }

    const selectArtist = (artist) => {
      if (artist) {
        const artistName = this.capitalizeFirstLetter(
          artist.nickName
            ? artist.nickName
            : artist.lastName + " " + artist.firstName
        );

        const birthYear = new Date(artist.birthDate).getFullYear();
        const selectedArtist = {
          artistNickName: artistName,
          artistCategory: artist.category,
          workCity: artist.workCity,
          birthYear: birthYear,
          artistDescription: artist.experience,
          artistAvatarUrl: artist.avatarUrl,
          artistEmail: artist.email,
          artistId: artist._id,
        };
        this.setState({
          ...this.state,
          modalOpen: false,
          showArtistForm: false,
          data: { ...this.state.data, ...selectedArtist },
        });
      } else {
        this.setState({
          ...this.state,
          data: { ...this.state.data, artistEmail: null, artistId: null },
          modalOpen: false,
          showArtistForm: true,
        });
      }
    };

    if (
      !this.props.currentUser.roles.includes("ROLE_ARTIST") &&
      artworkSeller?.typeOfUser !== "artist"
    ) {
      return (
        <Paper className={classes.block}>
          <h3>Auteur de l'oeuvre</h3>

          <Button
            variant="contained"
            color="primary"
            onClick={() => this.setState({ ...this.state, modalOpen: true })}
          >
            Ajouter un artist
          </Button>
          {this.state.data.artistNickName ? (
            <p>{this.state.data.artistNickName} sélectionné</p>
          ) : (
            <p>Vous devez sélectionner un artiste !</p>
          )}

          <SearchArtist
            modalOpen={this.state.modalOpen}
            handleModalClose={() =>
              this.setState({
                ...this.state,
                modalOpen: false,
              })
            }
            selectArtist={selectArtist}
          />

          {this.state.showArtistForm && (
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                {this.renderInput(
                  "artistNickName",
                  "Nom de l'artiste (pseudo ou Nom Prénom)",
                  "text",
                  null,
                  true
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                {this.renderSelect(
                  "artistCategory",
                  "Catégorie de l'artiste",
                  this.artistCategory
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                {this.renderInput(
                  "workCity",
                  "Ville / Pays de travail",
                  "text"
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                {this.renderInput("birthYear", "Année de naissance", "text")}
              </Grid>
              <Grid item xs={12}>
                {this.renderRichEditor("artistDescription", "Bio de l'artiste")}
              </Grid>

              <Grid container spacing={1}>
                {this.state.data.artistAvatarUrl && (
                  <Grid item md={4} xs={12} className={classes.image}>
                    <Avatar
                      className={classes.avatar}
                      alt="gallery"
                      variant={"square"}
                      src={this.state.data.artistAvatarUrl}
                    />
                    <Button
                      color="primary"
                      onClick={() =>
                        this.setState({
                          data: { ...this.state.data, artistAvatarUrl: "" },
                        })
                      }
                    >
                      Supprimer l'image
                    </Button>
                  </Grid>
                )}

                <Grid item md={4} xs={12}>
                  {artistAvatar}
                  <input
                    type={"file"}
                    onChange={this.uploadAvatarFile}
                    ref={this.uploadAvatarRef}
                    style={{ display: "none" }}
                  />
                </Grid>
              </Grid>
              {/*artistEmail: "",
              artistId: "",*/}
            </Grid>
          )}
        </Paper>
      );
    }

    return "";
  };

  renderSellerBlock = () => {
    const { currentUser, seller, classes, edit, artwork } = this.props;

    if (!edit && currentUser.roles.includes("ROLE_ADMIN") && !seller) {
      toast.error("Vous devez sélectionner un vendeur pour créé une oeuvre !");
      return <Redirect to={"/products"} />;
    }

    let artworkSeller = seller;
    if (edit) {
      artworkSeller = artwork.seller;
    }

    if (currentUser.roles.includes("ROLE_ADMIN") && artworkSeller) {
      return (
        <Paper className={classes.block}>
          <h3>Vendeur de l'oeuvre</h3>

          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              {artworkSeller.firstName} {artworkSeller.lastName}{" "}
              {artworkSeller.businessName}
            </Grid>
            <Grid item md={6} xs={12}>
              {artworkSeller.email}
            </Grid>
          </Grid>
        </Paper>
      );
    }
  };

  render() {
    const { classes, currentUser, artwork, seller } = this.props;

    let newImage = (
      <Avatar
        className={classes.avatar}
        alt="Image de profil"
        onClick={this.triggerUpload}
        variant={"square"}
        src={"https://www.vox.cg/wp-content/uploads/2014/10/default-img.gif"}
      />
    );

    if (this.state.imgLoading) {
      newImage = (
        <Skeleton
          variant="rect"
          width={250}
          height={250}
          className={classes.avatar}
        />
      );
    }

    const fees = calculateArtistFees(
      this.state.data.price,
      this.state.data.transportTax,
      currentUser,
      seller,
      artwork
    );

    return (
      <form
        onSubmit={this.handleSubmit}
        autoComplete="off"
        className={classes.root}
      >
        {this.renderSellerBlock()}
        <Paper className={classes.block}>
          <Grid container spacing={1}>
            {currentUser.roles.includes("ROLE_ADMIN") &&
              this.renderSelect("status", "Statut", this.status)}
            <Grid item md={6} xs={12}>
              {this.renderInput(
                "title",
                "Titre / nom de l'oeuvre *",
                "text",
                null,
                true
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              {this.renderSelect(
                "stateOfArtwork",
                "Etat de l'oeuvre",
                this.stateOfArtwork
              )}
            </Grid>

            <Grid item md={6} xs={12}>
              {this.renderInput(
                "yearOfCreation",
                "Année de création de l'oeuvre",
                "text"
              )}
            </Grid>

            <Grid item xs={12}>
              {this.renderRichEditor("description", "Détail *", null, true)}
            </Grid>

            <Grid item md={4} xs={12}>
              {this.renderAutocomplete(
                "typeOfArtwork",
                "Type d'oeuvre",
                this.typeOfArtwork,
                this.customHandleChangeAutocomplete,
                true
              )}
            </Grid>

            <Grid item md={4} xs={12}>
              {this.renderSelect("mainColor", "Couleur dominante", this.color)}
            </Grid>

            <Grid item md={4} xs={12}>
              {this.renderAutocomplete(
                "theme",
                "Thème",
                this.theme,
                null,
                true
              )}
            </Grid>

            <Grid item md={4} xs={12}>
              {this.renderAutocomplete(
                "technical",
                "Technique",
                this.technical,
                null,
                true
              )}
            </Grid>

            <Grid item md={4} xs={12}>
              {this.renderAutocomplete("support", "Support", this.support)}
            </Grid>

            <Grid item md={4} xs={12}>
              {this.renderAutocomplete("gender", "Genre", this.gender)}
            </Grid>

            <Grid item md={4} xs={12}>
              {this.renderInput("height", "Hauteur (cm) *", "text", null, true)}
            </Grid>

            <Grid item md={4} xs={12}>
              {this.renderInput("width", "Largeur (cm) *", "text", null, true)}
            </Grid>

            <Grid item md={4} xs={12}>
              {this.renderInput(
                "depth",
                "Profondeur (cm) *",
                "text",
                null,
                true
              )}
            </Grid>

            <Grid item md={6} xs={12}>
              {this.renderInput("price", "Prix *", "text", null, true, {
                onFocus: (event) => {
                  event.target.select();
                },
              })}
              <p>Montant de la commission : {fees}€</p>
            </Grid>

            <Grid item md={6} xs={12}>
              {this.renderInput(
                "transportTax",
                "Montant des frais de port (0 si les FDP sont 'sur devis') *",
                "text",
                this.customHandleChange,
                true
              )}
            </Grid>

            <Grid item md={12} xs={12}>
              {this.renderAutocompleteMultipleFree(
                "characteristics",
                "Caractéristiques",
                this.characteristics
              )}
            </Grid>

            <Grid item md={6} xs={12}>
              {this.renderCheckbox(
                "withCertificate",
                "Délivré avec certificat"
              )}
            </Grid>
          </Grid>
        </Paper>

        {this.renderArtistBlock()}

        <Paper className={classes.block}>
          <h3>Galerie images</h3>

          <Grid container spacing={1}>
            {this.state.data.images.map((image, index) => (
              <Grid item md={4} xs={12} key={index} className={classes.image}>
                <Avatar
                  className={classes.avatar}
                  alt="gallery"
                  variant={"square"}
                  src={image}
                />
                <Button color="primary" onClick={() => this.deleteImage(index)}>
                  Supprimer l'image
                </Button>
              </Grid>
            ))}

            <Grid item md={4} xs={12}>
              {newImage}
              <input
                type={"file"}
                onChange={this.uploadFile}
                ref={this.uploadRef}
                style={{ display: "none" }}
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper className={classes.block}>
          <div>{this.renderButton("Valider", "primary")}</div>
        </Paper>
      </form>
    );
  }
}

ProductForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductForm);
