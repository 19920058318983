import axios from "axios";

export async function getLeads(criteria) {
  const access_token_admin = localStorage.getItem("tokenAdmin");

  if (access_token_admin) {
    const config = {
      headers: { Authorization: `Bearer ${access_token_admin}` },
    };

    return await axios.post(
      process.env.REACT_APP_API_BASE_URL + "lead/search",
      criteria,
      config
    );
  }
}

export async function getLeadById(leadId) {
  const access_token_admin = localStorage.getItem("tokenAdmin");
  if (access_token_admin) {
    const config = {
      headers: { Authorization: `Bearer ${access_token_admin}` },
    };

    return axios.get(
      process.env.REACT_APP_API_BASE_URL + "lead/" + leadId,
      config
    );
  }

  return false;
}

export async function updateLeadByAdmin(lead, leadId) {
  const access_token = localStorage.getItem("tokenAdmin");

  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
  };

  return axios.patch(
    process.env.REACT_APP_API_BASE_URL + "lead/admin/profile",
    { leadId, newLead: lead },
    config
  );
}
