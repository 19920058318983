import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  CardMedia,
  Button,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    display: "flex",
    flex: "1",
    height: 220,
    overflow: "hidden",
    margin: "15px 0",
  },
  details: {
    display: "flex",
    flex: "1 0 auto",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: "30%",
  },
  controls: {
    display: "flex",
    flex: "1",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  icon: {
    height: 30,
    width: 30,
  },
}));

const CardUser = (props) => {
  const classes = useStyles();
  const { artPro } = props;

  const status = {
    0: {
      color: "default",
      text: "Nouveau",
    },
    new: {
      color: "default",
      text: "Nouveau",
    },
    waiting_validation: {
      color: "secondary",
      text: "En attente validation",
    },
    active: {
      color: "primary",
      text: "Actif",
    },
  };

  return (
    <Card className={classes.cardRoot}>
      <CardMedia
        className={classes.cover}
        image={
          artPro.avatarUrl
            ? artPro.avatarUrl
            : "https://www.club-games.com/wp-content/themes/cwp-youit/images/no-image-default.png"
        }
        title={artPro.businessName}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {artPro.businessName}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {artPro.email} - {artPro.phone}
          </Typography>
          <Button variant={"contained"} color={status[artPro.status].color}>
            {status[artPro.status].text}
          </Button>
        </CardContent>
      </div>
      <div className={classes.controls}>
        <IconButton
          aria-label="edit"
          component={Link}
          to={"/artPros/" + artPro._id}
        >
          <EditIcon className={classes.icon} />
          Modifier
        </IconButton>
        <IconButton
          aria-label="edit"
          component={Link}
          to={{
            pathname: "/products/new",
            seller: { ...artPro, typeOfUser: "artPro" },
          }}
        >
          <EditIcon className={classes.icon} />
          Créer une oeuvre
        </IconButton>
        {/* Add delete button */}
      </div>
    </Card>
  );
};

CardUser.propTypes = {
  className: PropTypes.string,
};

export default CardUser;
