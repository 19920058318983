import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  CardMedia,
  Button,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import LinkIcon from "@material-ui/icons/Link";
import { Link } from "react-router-dom";
import { useStore } from "react-redux";

import { slugify } from "../../../services/stringService";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    display: "flex",
    flex: "1",
    height: 220,
    overflow: "hidden",
    margin: "15px 0",
  },
  details: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
  },
  content: {
    flex: "1",
  },
  cover: {
    width: "30%",
  },
  controls: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    alignItems: "flex-end",
    justifyContent: "center",
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  icon: {
    height: 30,
    width: 30,
  },
  seller: {
    marginTop: 10,
  },
}));

const CardProduct = ({ artwork, onDelete }) => {
  const classes = useStyles();
  const store = useStore();
  const currentUser = store.getState().entities?.user?.currentUser;

  const status = {
    0: {
      color: "default",
      text: "En attente validation",
    },
    5: {
      color: "secondary",
      text: "Refusé",
    },
    10: {
      color: "primary",
      text: "En ligne",
    },
    20: {
      color: "default",
      text: "Acheté",
    },
    paginate: {
      alignItems: "end",
    },
  };

  return (
    <Card className={classes.cardRoot}>
      <CardMedia
        className={classes.cover}
        image={
          artwork.images[0]
            ? artwork.images[0]
            : "https://www.club-games.com/wp-content/themes/cwp-youit/images/no-image-default.png"
        }
        title={artwork.title}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {artwork.title} - {artwork.price}€
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {artwork.typeOfArtwork}
          </Typography>
          <Button variant={"contained"} color={status[10].color}>
            {status[artwork.status].text}
          </Button>
          {currentUser.roles.includes("ROLE_ADMIN") && (
            <Typography className={classes.seller} component="h6" variant="h6">
              Vendeur {artwork.seller.firstName} {artwork.seller.lastName}{" "}
              {artwork.seller.businessName}
            </Typography>
          )}
          <div dangerouslySetInnerHTML={{ __html: artwork.description }} />
        </CardContent>
      </div>
      <div className={classes.controls}>
        <IconButton
          aria-label="edit"
          component={Link}
          to={"/products/" + artwork._id}
        >
          <EditIcon className={classes.icon} />
          Modifier
        </IconButton>
        <IconButton aria-label="edit" onClick={() => onDelete(artwork._id)}>
          <DeleteIcon className={classes.icon} />
          Supprimer
        </IconButton>
        {artwork.status === 10 && (
          <IconButton
            aria-label="copyLink"
            onClick={() => {
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_MARKETPLACE_URL}oeuvre-d-art/${slugify(
                  artwork.title
                )}-${artwork._id}`
              );
              toast.success("Le lien de l'oeuvre a été copié !");
            }}
          >
            <LinkIcon className={classes.icon} />
            Copier le lien de l'oeuvre
          </IconButton>
        )}
      </div>
    </Card>
  );
};

CardProduct.propTypes = {
  className: PropTypes.string,
};

export default CardProduct;
