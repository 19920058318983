import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  block: {
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
  },
  buttons: {
    marginTop: theme.spacing(2),
    display: "block",
    textAlign: "center",
  },
}));

const Register = (props) => {
  const { className } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <div className={classes.registerForm}>
            <Paper className={classes.block}>
              <h1>Inscription</h1>
              <p>
                Créez sans attendre votre compte pro sur RunArtGallery et
                commencez à vendre vos oeuvres
              </p>
              <p>
                Une fois inscrit vous serez en mesure de déposer vos oeuvres
              </p>
              <p>Chaque comptes et chaque oeuvres sont soumis à validation</p>
              <p>
                Afin de vous tranférés les fonds relatifs à vos ventes, vous
                serez invité à saisir vos informations bancaires.
              </p>
              <p>
                Ces informations seront gérées par un organisme dédié et
                sécurisé. RunArt n'a pas accès à ces informations et aucune
                action ne pourra être effeectuée sans vos consentement
              </p>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper className={classes.block}>
            <h2>Quelle est votre statut ?</h2>
            <Button
              color="primary"
              variant="contained"
              size="large"
              className={classes.buttons}
              component={Link}
              to="/register/artist"
            >
              Je suis un Artiste *
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              className={classes.buttons}
              component={Link}
              to="/register/pro"
            >
              Je suis un professionnel de l'art * (Galerie, Agent, ...)
            </Button>
            <p>
              <em>
                * Les professionnels de l'art vendent des oeuvres produites part
                des tiers, les artistes vendent leur propre production
              </em>
            </p>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

Register.propTypes = {
  className: PropTypes.string,
};

export default Register;
