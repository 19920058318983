import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import TopBar from "../sharedComponents/TopBar";
import Menu from "./components/Menus";
import Footer from "./components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${theme.palette.drawer.width}px)`,
      marginLeft: theme.palette.drawer.width,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: 100,
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
}));

const Main = (props) => {
  const { children } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    const open = !mobileOpen;
    setMobileOpen(open);
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <TopBar onClick={handleDrawerToggle} />
      </AppBar>
      <Menu onClick={handleDrawerToggle} mobileOpen={mobileOpen} />
      <div className={classes.mainContent}>
        <main className={classes.content}>{children}</main>
        <Footer />
      </div>
    </div>
  );
};

export default Main;
