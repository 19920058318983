import React, { createRef } from "react";
import Joi from "joi-browser";
import Form from "../../../../common/Form";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";

const styles = (theme) => ({
  root: {
    "& .errorMsg": {
      color: "red",
    },
    "& .ck-editor__editable_inline": {
      background: theme.palette.background.level2,
      margin: "45px 0 15px 0",
      borderRadius: "8px !important",
    },
    "& .MuiButton-root, .MuiTextField-root": {
      width: "100%",
      margin: "15px 0",
    },
  },
  block: {
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
  },
  avatar: {
    width: 120,
    height: 120,
    margin: "0 auto",
    "& :hover": {
      cursor: "pointer",
    },
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  legalLink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "& :hover": {
      cursor: "pointer",
    },
  },
  errorMsg: {
    marginTop: 10,
    color: theme.palette.error.main,
    textAlign: "center",
  },
});

class AboutArtForm extends Form {
  constructor(props) {
    super();
    this.uploadRef = createRef();

    this.state = {
      data: {
        studies: "",
        experience: "",
        influence: "",
        technical: "",
        career: "",
        success: "",
        expos: "",
        mindset: "",
        rank: "",
        other: "",
      },
      errors: {},
    };
  }

  schema = {
    studies: Joi.string().required().label("Etudes et formations"),
    experience: Joi.string()
      .required()
      .label("Expérience professionnelle en rapport avec l’art"),
    influence: Joi.string()
      .required()
      .label("Influences artistiques, inspirations, références"),
    technical: Joi.string()
      .required()
      .label("Pratiques artistiques – maîtrise techniques"),
    career: Joi.string().required().label("Evolution de la carrière"),
    success: Joi.string()
      .required()
      .label(
        "Grandes réussites en tant qu’artiste : concours récompenses, présence dans des collections"
      ),
    expos: Joi.string()
      .required()
      .label("Expositions individuelles et de groupe"),
    mindset: Joi.any(),
    rank: Joi.any(),
    other: Joi.any(),
  };

  doSubmit = async () => {
    const { data } = this.state;
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  render() {
    const { classes } = this.props;

    return (
      <form
        onSubmit={this.handleSubmit}
        autoComplete="off"
        className={classes.root}
      >
        <Paper className={classes.block}>
          <h3>L'art et vous</h3>

          {this.renderRichEditor(
            "studies",
            "Etudes et formations *",
            null,
            true
          )}
          {this.renderRichEditor(
            "experience",
            "Expérience professionnelle en rapport avec l’art *",
            null,
            true
          )}
          {this.renderRichEditor(
            "influence",
            "Influences artistiques, inspirations, références *",
            null,
            true
          )}
          {this.renderRichEditor(
            "technical",
            "Pratiques artistiques – maîtrise techniques *",
            null,
            true
          )}
          {this.renderRichEditor(
            "career",
            "Evolution de la carrière *",
            null,
            true
          )}
          {this.renderRichEditor(
            "success",
            "Grandes réussites en tant qu’artiste : concours récompenses, présence dans des collections *",
            null,
            true
          )}
          {this.renderRichEditor(
            "expos",
            "Expositions individuelles et de groupe *",
            null,
            true
          )}
          {this.renderRichEditor(
            "mindset",
            "Philosophie si impactante sur la carrière"
          )}
          {this.renderRichEditor(
            "rank",
            "Cotation éventuelle : Akoun, Artprice, Icac … ou autre"
          )}
          {this.renderRichEditor(
            "other",
            "Divers, toutes informations que vous jugez utiles"
          )}
          <div>{this.renderButton("Suivant", "primary")}</div>
        </Paper>
      </form>
    );
  }
}

AboutArtForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AboutArtForm);
