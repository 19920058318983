import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { getArtists } from "../../../services/artistService";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  artistItem: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

function SearchArtist({ modalOpen = false, handleModalClose, selectArtist }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [artists, setArtists] = useState([]);
  const [artistKeyword, setArtistKeyword] = useState("");

  useEffect(() => {
    (async () => {
      const artists = await getArtists({ term: artistKeyword || null });
      setArtists(artists.data.data);
    })();
  }, [artistKeyword]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={modalOpen}
      onClose={handleModalClose}
      maxWidth="md"
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Rechercher un artist
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <TextField
          variant="outlined"
          onChange={(e) => setArtistKeyword(e.target.value)}
          placeholder="Rechercher un artiste"
          fullWidth
        />
        <Typography variant="h6" style={{ marginTop: 20 }}>
          Résultats
        </Typography>
        {artists.map((artist) => (
          <div className={classes.artistItem} key={artist._id}>
            <p style={{ flexGrow: 1 }}>
              {artist.nickName ? (
                <strong>{artist.nickName}</strong>
              ) : (
                <>
                  <strong>{artist.lastName}</strong> {artist.firstName}
                </>
              )}
            </p>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => selectArtist(artist)}
            >
              Choisir
            </Button>
          </div>
        ))}
        <div className={classes.artistItem}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => selectArtist(null)}
          >
            L'artiste n'est pas dans la liste
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SearchArtist;
