import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Image from "../../common/atoms/Image";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  logo: {
    maxHeight: 120,
    maxWidth: 250,
    margin: "10px 50px",
    [theme.breakpoints.down("md")]: {
      margin: "0px 0px",
      padding: 5,
    },
  },
  menuButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const TopBar = (props) => {
  const { onClick } = props;

  const classes = useStyles();

  return (
    <Toolbar className={clsx(classes.root)}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={onClick}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <Link to="/">
        <Image
          className={classes.logo}
          src={"/assets/images/logo-banner.jpg"}
        />
      </Link>
      <Typography variant="h4" className={classes.title}>
        Application Pro
      </Typography>
    </Toolbar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
