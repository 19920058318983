import React from "react";
import "./App.css";
import { useMediaPredicate } from "react-media-hook";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import theme from "./theme";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const store = configureStore();

function App() {
  const mode = useMediaPredicate("(prefers-color-scheme: dark)")
    ? "dark"
    : "light";

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme[mode]}>
        <CssBaseline />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router>
          <Routes />
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
