import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, MobileStepper, Paper } from "@material-ui/core";
import { useState } from "react";
import IdentityStepForm from "./components/Artist/IdentityStepForm";
import IdentityMoreInfosForm from "./components/Artist/IdentityMoreInfosForm";
import AboutArtForm from "./components/Artist/AboutArtForm";
import LegalAcceptationForm from "./components/Artist/LegalAcceptationForm";
import { registerArtist } from "../../services/artistService";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  block: {
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
  },
  buttons: {
    marginTop: theme.spacing(2),
    display: "block",
  },
  stepper: {
    width: "100%",
    flexGrow: 1,
  },
}));

const RegisterArtist = (props) => {
  const { className } = props;

  const classes = useStyles();

  const [currentPage, setPage] = useState(1);
  const nextPage = () => setPage((prev) => ++prev);

  const [wizardData, setWizardData] = useState({});
  const history = useHistory();

  const handleNextStep = (data) => {
    setWizardData({ ...wizardData, ...data });
    nextPage();
  };

  const handleSubmit = async () => {
    try {
      await registerArtist(wizardData);
      toast.success("Compte créé avec succès, veuillez vous connecter");
      history.push("/login");
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.error;
        this.setState({ errors });
      }
    }
  };

  const contextInstructions = () => {
    switch (currentPage) {
      case 1:
        return (
          <React.Fragment>
            <h1>Inscription Artiste</h1>
            <p>Vous êtes sur le point de créer un compte artiste.</p>
            <p>
              Cela signifie que vous devez vendre uniquement des oeuvres de
              votre production.
            </p>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <h1>Inscription Artiste</h1>
            <p>Dites nous en un peu plus sur vous.</p>
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <h1>Inscription Artiste</h1>
            <p>Vous y êtes presque !</p>
            <p>
              Dans cette section plus vous renseignez d'informations, mieux vous
              serez référencé sur le site de vente.
            </p>
          </React.Fragment>
        );
      case 4:
        return (
          <React.Fragment>
            <h1>Inscription Artiste</h1>
            <p>
              Merci d'accetper les CGUV pour finaliser la création de votre
              compte.
            </p>
          </React.Fragment>
        );
      default:
        return "";
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <div className={classes.registerForm}>
            <Paper className={classes.block}>
              {contextInstructions()}
              <p>Les champs marqué d'une étoile "*" sont obligatoires</p>
              <MobileStepper
                variant="text"
                steps={4}
                position="static"
                activeStep={currentPage - 1}
                className={classes.stepper}
              />
            </Paper>
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          {currentPage === 1 && <IdentityStepForm onSubmit={handleNextStep} />}
          {currentPage === 2 && (
            <IdentityMoreInfosForm onSubmit={handleNextStep} />
          )}
          {currentPage === 3 && <AboutArtForm onSubmit={handleNextStep} />}
          {currentPage === 4 && (
            <LegalAcceptationForm onSubmit={handleSubmit} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

RegisterArtist.propTypes = {
  className: PropTypes.string,
};

export default RegisterArtist;
