import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import ArtistForm from "../Profile/components/ArtistForm";
import { getArtistById } from "../../services/artistService";
import { useStore } from "react-redux";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
}));

const Artists = (props) => {
  const { className } = props;
  const artistId = props.match.params.id;
  const classes = useStyles();
  const history = useHistory();
  const [title, setTitle] = useState("Nouvel artiste");

  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [artist, setArtist] = useState(null);

  const store = useStore();
  const { currentUser } = store.getState().entities.user;

  useEffect(() => {
    async function fetchData() {
      if (artistId !== "new") {
        const artistResponse = await getArtistById(artistId);
        setTitle(artistResponse.data.firstName + artistResponse.data.lastName);
        setEdit(true);
        setArtist(artistResponse.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    fetchData();
  }, [artistId]);

  const handleSubmit = () => {
    toast.success("Profil mis à jour avec succès");
    history.push("/artists");
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Button
        color="primary"
        className={classes.button}
        startIcon={<ArrowBackIcon />}
        component={Link}
        to={"/artists"}
      >
        Retour liste
      </Button>
      <h1 className={classes.title}>{title}</h1>
      {!loading && (
        <ArtistForm
          onSubmit={handleSubmit}
          edit={edit}
          artist={artist}
          artistId={artistId}
          admin={true}
          currentUser={currentUser}
        />
      )}
    </div>
  );
};

Artists.propTypes = {
  className: PropTypes.string,
};

export default Artists;
