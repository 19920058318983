import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TopBar from "../sharedComponents/TopBar";
import Footer from "./components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
  },
  content: {
    height: "100%",
    width: "90%",
    margin: "0 auto",
    flex: 1,
  },
}));

const Minimal = (props) => {
  const { children, className } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <TopBar />
      <main className={classes.content}>{children}</main>
      <Footer />
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Minimal;
