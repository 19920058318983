import React, { Component } from "react";
import Joi from "joi-browser";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Grid,
  Chip,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import WarningIcon from "@material-ui/icons/Warning";

export default class Form extends Component {
  state = {
    data: {},
    errors: {},
    editors: {},
  };

  validate = () => {
    const { errors: stateErrors } = this.state;
    const result = Joi.validate(this.state.data, this.schema, {
      abortEarly: false,
    });

    if (!result.error && !stateErrors) {
      return null;
    }

    const errors = {};
    if (result.error) {
      for (let item of result.error?.details) {
        errors[item.path[0]] = item.message;
      }
    }

    return Object.keys({ ...errors, ...stateErrors }).length === 0
      ? null
      : { ...errors, ...stateErrors };
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);

    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      return errors;
    }

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (errorMessage) {
      errors[input.name] = errorMessage;
    } else {
      delete errors[input.name];
    }

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  handleCheckboxChange = ({ target }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name: target.name,
      value: target.checked,
    });

    if (errorMessage) {
      errors[target.name] = errorMessage;
    } else {
      delete errors[target.name];
    }

    const data = { ...this.state.data };
    data[target.name] = target.checked;
    this.setState({ data, errors });
  };

  handleEditorChange = (event, editor, name) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name,
      value: editor.getData(),
    });

    if (errorMessage) {
      errors[name] = errorMessage;
    } else {
      delete errors[name];
    }

    const data = { ...this.state.data };
    data[name] = editor.getData();
    this.setState({ data, errors });
  };

  handleDateChange = (date, name) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name,
      value: date,
    });

    if (errorMessage) {
      errors[name] = errorMessage;
    } else {
      delete errors[name];
    }

    const data = { ...this.state.data };
    data[name] = date;
    this.setState({ data, errors });
  };

  handleAutocompleteSelect = (name, value) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name,
      value: value,
    });

    if (errorMessage) {
      errors[name] = errorMessage;
    } else {
      delete errors[name];
    }

    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data, errors });
  };

  handleSelect = (name, event) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty({
      name,
      value: event.target.value,
    });

    if (errorMessage) {
      errors[name] = errorMessage;
    } else {
      delete errors[name];
    }

    const data = { ...this.state.data };
    data[name] = event.target.value;
    this.setState({ data, errors });
  };

  renderButton(label, color = "primary") {
    return (
      <Button
        variant="contained"
        color={color}
        type="submit"
        disabled={!!this.validate()}
      >
        {label}
      </Button>
    );
  }

  renderInput(
    name,
    label,
    type,
    customHandle = null,
    required = false,
    options = {}
  ) {
    const { data, errors } = this.state;

    const helperText = errors[name]
      ? errors[name]
      : required &&
        !data[name] && (
          <span style={{ display: "flex", alignItems: "center" }}>
            <WarningIcon />
            Ce champ est requis
          </span>
        );
    label += required ? " - Requis" : " - Optionnel";

    return (
      <TextField
        label={label}
        name={name}
        value={data[name]}
        onChange={(target) => {
          this.handleChange(target);
          customHandle && customHandle(target);
        }}
        error={!!errors[name]}
        helperText={helperText}
        type={type}
        variant="outlined"
        required={required}
        fullWidth={true}
        onFocus={options.onFocus ? options.onFocus : () => {}}
      />
    );
  }

  renderDatePicker(name, label, format = "dd/MM/yyyy") {
    const { data } = this.state;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <KeyboardDatePicker
            margin="normal"
            inputVariant="outlined"
            id={name}
            name={name}
            label={label}
            format={format}
            value={data[name]}
            onChange={(date) => this.handleDateChange(date, name)}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    );
  }

  renderRichEditor(name, label, config = null, required = false) {
    const { data, errors } = this.state;

    if (!config) {
      config = {
        toolbar: ["bold", "italic", "bulletedList", "numberedList"],
      };
    }

    const error = errors[name]
      ? errors[name]
      : required &&
        !data[name] && (
          <span
            style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
          >
            <WarningIcon />
            Ce champ est requis
          </span>
        );
    label += required ? " - Requis" : " - Optionnel";

    return (
      <React.Fragment>
        <span>{label}</span>
        <CKEditor
          editor={InlineEditor}
          config={config}
          data={data[name]}
          onChange={(event, editor) =>
            this.handleEditorChange(event, editor, name)
          }
        />
        {error}
      </React.Fragment>
    );
  }

  renderCheckbox(name, label, color = "primary") {
    const { data } = this.state;

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={data[name]}
            onChange={this.handleCheckboxChange}
            name={name}
            color={color}
          />
        }
        label={label}
      />
    );
  }

  renderAutocompleteFree(
    name,
    label,
    options,
    customHandle = null,
    required = false
  ) {
    const { data, errors } = this.state;
    const filter = createFilterOptions();

    const helperText = errors[name]
      ? errors[name]
      : required &&
        !data[name] && (
          <span style={{ display: "flex", alignItems: "center" }}>
            <WarningIcon />
            Ce champ est requis
          </span>
        );
    label += required ? " - Requis" : " - Optionnel";

    return (
      <Autocomplete
        value={data[name]}
        fullWidth={true}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            this.handleAutocompleteSelect(name, newValue);
            customHandle && customHandle(name, newValue);
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            this.handleAutocompleteSelect(name, newValue.inputValue);
            customHandle && customHandle(name, newValue.inputValue);
          } else {
            this.handleAutocompleteSelect(
              name,
              newValue && newValue.text ? newValue.text : newValue
            );
            customHandle && customHandle(name, newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              text: `Ajouter "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={name}
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.text;
        }}
        renderOption={(option) => option.text}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            error={!!errors[name]}
            helperText={helperText}
          />
        )}
      />
    );
  }

  renderAutocomplete(
    name,
    label,
    options,
    customHandle = null,
    required = false
  ) {
    const { data, errors } = this.state;

    const helperText = errors[name]
      ? errors[name]
      : required &&
        !data[name] && (
          <span style={{ display: "flex", alignItems: "center" }}>
            <WarningIcon />
            Ce champ est requis
          </span>
        );
    label += required ? " - Requis" : " - Optionnel";

    return (
      <Autocomplete
        value={data[name] ? data[name] : null}
        fullWidth={true}
        onChange={(event, newValue) => {
          this.handleAutocompleteSelect(
            name,
            newValue && newValue.id ? newValue.id : newValue
          );
          customHandle && customHandle(name, newValue);
        }}
        filterOptions={(options, params) => {
          const filtered = options.filter(
            (option) =>
              option.text
                .toLowerCase()
                .includes(params.inputValue.toLowerCase()) ||
              option.alwaysDisplayed === true
          );
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={name}
        options={options}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "number" || typeof option === "string") {
            const selectedOption = options.find((item) => item.id === option);
            return selectedOption.text;
          }
          // Regular option
          return option?.text ? option.text : option;
        }}
        renderOption={(option) => option.text}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            error={!!errors[name]}
            helperText={helperText}
          />
        )}
      />
    );
  }

  renderAutocompleteMultipleFree(name, label, options) {
    const { data } = this.state;

    return (
      <Autocomplete
        multiple
        id={name}
        options={options.map((option) => option.text)}
        value={data[name] ? data[name] : ""}
        freeSolo
        onChange={(event, value) => this.handleAutocompleteSelect(name, value)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label={label}
            placeholder={label}
          />
        )}
      />
    );
  }

  renderSelect(name, label, options) {
    const { data, errors } = this.state;

    return (
      <FormControl variant="outlined">
        <InputLabel id={name}>{label}</InputLabel>
        <Select
          name={name}
          value={data[name]}
          fullWidth={true}
          label={label}
          onChange={(event) => this.handleSelect(name, event)}
          error={errors[name]}
        >
          {options.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}
