import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import ShipProductForm from "./ShipProductForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function ShipProduct({ productId, order, open, onClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubmit = () => {
    onClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle>
        Expedition du produit :{" "}
        {order.products.find((product) => product._id === productId)?.name}
      </DialogTitle>
      <DialogContent>
        <ShipProductForm
          productId={productId}
          order={order}
          onSubmit={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
}

export default ShipProduct;
