import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import RouteWithLayout from "./common/RouteWithLayout";
import { useDispatch, useStore } from "react-redux";

import Minimal from "./layouts/Minimal/Minimal";
import Main from "./layouts/Main/Main";

import {
  Home as HomeView,
  Login as LoginView,
  Register as RegisterView,
  ProductsList as ProductsListView,
  Products as ProductsView,
  OrdersList as OrdersListView,
  Orders as OrdersView,
  Profile as ProfileView,
  RegisterArtist as RegisterArtistView,
  RegisterPro as RegisterProView,
  ArtistsList as ArtistListView,
  ArtProList as ArtProListView,
  Artists as ArtistsView,
  ArtPros as ArtProsView,
  LeadsList as LeadsListView,
  Leads as LeadsView,
  ClientsList as ClientsListView,
  Clients as ClientsView,
} from "./view";
import { loadCurrentUser } from "./store/user";

const Routes = () => {
  const dispatch = useDispatch();
  const store = useStore();

  useEffect(() => {
    dispatch(loadCurrentUser());
  }, [dispatch, store]);

  return (
    <Switch>
      <RouteWithLayout
        component={LoginView}
        exact
        layout={Minimal}
        path="/login"
      />
      <RouteWithLayout
        component={RegisterArtistView}
        exact
        layout={Minimal}
        path="/register/artist"
      />
      <RouteWithLayout
        component={RegisterProView}
        exact
        layout={Minimal}
        path="/register/pro"
      />
      <RouteWithLayout
        component={RegisterView}
        exact
        layout={Minimal}
        path="/register"
      />
      <RouteWithLayout
        isProtected={true}
        component={ProfileView}
        layout={Main}
        path="/profile/:tab"
      />
      <RouteWithLayout
        isProtected={true}
        component={ProfileView}
        layout={Main}
        path="/profile"
      />
      <RouteWithLayout
        isProtected={true}
        component={ProductsView}
        exact
        layout={Main}
        path="/products/:id"
      />
      <RouteWithLayout
        isProtected={true}
        component={ProductsListView}
        exact
        layout={Main}
        path="/products"
      />
      <RouteWithLayout
        isProtected={true}
        isAdmin={true}
        component={OrdersView}
        exact
        layout={Main}
        path="/orders/:id"
      />
      <RouteWithLayout
        isProtected={true}
        isAdmin={true}
        component={OrdersListView}
        exact
        layout={Main}
        path="/orders"
      />
      <RouteWithLayout
        isProtected={true}
        isAdmin={true}
        component={ArtistsView}
        exact
        layout={Main}
        path="/artists/:id"
      />
      <RouteWithLayout
        isProtected={true}
        isAdmin={true}
        component={ArtistListView}
        exact
        layout={Main}
        path="/artists"
      />
      <RouteWithLayout
        isProtected={true}
        isAdmin={true}
        component={ArtProsView}
        exact
        layout={Main}
        path="/artPros/:id"
      />
      <RouteWithLayout
        isProtected={true}
        isAdmin={true}
        component={ArtProListView}
        exact
        layout={Main}
        path="/artPros"
      />
      <RouteWithLayout
        isProtected={true}
        isAdmin={true}
        component={LeadsView}
        exact
        layout={Main}
        path="/leads/:id"
      />
      <RouteWithLayout
        isProtected={true}
        isAdmin={true}
        component={LeadsListView}
        exact
        layout={Main}
        path="/leads"
      />
      <RouteWithLayout
        isProtected={true}
        isAdmin={true}
        component={ClientsView}
        exact
        layout={Main}
        path="/clients/:id"
      />
      <RouteWithLayout
        isProtected={true}
        isAdmin={true}
        component={ClientsListView}
        exact
        layout={Main}
        path="/clients"
      />
      <RouteWithLayout
        isProtected={true}
        component={HomeView}
        exact
        layout={Main}
        path="/"
      />
      {/*<RouteWithLayout
        component={NotFoundView}
        exact
        layout={Minimal}
        path="/not-found"
      />
      <RouteWithLayout
        component={NotFoundCoverView}
        exact
        layout={Minimal}
        path="/not-found-cover"
      />
      <Redirect to="/not-found-cover" status="404" />*/}
    </Switch>
  );
};

export default Routes;
