import axios from "axios";

export async function registerArtPro(artPro) {
  return await axios.post(
    process.env.REACT_APP_API_BASE_URL + "art-pro/register",
    artPro
  );
}

export async function updateArtProProfile(artPro) {
  const access_token = localStorage.getItem("tokenArtPro");

  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
  };

  return axios.patch(
    process.env.REACT_APP_API_BASE_URL + "art-pro/profile",
    artPro,
    config
  );
}

export async function updateArtProProfileByAdmin(artPro, artProId) {
  const access_token = localStorage.getItem("tokenAdmin");

  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
  };

  return axios.patch(
    process.env.REACT_APP_API_BASE_URL + "art-pro/admin/profile",
    { newArtPro: artPro, artProId },
    config
  );
}

export async function getArtPros(criteria) {
  const access_token_admin = localStorage.getItem("tokenAdmin");

  if (access_token_admin) {
    const config = {
      headers: { Authorization: `Bearer ${access_token_admin}` },
    };

    return await axios.post(
      process.env.REACT_APP_API_BASE_URL + "art-pro/search",
      criteria,
      config
    );
  }
}

export async function getArtProById(artProId) {
  const access_token_admin = localStorage.getItem("tokenAdmin");
  if (access_token_admin) {
    const config = {
      headers: { Authorization: `Bearer ${access_token_admin}` },
    };

    return axios.get(
      process.env.REACT_APP_API_BASE_URL + "art-pro/" + artProId,
      config
    );
  }

  return false;
}
